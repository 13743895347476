import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const ImageAttributeStyled = styled.div`
  .ImgWrapper {
    display: flex;
    margin-inline-end: ${spacing.xsmall};

    img {
      max-inline-size: 24px;
      max-block-size: 24px;
    }
  }

  h4.Title {
    display: flex;
    align-items: center;
    line-height: 22px;
    margin-block-start: ${spacing.small};

    img {
      max-inline-size: 24px;
      max-block-size: 24px;
      margin-inline-end: ${spacing.small};
    }
  }

  .Attribute {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-block: 20px;

    &:first-of-type {
      padding-block-start: 0;
    }

    p {
      white-space: pre-wrap;
      margin-block: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .DocumentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-block-end: ${spacing.small};

    .ImgWrapper {
      margin-block-start: 4px;
    }

    p {
      white-space: pre-wrap;
      margin-block: 0;
    }

    a {
      text-decoration: none;
    }
  }

  .Attribute a:hover {
    color: ${colors.gray1};
  }

  .DocumentWrapper a:hover {
    color: ${colors.gray1};
  }
`;
