import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const NavigationStyled = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: ${colors.white};

  @media screen and (min-width: ${breakpoints.desktop}) {
    border-block-end: thin solid ${colors.gray4};
    padding: 0 ${spacing.pagePadding};
  }

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    .LanguageSelector {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 98px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: static;
    inset-inline-start: auto;
    transform: none;
  }

  a {
    display: flex;
    align-items: center;
    color: ${colors.black};

    &:visited {
      color: ${colors.black};
    }
  }
`;

export const MenuToggler = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  width: ${spacing.regular};

  svg {
    path {
      fill: ${colors.black};
    }
  }
`;

export const MenuActions = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  block-size: 100%;

  > * + * {
    margin-inline-start: 12px;
  }
`;

const sharedTopMenuLinkStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  block-size: auto;
  white-space: nowrap;
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.1rem;
  color: ${colors.black};
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.1s linear;
  padding-block-end: 4px;
  margin: 8px 0;

  @media screen and (min-width: ${breakpoints.desktop}) {
    letter-spacing: 0.175rem;
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    letter-spacing: 0.175rem;
  }

  &::after {
    content: '';
    position: absolute;
    block-size: 1px;
    inline-size: 20px;
    inset-block-end: 0;
    inset-inline-start: 0;
    background: ${colors.black};
    border-radius: 2px;
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &:link {
    color: ${colors.black};
  }

  &:hover,
  &:active {
    color: ${colors.red};

    &::after {
      opacity: 1;
      background: ${colors.red};
    }
  }
`;

interface TopMenuItemProps {
  $active?: boolean;
  largerBreakpoint?: boolean;
}

export const TopMenuLink = styled(Link)<TopMenuItemProps>`
  ${sharedTopMenuLinkStyle};
  ${({ $active }) =>
    $active
    && css`
      color: ${colors.red};

      &::after {
        opacity: 1;
        background: ${colors.red};
      }
    `}
`;

export const TopMenuToggle = styled.button<
  TopMenuItemProps & { $open?: boolean }
>`
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  ${sharedTopMenuLinkStyle};
  cursor: pointer;
  ${({ $active }) =>
    $active
    && css`
      color: ${colors.red};

      &::after {
        opacity: 1;
        background: ${colors.red};
      }
    `}
  ${({ $open }) =>
    $open
    && css`
      &::after {
        opacity: 1;
      }
    `}
`;

export const TopMenuLinkInner = styled.span<TopMenuItemProps>`
  display: block;
  position: relative;
`;

export const TopMenu = styled.div<{ $largerBreakpoint?: boolean }>`
  display: flex;
  width: 100%;
  block-size: 100%;
  justify-content: center;
`;

export const CenterMenu = styled.div<{ $largerBreakpoint?: boolean }>`
  display: flex;
  block-size: 100%;

  > * + * {
    margin-inline-start: ${spacing.xsmall};

    ${({ $largerBreakpoint }) =>
      !$largerBreakpoint
      && css`
        @media screen and (min-width: ${breakpoints.desktopMedium}) {
          margin-inline-start: ${spacing.small};
        }

        @media screen and (min-width: ${breakpoints.desktopLarge}) {
          margin-inline-start: ${spacing.regular};
        }
      `}
    ${({ $largerBreakpoint }) =>
      $largerBreakpoint
      && css`
        @media screen and (width >= 1345px) {
          margin-inline-start: ${spacing.regular};
        }
      `}
  }
`;

export const MobileNav = styled.div<{ $largerBreakpoint?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  block-size: 56px;
  background-color: ${colors.white};
  align-items: center;
  padding-inline-start: ${spacing.pagePaddingMobile};
  border-block-end: thin solid ${colors.gray4};

  span {
    align-self: center;
  }

  ${({ $largerBreakpoint }) =>
    !$largerBreakpoint
    && css`
      @media screen and (width >= 1070px) {
        display: none;
      }
    `}

  ${({ $largerBreakpoint }) =>
    $largerBreakpoint
    && css`
      @media screen and (width >= 1239px) {
        display: none;
      }
    `}
`;

export const DesktopNav = styled.div<{ $largerBreakpoint?: boolean }>`
  position: relative;
  display: none;
  block-size: 56px;
  margin: 0 auto;
  max-inline-size: ${breakpoints.maxPageWidth};
  justify-content: space-between;
  align-items: center;

  ${({ $largerBreakpoint }) =>
    !$largerBreakpoint
    && css`
      @media screen and (width >= 1070px) {
        display: flex;
      }
    `}

  ${({ $largerBreakpoint }) =>
    $largerBreakpoint
    && css`
      @media screen and (width >= 1239px) {
        display: flex;
      }
    `}
`;

export const TopActions = styled.div`
  display: flex;
  block-size: 100%;

  > * + * {
    margin-inline-start: ${spacing.small};
  }

  > button,
  > div button {
    appearance: none;
    padding: 0;
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inline-size: auto;
    block-size: 100%;
    cursor: pointer;

    svg {
      pointer-events: none;
    }

    svg,
    a {
      block-size: ${spacing.regular};
    }

    a {
      display: flex;
      align-items: center;
      letter-spacing: 0.15rem;
      text-decoration: none;
      text-transform: uppercase;
      font-size: ${fontSizes.xsmall};
      font-weight: ${fontWeights.medium};
      color: ${colors.black};
    }

    &:hover {
      svg {
        color: ${colors.gray1};
      }
    }
  }
`;

export const ShopLinkContainer = styled.div`
  border-inline-end: thin solid ${colors.border};
  padding-inline: ${spacing.xxsmall} ${spacing.xxsmall};
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    letter-spacing: 0.15rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${fontSizes.xsmall};
    font-weight: ${fontWeights.medium};
  }

  svg {
    pointer-events: none;
    margin-inline-start: ${spacing.xxsmall};
  }
`;

export const ShopLink = styled(Link)`
  align-self: center;
  color: ${colors.black};
  block-size: 1.5rem;
`;

export const IconLabel = styled.span`
  font-size: 12px;
  color: ${colors.black};
  white-space: pre;
`;

export const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    block-size: 28px;
  }
`;
