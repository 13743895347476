import styled from 'styled-components';

import { fontSizes, lineHeights } from '@hultafors/hultafors/tokens';

interface ParagraphStyledProps {
  $small?: boolean;
}

export const ParagraphStyled = styled.p<ParagraphStyledProps>`
  font-size: ${({ $small }) => ($small ? fontSizes.small : fontSizes.body)};
  line-height: ${({ $small }) =>
    $small ? lineHeights.small : lineHeights.body};
`;
