export const spacing = {
  mega: '17.5rem', // 280px
  xxlarge: '9.5rem', // 152px
  xlarge: '6rem', // 96px
  large: '4rem', // 64px
  medium: '3rem', // 48px
  block: '2rem', // 32px
  regular: '1.5rem', // 24px
  small: '1rem',
  xsmall: '0.5rem',
  xxsmall: '0.25rem',
  pagePadding: '30px',
  pagePaddingMobile: '20px',
};

export type Spacing = keyof typeof spacing;
