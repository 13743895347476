import { useState } from 'react';

import { isMorePages } from '@hultafors/shared/helpers';
import {
  ListApiPaging,
  ProductFilter,
  QueryProductFilter,
} from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { HultaforsProduct } from '@hultafors/hultafors/types';

import { Button } from '../Button/Button';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';
import { Filter } from '../Filter/Filter';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Icon } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';
import { Pager } from '../Pager/Pager';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';
import { SlideIn } from '../SlideIn/SlideIn';

import { ClearContainer, IconButton } from './ProductListBlock.styled';

export interface ProductsBlockProps {
  loading: boolean;
  error: boolean;
  products: HultaforsProduct[];
  filters?: ProductFilter[];
  paging?: ListApiPaging;
  productsPageSize: number;
  selectedFilters?: any[];
  filterChange(input: QueryProductFilter): void;
  clearFilters: () => void;
  loadMore: (e: any) => void;
  loadingMore: boolean;
  categorySlug: string;
}

export const ProductListBlock: React.FC<ProductsBlockProps> = ({
  loading,
  productsPageSize,
  filterChange,
  clearFilters,
  loadMore,
  loadingMore,
  products = [],
  paging,
  filters = [],
  categorySlug,
}) => {
  const { globalContent } = useGlobal();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  if (loading) {
    return <Loader />;
  }
  const onMobileClearClick = () => {
    clearFilters();
    toggleFilter();
  };

  return (
    globalContent && (
      <>
        <Section smallMarginTop>
          <Grid
            columns={1}
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
          >
            <GridChild columnSpan={[{ columns: 1 }]}>
              <Filter
                filters={filters}
                filterChange={filterChange}
                clearFilter={clearFilters}
                clearFilterLabel={globalContent.clearFilterLabel || ''}
                toggleFilter={toggleFilter}
              />
            </GridChild>
          </Grid>

          <Grid
            columnGap={[{ columnGap: 20 }]}
            columns={[
              { columns: 1 },
              { breakpoint: 'mediumMobile', columns: 2 },
              { breakpoint: 'desktop', columns: 3 },
              { breakpoint: 'desktopMedium', columns: 4 },
            ]}
          >
            <Products
              products={products}
              badgeText={globalContent?.newLabel || ''}
              modelText={globalContent?.modelsLabel || ''}
              numberOfItems={productsPageSize}
              loading={loadingMore}
              categorySlug={categorySlug}
            />
          </Grid>
          <Pager
            buttonLabel={globalContent?.showMoreLabel || ''}
            ofLabel={globalContent?.ofLabel || ''}
            productsLabel={globalContent?.productsLabel || ''}
            onClick={loadMore}
            paging={paging}
            morePages={paging ? isMorePages(paging) : false}
            loading={loadingMore}
          />
        </Section>

        <SlideIn
          isOpen={isFilterOpen}
          toggle={toggleFilter}
          headerText={globalContent?.filterLabel || ''}
          headerIcon="filter"
          padContent
          clearText={globalContent?.clearLabel || ''}
          clearAll={clearFilters}
        >
          <div>
            <ClearContainer>
              <IconButton onClick={onMobileClearClick} text>
                {globalContent?.clearFilterLabel}
                <Icon svg="trash" width={16} />
              </IconButton>
            </ClearContainer>
            <Filter
              filters={filters}
              filterChange={filterChange}
              clearFilter={clearFilters}
              clearFilterLabel={globalContent.clearFilterLabel || ''}
              toggleFilter={toggleFilter}
              isMobileFilter
            />
            <ButtonWrapper>
              <Button
                onClick={() => toggleFilter()}
                className="Black"
                fullWidth
              >
                {globalContent?.doneLabel || 'Done'}
              </Button>
            </ButtonWrapper>
          </div>
        </SlideIn>
      </>
    )
  );
};
