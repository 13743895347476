import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { DialogComponent } from '../DialogComponent/DialogComponent';

export const BackDrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: black;
  opacity: 0.3;
  z-index: 2;
`;

export const StyledDialog = styled(DialogComponent)`
  height: 360px;
  max-height: 80%;
  width: 90%;
  padding: 0 4rem;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    inline-size: 670px;
  }
`;

export const DialogContent = styled.div`
  position: relative; /* anchor for BuyingOptionsList */
  height: 100%;

  div {
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    inline-size: 100%;
  }
`;
