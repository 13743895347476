import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const StyledRetailersAdditionalSelections = styled.div`
  margin-top: ${spacing.regular};

  .RadiusSelectContainer {
    label {
      font-size: ${fontSizes.body};
    }
  }
`;
