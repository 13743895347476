import { VisibleSeoBlockFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';

import { StyledParagraph } from './VisibleSeoBlockStyle';

export const VisibleSeoBlock: React.FC<VisibleSeoBlockFragment> = ({
  visibleSeoDescription,
  visibleSeoTitle,
}) => {
  return (
    <Section>
      <Grid columns={[{ columns: 1 }, { columns: 12, breakpoint: 'tablet' }]}>
        <GridChild
          columnSpan={[
            { start: 1, columns: 1 },
            { start: 2, columns: 6, breakpoint: 'tablet' },
          ]}
        >
          <StyledParagraph>{visibleSeoTitle}</StyledParagraph>
          <Markdown
            variant="dark"
            options={{
              forceBlock: true,
              overrides: { p: Paragraph },
            }}
          >
            {visibleSeoDescription}
          </Markdown>
        </GridChild>
      </Grid>
    </Section>
  );
};
