import { EmptyProduct } from '../EmptyProduct/EmptyProduct';

interface EmptyProductsProps {
  pageSize: number;
}

export const EmptyProducts: React.FC<EmptyProductsProps> = ({ pageSize }) => {
  const items: number[] = Array.from(Array(pageSize));
  function itemMapper(_item: number, i: number) {
    return <EmptyProduct key={`EP-${i}`} />;
  }
  return <>{items.map(itemMapper)}</>;
};
