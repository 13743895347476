import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

export const ContentLayoutStyled = styled.div`
  display: flex;
  gap: ${spacing.xlarge};
  justify-content: center;
  width: 100%;
  margin: ${spacing.medium} 0;

  @media screen and (max-width: ${breakpoints.desktop}) {
    gap: ${spacing.xsmall};
  }
`;
