import { Icon, IconSvg } from '../Icon/Icon';

import { ButtonStyled } from './Button.styled';

interface ButtonProps {
  'children'?: React.ReactNode;
  'className'?: string;
  'disabled'?: boolean;
  'svgIcon'?: IconSvg;
  'svgWidth'?: number;
  'onClick'?: React.MouseEventHandler<HTMLButtonElement>;
  'type'?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  'aria-label'?: string;
  'fullWidth'?: boolean;
  'center'?: boolean;
  'minWidth'?: string;
  'progress'?: boolean;
  'text'?: boolean;
  'bold'?: boolean;
  'uppercase'?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  type = 'submit',
  children,
  svgIcon,
  svgWidth,
  className,
  disabled,
  fullWidth,
  center,
  minWidth,
  progress,
  text,
  bold,
  uppercase,
  ...rest
}) => {
  return (
    <ButtonStyled
      $fullWidth={fullWidth}
      $center={center}
      $minWidth={minWidth}
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
      $progress={progress}
      $text={text}
      $bold={bold}
      $uppercase={uppercase}
      {...rest}
      data-test-id="Button"
    >
      {children}
      {svgIcon && <Icon svg={svgIcon} width={svgWidth} />}
    </ButtonStyled>
  );
};
