import { useEffect, useState } from 'react';

import Image from 'next/image';

import { useCart } from '@hultafors/hultafors/hooks';
import { CartItemData } from '@hultafors/hultafors/types';

import { AmountInput } from '../AmountInput/AmountInput';
import { Icon } from '../Icon/Icon';

import {
  CartItemStyled,
  CloseButton,
  ProductDetails,
  ProductImage,
  ProductInfo,
  ProductName,
  ProductSku,
} from './CartItem.styled';

interface CartItemProps {
  item: CartItemData;
  skuLabel: string;
  closeLabel: string;
  callback?: React.MouseEventHandler<HTMLAnchorElement>;
}
export const CartItem: React.FC<CartItemProps> = ({
  item,
  skuLabel,
  closeLabel,
  callback,
}) => {
  const { cart, removeCartItem, setCartAmount } = useCart();
  const cartItem = cart?.find(({ sku }) => sku === item.sku);
  const [amount, setAmount] = useState(cartItem?.amount || 1);

  useEffect(() => {
    if (cartItem && amount !== cartItem.amount) {
      setCartAmount(item.sku, amount);
    }
  }, [amount]);

  const remove: React.MouseEventHandler<HTMLButtonElement> = () => {
    removeCartItem(item.sku);
  };

  return (
    <CartItemStyled>
      <ProductImage href={item.url || ''} onClick={callback}>
        <Image
          src={item.image || '/assets/img/noimage.png'}
          fill
          style={{ objectFit: 'contain' }}
          sizes="120px"
          alt={item.name}
        />
      </ProductImage>

      <ProductInfo>
        <ProductDetails href={item.url || ''} onClick={callback}>
          <ProductName>{`${item.name} ${item.description}`}</ProductName>
          <ProductSku>{`${skuLabel}: ${item.sku}`}</ProductSku>
        </ProductDetails>

        <AmountInput setAmount={setAmount} amount={amount} />
      </ProductInfo>
      <div>
        <CloseButton onClick={remove} aria-label={closeLabel || 'Close'}>
          <Icon svg="close" />
        </CloseButton>
      </div>
    </CartItemStyled>
  );
};
