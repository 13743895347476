import { SectionStyled } from './Section.styled';

interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  borderBottomDesktop?: boolean;
  borderTop?: boolean;
  fullBleed?: boolean;
  gray?: boolean;
  hide?: boolean;
  largePaddingTop?: boolean;
  moreMarginBottom?: boolean;
  morePaddingBottom?: boolean;
  noMargin?: boolean;
  noMarginTop?: boolean;
  paddingBottom?: boolean;
  smallMarginTop?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  style,
  borderBottomDesktop,
  borderTop,
  fullBleed,
  gray,
  hide,
  largePaddingTop,
  moreMarginBottom,
  morePaddingBottom,
  noMargin,
  noMarginTop,
  paddingBottom,
  smallMarginTop,
}) => (
  <SectionStyled
    style={style}
    className={className}
    $borderBottomDesktop={borderBottomDesktop}
    $borderTop={borderTop}
    $fullBleed={fullBleed}
    $gray={gray}
    $hide={hide}
    $largePaddingTop={largePaddingTop}
    $moreMarginBottom={moreMarginBottom}
    $morePaddingBottom={morePaddingBottom}
    $noMargin={noMargin}
    $noMarginTop={noMarginTop}
    $paddingBottom={paddingBottom}
    $smallMarginTop={smallMarginTop}
  >
    {children}
  </SectionStyled>
);
