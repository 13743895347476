import {
  RetailerTabsStyled,
  TabButton,
  TabLabel,
  TabsContainer,
} from './RetailerTabs.styled';

interface RetailerTabsProps {
  active: number;
  tabs: string[];
  setActive(value: number): void;
}

export const RetailerTabs: React.FC<RetailerTabsProps> = ({
  tabs = [],
  setActive,
  active,
}) => {
  const tabMapper = (tab: string, index: number) => (
    <TabButton
      key={`RetailerTabs-tab-${index}`}
      $active={active === index}
      onClick={() => setActive(index)}
    >
      <TabLabel small $active={active === index}>
        {tab}
      </TabLabel>
    </TabButton>
  );
  return (
    <RetailerTabsStyled>
      <TabsContainer $center>{tabs.map(tabMapper)}</TabsContainer>
    </RetailerTabsStyled>
  );
};
