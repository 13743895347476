import {
  ArticlePageFragment,
  ContentAreaField,
  IconPageFragment,
  MobileDesktopImages,
  NewsPageFragment,
  NewsPagePlugFragment,
  StoryFragment,
} from '@hultafors/hultafors/types';

import { ContentArea } from '../ContentArea/ContentArea';
import { HeroBlock } from '../HeroBlock/HeroBlock';

export interface ContentPageProps {
  pageContent:
    | (ArticlePageFragment & MobileDesktopImages)
    | (StoryFragment & MobileDesktopImages)
    | (NewsPageFragment & MobileDesktopImages)
    | (IconPageFragment & MobileDesktopImages);
  allNewsPages?: NewsPagePlugFragment[];
}

export const ContentPage: React.FC<ContentPageProps> = ({
  pageContent,
  allNewsPages = [],
}) => {
  return (
    <>
      <HeroBlock
        darkText={pageContent.darkText}
        description={pageContent.description}
        tabletImage={pageContent.tabletImage}
        mobileImage={pageContent.mobileImage}
        desktopImage={pageContent.desktopImage}
        video={pageContent.video}
        label={pageContent.label}
        title={pageContent.title}
        verticalText={pageContent.verticalText}
      />
      <ContentArea
        content={pageContent.contentArea as ContentAreaField[]}
        allNewsPages={allNewsPages}
      />
    </>
  );
};
