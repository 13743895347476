import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const FindRetailersListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  .Accordion > div {
    border-block-start: thin solid ${colors.gray4};

    &:first-of-type {
      border-block-start: none;
    }
  }

  .CollapseHeader {
    block-size: 72px;
    cursor: pointer;
    font-size: ${fontSizes.h3};
  }

  .CollapseContent {
    padding: 0;

    p {
      margin-block-end: ${spacing.small};
      line-height: ${lineHeights.body};
    }
  }
`;
