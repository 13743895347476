import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';
export const ProductModelStyled = styled.article`
  margin: ${spacing.regular} ${spacing.small};
  padding: ${spacing.small};
  box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
  border-radius: ${spacing.xxsmall};

  .ModelInfo {
    line-height: 10px;
  }
`;

export const ModelImage = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
`;

export const ModelName = styled.div`
  text-transform: uppercase;
`;

export const ModelInfo = styled.div`
  font-size: ${fontSizes.small};
  line-height: 10px;
`;

export const Outline = styled(Button)`
  width: 100%;
  margin-top: ${spacing.block};
`;

export const ProductInfo = styled.dl`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  border-bottom: thin solid ${colors.border};
`;

const sharedAttributeStyle = css`
  border-top: thin solid ${colors.border};
  margin: 0;
  padding: 0 0 ${spacing.xsmall};
`;

export const Label = styled.dt`
  ${sharedAttributeStyle};
  text-transform: uppercase;
  color: ${colors.gray1};
  font-size: ${fontSizes.supersmall};
  letter-spacing: 0.1rem;
  white-space: nowrap;
  padding-top: ${spacing.small};
`;

export const Value = styled.dd`
  ${sharedAttributeStyle};
  font-size: ${fontSizes.small};
  text-align: end;
  line-height: ${lineHeights.small};
  padding-top: ${spacing.xsmall};
`;
