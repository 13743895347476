import dynamic from 'next/dynamic';

import {
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Icon } from '../Icon/Icon';
import { Section } from '../Section/Section';

import {
  Address,
  Contact,
  IconLink,
  Left,
  Middle,
  Right,
  ViewOnMap,
  VisitWebsite,
} from './ContactStores.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionOld),
);
const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.AccordionItemOld),
);

export interface ContactStoresProps {
  offices: OfficeFragment[];
  viewOnMapLabel: string;
  localWebLabel: string;
}

export const ContactStores: React.FC<
  ContactStoresProps | OfficesBlockFragment
> = ({ offices, viewOnMapLabel, localWebLabel }) => {
  const generateGoogleMapLinks = (
    name?: string,
    country?: string,
    address?: string,
    zip_code?: string,
  ) => {
    const baseLink = 'https://www.google.com/maps/search/?api=1&query=';
    return `${baseLink}${name ? name : ''}+${country ? country : ''}+${
      address ? address : ''
    }+${zip_code ? zip_code : ''}`;
  };

  if (!offices?.length || !viewOnMapLabel || !localWebLabel) {
    return null;
  }

  const officeMapper = (item: OfficeFragment) => {
    return (
      <AccordionItem label={item.country || ''} key={`Office-${item.id}`}>
        {item.address && (
          <Address>
            {item.name}
            <br />
            {item.address}
            <br />
            {`${item.zipcode}`}
            {item.country ? ', ' + item.country : ''}
          </Address>
        )}
        <ViewOnMap>
          <IconLink
            href={
              generateGoogleMapLinks(
                item.name || undefined,
                item.country || undefined,
                item.address || undefined,
                item.zipcode || undefined,
              ) || ''
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon svg="placeTag" width={16} />
            {viewOnMapLabel}
          </IconLink>
        </ViewOnMap>

        <Contact>
          {item.email && (
            <Left $border={!item.shopEmail}>
              <IconLink href={`mailto:${item.email}`}>
                <Icon svg="mail" width={16} />
                {item.emailLabel || item.email}
              </IconLink>
            </Left>
          )}
          {item.shopEmail && (
            <Middle>
              <IconLink href={`mailto:${item.shopEmail}`}>
                <Icon svg="mail" width={16} />
                {item.shopEmailLabel}
              </IconLink>
            </Middle>
          )}
          {item.phoneNumber && (
            <Right>
              <IconLink href={`tel:${item.phoneNumber}`}>
                <Icon svg="phone" width={16} />
                {item.phoneNumber}
              </IconLink>
            </Right>
          )}

          {item.companyWebsite && (
            <VisitWebsite
              href={item.companyWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              {localWebLabel}
              <Icon svg="externalLink" width={16} />
            </VisitWebsite>
          )}
        </Contact>
      </AccordionItem>
    );
  };

  return (
    <Section>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
            { breakpoint: 'desktopMedium', columns: 6, start: 4 },
          ]}
        >
          <Accordion allowZeroExpanded>{offices.map(officeMapper)}</Accordion>
        </GridChild>
      </Grid>
    </Section>
  );
};
