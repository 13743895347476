import styled from 'styled-components';

import { spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';

export const ClearContainer = styled.div`
  margin-block-end: ${spacing.xsmall};
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled(Button)`
  padding: ${spacing.xsmall} ${spacing.small} 0 0;
  height: ${spacing.block};
  border: 0;

  svg {
    margin-inline-start: ${spacing.xsmall};
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
  }
`;
