import Link from 'next/link';
import { useRouter } from 'next/router';

import { ApiSettings } from '@hultafors/shared/types';

import { routes } from '@hultafors/hultafors/helpers/routing';
import { useCart, useGlobal } from '@hultafors/hultafors/hooks';
import { GlobalFields, MenuItemFragment } from '@hultafors/hultafors/types';

import { Icon } from '../Icon/Icon';
import { NavItem } from '../NavItem/NavItem';

import {
  CenterMenu,
  DesktopNav,
  IconLabel,
  Logo,
  MenuActions,
  MenuToggler,
  MobileNav,
  NavigationStyled,
  NavItemContainer,
  ShopLink,
  ShopLinkContainer,
  TopActions,
  TopMenu,
  TopMenuLink,
  TopMenuLinkInner,
  TopMenuToggle,
} from './Navigation.styled';

interface NavigationProps {
  isOpen: boolean;
  globalContent: GlobalFields['global'];
  menuData: GlobalFields['allMenuItems'];
  settings: ApiSettings;
  openDesktopMenuType: string;
  toggleMobileMenu(): void;
  toggleDesktopMenu(value: string): void;
  toggleFavoritesMenu(value: string): void;
  toggleCartMenu(value: string): void;
  toggleMarketPicker(): void;
  shopLinkUrl?: string | null;
  shopLinkLabel?: string | null;
}

export const Navigation: React.FC<NavigationProps> = ({
  menuData,
  globalContent,
  openDesktopMenuType,
  toggleMobileMenu,
  toggleCartMenu,
  toggleFavoritesMenu,
  toggleDesktopMenu,
  toggleMarketPicker,
  shopLinkUrl,
  shopLinkLabel,
}) => {
  const { cart } = useCart();
  const global = useGlobal();
  const { asPath } = useRouter();

  const toggleFavorites = () => {
    toggleFavoritesMenu('');
  };
  const toggleCart = () => {
    toggleCartMenu('');
  };

  /*
  Some markets have too much text in the nav menu.
  The variable 'largerBreakpoint' compares to markets in 'marketsWithWideText'
  and if a market is included, the breakpoint is enlarged in order
  to fit everything, including the nav icon labels, in the nav.
  */
  const marketsWithLargeText = ['de'];

  const largerBreakpoint = marketsWithLargeText.includes(
    global?.settings?.market,
  );

  function menuDataMapper(item: MenuItemFragment) {
    const href = `/${item.pageLink?.slug || item.url}`.replace(/\/\//g, '/');
    if (item.children?.length) {
      const onClick: React.MouseEventHandler = () =>
        toggleDesktopMenu(item.title ?? '');
      return (
        <TopMenuToggle
          key={`MenuItem-${item.id}`}
          $active={asPath.startsWith(`/${routes.PRODUCTS}`)}
          $open={openDesktopMenuType === item.title}
          onClick={onClick}
          data-slidein-ignore="desktopMenu"
        >
          <TopMenuLinkInner>{item.title}</TopMenuLinkInner>
        </TopMenuToggle>
      );
    }
    if (!item.hide) {
      return (
        <TopMenuLink key={`MenuItemLink-${item.id}`} href={href || ''}>
          <TopMenuLinkInner>{item.title}</TopMenuLinkInner>
        </TopMenuLink>
      );
    }
    return null;
  }
  if (!menuData) {
    return null;
  }
  return (
    <NavigationStyled>
      <MobileNav $largerBreakpoint={largerBreakpoint}>
        <MenuToggler
          onClick={toggleMobileMenu}
          aria-label={globalContent.menuLabel ?? ''}
        >
          <Icon svg="menu" />
        </MenuToggler>
        <Logo>
          <Link href="/" aria-label="Hultafors Tools">
            <Icon svg="logo" width={98} height={32} />
          </Link>
        </Logo>

        <MenuActions>
          <NavItem
            isFavorite
            toggle={toggleFavorites}
            label={globalContent.favoritesHeader ?? ''}
          />
          {globalContent.changeLanguageLabel
          && globalContent.changeLanguageLink && (
            <NavItem
              className="Language"
              isLanguage
              label={globalContent.changeLanguageLabel}
              toggle={toggleMarketPicker}
            />
          )}
          {shopLinkUrl && shopLinkLabel && (
            <ShopLinkContainer>
              <ShopLink
                href={shopLinkUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shopLinkLabel}
                <Icon svg="shop" />
              </ShopLink>
            </ShopLinkContainer>
          )}
        </MenuActions>
      </MobileNav>
      <DesktopNav $largerBreakpoint={largerBreakpoint}>
        <Logo>
          <Link href="/" aria-label="Hultafors Tools">
            <Icon svg="logo" width={98} height={32} />
          </Link>
        </Logo>

        <TopMenu>
          <CenterMenu $largerBreakpoint={largerBreakpoint}>
            {menuData?.map(menuDataMapper)}
          </CenterMenu>
        </TopMenu>
        <TopActions>
          <NavItemContainer>
            <NavItem
              isSearch
              toggle={() => toggleDesktopMenu('search')}
              data-slidein-ignore="desktopMenu"
              aria-label={globalContent.searchLabel ?? ''}
              label={globalContent.searchLabel ?? ''}
            />
            <IconLabel>{globalContent.searchLabel ?? ''}</IconLabel>
          </NavItemContainer>
          {globalContent.showShoppinglist
          && globalContent.shoppingListHeader && (
            <NavItem
              isSearch
              itemsCount={cart.length}
              toggle={toggleCart}
              label={globalContent.shoppingListHeader}
            />
          )}
          {globalContent.changeLanguageLabel
          && globalContent.changeLanguageLink && (
            <NavItemContainer>
              <NavItem
                className="Language"
                isLanguage
                label={globalContent.changeLanguageLabel}
                toggle={toggleMarketPicker}
              />
              <IconLabel>
                {globalContent?.changeLanguageLabel ?? ''}
              </IconLabel>
            </NavItemContainer>
          )}
          {globalContent.partnerPortalUrl && (
            <NavItemContainer>
              <Link
                href={globalContent.partnerPortalUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={globalContent.partnerPortalLinkLabel ?? ''}
              >
                <Icon svg="partnerLogin" />
              </Link>
              <IconLabel>{globalContent?.partnerLabel ?? ''}</IconLabel>
            </NavItemContainer>
          )}
          {shopLinkUrl && shopLinkLabel && (
            <NavItemContainer>
              <ShopLink
                href={shopLinkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon svg="shop" />
              </ShopLink>
              <IconLabel>{shopLinkLabel}</IconLabel>
            </NavItemContainer>
          )}
        </TopActions>
      </DesktopNav>
    </NavigationStyled>
  );
};
