import styled from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/hultafors/tokens';

interface BoxStyledProps {
  $marginTop?: boolean;
  $shadow?: boolean;
}

export const BoxStyled = styled.div<BoxStyledProps>`
  padding: ${spacing.small};
  margin-top: ${({ $marginTop }) => ($marginTop ? '3rem' : '0')};
  margin-bottom: ${spacing.small};
  border-radius: 5px;
  background-color: ${colors.white};
  transition: 0.1s;

  h3 {
    font-weight: ${fontWeights.normal};
  }

  &:hover {
    box-shadow: ${({ $shadow }) =>
      $shadow ? '0 5px 15px rgb(175 175 175 / 50%)' : 'none'};
    transition: 0.2s;
  }
`;
