import { ParagraphStyled } from './Paragraph.styled';

interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  tagName?: 'p' | 'span' | 'dd';
  small?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  small,
  style,
  tagName,
}) => (
  <ParagraphStyled
    as={tagName}
    className={className}
    $small={small}
    style={style}
  >
    {children}
  </ParagraphStyled>
);
