import styled from 'styled-components';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultafors/tokens';

interface H5StyledProps {
  $center?: boolean;
  $underline?: boolean;
}

export const H5Styled = styled.h5<H5StyledProps>`
  text-align: ${({ $center }) => ($center ? 'center' : '')};
  font-size: ${fontSizes.h5};
  line-height: ${lineHeights.h5};
  font-weight: ${fontWeights.normal};
  margin: 0;
`;
