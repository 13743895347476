import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

interface TextBlockStyledProps {
  $center?: boolean;
  $fullWidth?: boolean;
}
export const TextBlockStyled = styled.div<TextBlockStyledProps>`
  margin: ${spacing.medium} 0;
  padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
    padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePadding};
  }

  h2 {
    margin: 0;
    margin-block-end: ${spacing.regular};
    font-size: ${fontSizes.h2mobile};
    line-height: ${lineHeights.h2mobile};
    font-weight: ${fontWeights.normal};
    text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.h2};
      line-height: ${lineHeights.h2};
    }
  }

  h3 {
    font-size: ${fontSizes.h4};
    font-weight: ${fontWeights.normal};
    line-height: ${lineHeights.h4};

    /* margin-bottom: ${spacing.xsmall}; */
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.h3};
      line-height: ${lineHeights.h3};
    }
  }

  h4 {
    font-size: ${fontSizes.h4};
    line-height: ${lineHeights.h4};
    font-weight: ${fontWeights.normal};

    /* margin-bottom: ${spacing.small}; */
    margin-block-end: 0;
  }

  div > h3,
  div > h4 {
    margin-block-start: ${spacing.block};
  }

  h2 + .BodyText {
    margin-block-start: ${spacing.small};
  }

  p + h2 {
    margin-block-start: ${spacing.medium};
  }

  p {
    font-size: ${fontSizes.small};
    line-height: 24px;
    margin-block-end: 12px;
    white-space: pre-wrap;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.body};
      line-height: 26px;
    }

    strong {
      font-weight: ${fontWeights.medium};
    }
  }

  ul {
    li {
      margin: 0;

      p {
        margin: 0;
      }
    }
  }

  ol {
    li {
      margin: 0;

      p {
        margin: 0;
      }
    }
  }

  p:last-of-type {
    margin-block-end: 0;
  }

  .BodyText *:first-child {
    margin-block-start: 0;
  }
`;
