import { Component } from 'react';
import React from 'react';

import { Collapse } from '../Collapse/Collapse';

import { AccordionStyled } from './Accordion.styled';
interface AccordionProps {
  small?: boolean;
  allowMultipleOpen?: boolean;
  callBack?: (...args: any[]) => any;
  selectItem?(id?: string): void;
  selectedItemId?: string;
  $noPadding?: boolean;
  $keyPrefix?: string;
  subLabel?: string;
  children?: React.ReactNode;
}

type OpenSections = Record<string, boolean>;
interface AccordionState {
  openSections: OpenSections;
}
export class Accordion extends Component<AccordionProps, AccordionState> {
  constructor(props: AccordionProps) {
    super(props);
    const openSections: OpenSections = {};
    if (this.props.children && React.Children.count(this.props.children) > 1) {
      React.Children.forEach(this.props.children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }
        if (child?.props?.isOpen && child.key) {
          openSections[child.key] = true;
        }
      });
    }
    this.state = { openSections };
  }

  override componentDidUpdate(prevProps: AccordionProps) {
    if (prevProps.selectedItemId !== this.props.selectedItemId) {
      if (this.props.selectedItemId) {
        const key = `${this.props.$keyPrefix}-${this.props.selectedItemId}`;
        this.setState({
          openSections: {
            [key]: true,
          },
        });
      }
    }
  }

  onClick = (key: string, id: string) => {
    if (this.props.selectedItemId) {
      // reset selected from outside
      this.props.selectItem?.();
    }
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;
    const isOpen = !!openSections[key];
    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [key]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [key]: !isOpen,
        },
      });
    }
    if (id && !isOpen) {
      this.props.selectItem?.(id);
    }
    if (this.props.callBack) {
      this.props.callBack(key);
    }
  };

  shouldRenderCollapsible(child: any) {
    return (
      (child?.props.children
      && typeof child.props.children !== 'string'
      && child.props.children.length > 0)
      || child?.props.$forceCollapsible
    );
  }

  override render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this;
    return (
      <AccordionStyled className="Accordion">
        {React.Children.map(children, (child, i) => {
          if (
            React.isValidElement(child)
            && this.shouldRenderCollapsible(child)
          ) {
            return (
              <Collapse
                key={`Collapse-${i}`}
                isOpen={!!(child.key && !openSections[child.key])}
                label={child.props.label}
                subLabel={child.props.subLabel}
                onClick={() => child.key && onClick(child.key, child.props.id)}
                small={this.props.small}
                $noPadding={this.props.$noPadding}
              >
                {child.props.children ? child.props.children : child}
              </Collapse>
            );
          }
          if (React.isValidElement<{ children?: React.ReactNode }>(child)) {
            return (
              <div className="notCollapsibleItem" key={`Accordion-${i}`}>
                {child.props.children}
              </div>
            );
          }
          return null;
        })}
      </AccordionStyled>
    );
  }
}
