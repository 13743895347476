import { BadgeStyled } from './Badge.styled';

interface BadgeProps {
  children?: string | number;
  className?: string;
  small?: boolean;
  tiny?: boolean;
  red?: boolean;
  round?: boolean;
  styleName?: string;
  isNew?: boolean;
  premium?: boolean;
  sustainable?: boolean;
  inline?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({
  className,
  small,
  tiny,
  red,
  round,
  styleName,
  premium,
  sustainable,
  inline,
  isNew,
  children,
}) => (
  <BadgeStyled
    className={`Badge ${styleName} ${className}`}
    $small={small}
    $tiny={tiny}
    $red={red}
    $round={round}
    $styleName={styleName}
    $premium={premium}
    $sustainable={sustainable}
    $inline={inline}
    $isNew={isNew}
  >
    {children ?? ''}
  </BadgeStyled>
);
