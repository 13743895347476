import { H5Styled } from './H5.styled';

interface H5Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
  large?: boolean;
}

export const H5: React.FC<H5Props> = ({ children, className, center }) => {
  return (
    <H5Styled $center={center} className={className}>
      {children}
    </H5Styled>
  );
};
