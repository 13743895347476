import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/hultafors/tokens';

export const CategoryListBlockStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  place-items: start;
  background: ${colors.white};

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  > * {
    aspect-ratio: 1 / 1;
  }
`;
