export interface AccordionItemProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  children?: React.ReactNode;
  $label?: string | null;
  $forceCollapsible?: boolean;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  $label,
  $forceCollapsible,
  ...rest
}) => {
  return <li {...rest}>{children}</li>;
};
