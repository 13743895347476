import Image from 'next/image';

import { FavoriteItem } from '@hultafors/shared/types';

import { useFavorites, useGlobal } from '@hultafors/hultafors/hooks';

import { Icon } from '../Icon/Icon';

import {
  FavoriteCardStyled,
  ImageContainer,
  ProductLink,
  ProductName,
  ToggleButton,
  ToggleContainer,
} from './FavoriteCard.styled';

interface FavoriteCardProps {
  item: FavoriteItem;
  callback?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const FavoriteCard: React.FC<FavoriteCardProps> = ({
  item,
  callback,
}) => {
  const { toggleFavorite } = useFavorites();
  const onClick = () => toggleFavorite(item);
  const { globalContent } = useGlobal();
  if (!item) {
    return null;
  }
  return (
    <FavoriteCardStyled>
      <ToggleContainer>
        <ToggleButton
          onClick={onClick}
          aria-label={globalContent.removeFromFavoritesLabel ?? ''}
        >
          <Icon svg="closeOutlined" width={16} height={16} />
        </ToggleButton>
      </ToggleContainer>
      <ProductLink href={item.url || ''} onClick={callback}>
        <ImageContainer>
          <Image
            src={item.image ?? ''}
            fill
            style={{ objectFit: 'contain' }}
            sizes="150px"
            alt={item.name || ''}
          />
        </ImageContainer>
        <ProductName>{item.name || ''}</ProductName>
      </ProductLink>
    </FavoriteCardStyled>
  );
};
