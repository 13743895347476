import Image from 'next/image';

import {
  PersonellListFragment,
  PersonFragment,
} from '@hultafors/hultafors/types';

import { H2 } from '../H2/H2';

import {
  ImageWrapper,
  InfoText,
  Inner,
  Person,
  PersonellListStyled,
  TextWrapper,
} from './personell-list.styled';

export const PersonellList: React.FC<PersonellListFragment> = ({
  alignTextCenter,
  alignTextTop,
  personell,
  title,
}) => {
  function personMapper(person: PersonFragment, index: number) {
    if (!person) {
      return null;
    }
    const key = `person-${index}`;
    const hasImage = !!person.image?.responsiveImage?.src;

    return (
      <Person key={key}>
        {hasImage && (
          <ImageWrapper>
            <Image
              src={person?.image?.responsiveImage?.src ?? ''}
              alt={person?.image?.alt ?? ''}
              fill
              style={{ objectFit: 'contain', aspectRatio: '1' }}
              blurDataURL={person?.image?.responsiveImage?.base64 ?? undefined}
              placeholder="blur"
            />
          </ImageWrapper>
        )}
        <TextWrapper
          $alignTextCenter={alignTextCenter}
          $alignTextTop={alignTextTop}
          $hasImage={hasImage}
        >
          <InfoText
            $alignTextCenter={alignTextCenter}
            $alignTextTop={alignTextTop}
          >
            {person?.text ?? ''}
          </InfoText>
        </TextWrapper>
      </Person>
    );
  }

  if (!personell?.length) {
    return null;
  }

  return (
    <PersonellListStyled>
      <Inner>
        {title && <H2>{title}</H2>}
        {personell?.map(personMapper)}
      </Inner>
    </PersonellListStyled>
  );
};
