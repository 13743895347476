import styled from 'styled-components';

import { colors } from '@hultafors/hultafors/tokens';

export const RouterLinkStyled = styled.span`
  a {
    block-size: 100%;
    color: ${colors.black};
    text-decoration: none;
    transition: all 0.2s;
    position: relative;

    &:hover {
      border-color: #555;
      color: ${colors.gray1};
      transition: all 0.2s;
    }

    &:focus {
      color: inherit;
      box-shadow: none !important;
      outline: 0;
      border: 3px auto;
    }

    &:active {
      color: inherit;
    }
  }
`;
