import Markdown from 'markdown-to-jsx';
import styled, { css } from 'styled-components';

import { colors } from '@hultafors/hultafors/tokens';

const getAnchorStyle = (variant?: string) => {
  switch (variant) {
    case 'light':
      return css`
        a {
          text-decoration: underline;
          color: ${colors.white};

          &:hover {
            color: ${colors.gray3};
          }
        }
      `;
    case 'dark':
      return css`
        a {
          text-decoration: underline;
          color: ${colors.black};

          &:hover {
            color: ${colors.gray1};
          }
        }
      `;
    default:
      return '';
  }
};

interface StyledMarkdownProps {
  $variant?: string;
}
export const StyledMarkdown = styled(Markdown)<StyledMarkdownProps>`
  ${(props) => getAnchorStyle(props.$variant)};
`;
