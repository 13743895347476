import { TextButtonStyled } from './TextButton.styled';

interface TextButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
  center?: boolean;
  iconUrl?: string;
  iconLeft?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  className,
  onClick,
  children,
  center,
  iconLeft,
  iconUrl,
}) => {
  return (
    <TextButtonStyled
      className={className}
      onClick={onClick}
      $center={center}
      $iconLeft={iconLeft}
      $iconUrl={iconUrl}
    >
      {children}
    </TextButtonStyled>
  );
};
