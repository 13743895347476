import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontWeights, spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';

export const SubscribeText = styled.div`
  font-weight: ${fontWeights.medium};
  margin-bottom: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    text-align: end;
  }
`;

export const SubscribeButton = styled(Button)`
  width: fit-content;
  right: 0;
`;
