import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const StoreListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: 0;
  }

  .Accordion > div {
    border-block-start: thin solid ${colors.border};
  }

  .CollapseHeader {
    block-size: ${spacing.large};
    cursor: pointer;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    color: ${colors.black};

    &:hover {
      color: ${colors.gray1};
    }
  }

  p,
  label {
    font-size: 14px;
  }

  .CollapseContent {
    padding: 0;

    p {
      margin-block-end: ${spacing.small};
    }
  }

  .Label {
    margin: 0;
  }

  .Address {
    margin: 0 0 30px;
    line-height: 1.25rem;
  }

  .ViewOnMap {
    margin-block-end: 30px;
  }

  .Contact {
    display: flex;
    flex-direction: row;
    border-block-start: thin solid ${colors.gray4};
    align-items: center;
    padding: 0;
    block-size: 60px;

    &.EmailAndPhoneWrapper {
      flex-direction: column;
      block-size: auto;
      padding: 20px 0;
      align-items: flex-start;

      @media screen and (min-width: ${breakpoints.mediumMobile}) {
        flex-direction: row;
        block-size: 60px;
        padding: 0;
        align-items: center;
      }

      @media screen and (max-width: ${breakpoints.bigMobile}) {
        justify-content: center;
      }
    }

    div {
      display: inline-flex;

      &.Right {
        padding-inline-start: 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding-inline-start: ${spacing.small};
          block-size: 100%;
          align-items: center;
        }
      }

      &.AddBorderRight {
        border-inline-end: thin solid ${colors.gray4};
      }

      &.Middle {
        padding: 0 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding: 0 ${spacing.small};
          border-inline-end: thin solid ${colors.gray4};
          border-inline-start: thin solid ${colors.gray4};
          block-size: 100%;
          align-items: center;
        }
      }

      &.Left {
        padding-inline-end: 20px;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          padding-inline-end: ${spacing.small};
          block-size: 100%;
          align-items: center;
        }
      }
    }

    &.VisitSite {
      justify-content: flex-end;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    label {
      inline-size: 100%;
      margin-block-end: 6px;
    }

    &.StoreSells {
      align-items: center;
      min-block-size: 90px;
      block-size: auto;

      .StoresWrapper {
        flex-direction: column;
      }

      a {
        text-decoration: underline;
      }
    }

    .Stores {
      inline-size: 100%;

      div {
        .Separator {
          display: inline-flex;
          margin: 0 5px;
        }
      }
    }
  }

  .Accordion > div:last-of-type {
    border-block-end: thin solid ${colors.border};
  }

  .MapLink {
    text-decoration: none;
  }

  .LeftIconLink {
    position: relative;

    svg {
      margin-inline-end: 6px;
    }
  }
`;
