import { useEffect, useState } from 'react';

import Image from 'next/image';
import { useKey } from 'react-use';

import { breakpoints } from '@hultafors/shared/constants';
import { ProductDetailsImage } from '@hultafors/shared/types';

import { createProductImageSrc } from '@hultafors/hultafors/helpers/create-product-image-src/create-product-image-src';

import { Icon } from '../Icon/Icon';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { Modal } from '../Modal/Modal';

import {
  DesktopGrid,
  DesktopImages,
  ImageWrapper,
  MobileImages,
  ProductDetailsImagesStyled,
  ShowAllButton,
  ZoomIcon,
} from './ProductDetailsImages.styled';

export interface ProductDetailsImagesProps {
  children?: React.ReactNode;
  className?: string;
  images: ProductDetailsImage[];
  alt?: string;
  viewAll?: string;
}

const MAX_NUMBER_OF_PRODUCT_IMAGES = 4;

export const ProductDetailsImages: React.FC<ProductDetailsImagesProps> = ({
  images = [],
  alt = '',
  viewAll = '',
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [carouselOpen, setCarouselOpen] = useState(false);

  useKey('Escape', () => {
    setCarouselOpen(false);
  });

  const toggleCarousel = () => {
    setCarouselOpen(!carouselOpen);
  };
  useEffect(() => {
    if (!carouselOpen) {
      setCurrentImageIndex(0);
    }
  }, [carouselOpen]);

  const showALl = () => {
    setCurrentImageIndex(0);
    setCarouselOpen(true);
  };

  const sizes = [
    '(min-width: 1500px) 402px',
    `(min-width: ${breakpoints.desktop}) calc((((100vw - 280px) / 12) * 7 + 92px) / 2)`,
    `(min-width: ${breakpoints.mobileMax}) calc(50vw - 32px)`,
    '100vw',
  ].join(', ');

  function imageMapper(image: ProductDetailsImage, i: number) {
    if (i >= MAX_NUMBER_OF_PRODUCT_IMAGES) {
      return null;
    }

    const onClick = () => {
      setCurrentImageIndex(i);
      setCarouselOpen(true);
    };
    return (
      <ImageWrapper
        key={`ProductDetailsImage-${i}`}
        data-index={i}
        onClick={onClick}
      >
        <Image
          src={createProductImageSrc(image.url)}
          alt={alt || ''}
          fill
          style={{ objectFit: 'contain' }}
          sizes={sizes}
        />
        <ZoomIcon>
          <Icon svg="plus" width={16} />
        </ZoomIcon>
      </ImageWrapper>
    );
  }

  return images?.length
    ? (
      <ProductDetailsImagesStyled>
        <MobileImages>
          <ImageSlider productPage images={images} width={300} height={300} />
        </MobileImages>
        <DesktopImages>
          <DesktopGrid>{images.map(imageMapper)}</DesktopGrid>

          {images.length > MAX_NUMBER_OF_PRODUCT_IMAGES && (
            <ShowAllButton center onClick={showALl}>
              {viewAll}
            </ShowAllButton>
          )}
        </DesktopImages>
        {!!images?.length && (
          <Modal toggle={toggleCarousel} isOpen={carouselOpen}>
            {carouselOpen && (
              <ImageSlider
                images={images}
                currentImageIndex={currentImageIndex}
                inModal
              />
            )}
          </Modal>
        )}
      </ProductDetailsImagesStyled>
      )
    : null;
};
