import styled from 'styled-components';

export const ScreenReaderOnlyStyled = styled.span`
  &:not(:focus, :active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    block-size: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    inline-size: 1px;
  }
`;
