import ShoppingList from '../../svg/shoppingList.svg';
import { AmountInput } from '../AmountInput/AmountInput';

import { AddToCartButton, AddToCartStyled } from './AddToCart.styled';
interface AddToCartProps {
  addToCart: React.MouseEventHandler<HTMLButtonElement>;
  setAmount(amount: number): void;
  addToShoppingCart?: string;
  amount: number;
  disabled?: boolean;
}
export const AddToCart: React.FC<AddToCartProps> = ({
  addToCart,
  addToShoppingCart = '',
  setAmount,
  amount = 1,
  disabled,
}) => {
  return (
    <AddToCartStyled data-test-id="AddToCart">
      <AmountInput setAmount={setAmount} amount={amount} disabled={disabled} />

      <AddToCartButton onClick={addToCart} disabled={disabled || amount < 1}>
        {addToShoppingCart}
        {' '}
        <ShoppingList
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
      </AddToCartButton>
    </AddToCartStyled>
  );
};
