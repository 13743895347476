import dynamic from 'next/dynamic';

import { createCategoryUrl } from '@hultafors/hultafors/helpers/routing';
import { ImageFragment } from '@hultafors/hultafors/types';

import {
  CategoryPlugPicture,
  CategoryPlugStyled,
  CategoryPlugText,
} from './CategoryPlug.styled';

const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

export interface CategoryPlugProps {
  image?: ImageFragment;
  title: string;
  slug?: string;
}
export const CategoryPlug: React.FC<CategoryPlugProps> = ({
  image,
  title,
  slug,
}) => (
  <CategoryPlugStyled href={createCategoryUrl(slug || '') || ''}>
    <CategoryPlugText>{title}</CategoryPlugText>
    <CategoryPlugPicture>
      {image?.responsiveImage && <AspectRatioImage desktopImage={image} />}
    </CategoryPlugPicture>
  </CategoryPlugStyled>
);
