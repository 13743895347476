import { H1Styled } from './H1.styled';

interface H1Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H1: React.FC<H1Props> = ({ children, className, center }) => {
  return (
    <H1Styled $center={center} className={className}>
      {children}
    </H1Styled>
  );
};
