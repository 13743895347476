import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import {
  ButtonContainer,
  StyledButtonLink,
  StyledHeader,
  StyledParagraph,
} from './TwoColumnText.styled';

interface TwoColumnTextProps {
  firstColumnText?: string | null;
  secondColumnText?: string | null;
  header?: string | null;
  CTAText?: string | null;
  CTALink?: string | null;
}

export const TwoColumnText: React.FC<TwoColumnTextProps> = (props) => {
  return (
    <>
      <Grid
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[{ breakpoint: 'mobileMax', start: 2, columns: 5 }]}
        >
          <StyledHeader>{props.header}</StyledHeader>
        </GridChild>
      </Grid>
      <Grid
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}
      >
        <GridChild
          columnSpan={[{ breakpoint: 'mobileMax', start: 2, columns: 5 }]}
        >
          <StyledParagraph className="BottomSpacing">
            {props.firstColumnText}
          </StyledParagraph>
        </GridChild>
        <GridChild
          columnSpan={[{ breakpoint: 'mobileMax', start: 7, columns: 5 }]}
        >
          <StyledParagraph className="Second">
            {props.secondColumnText}
          </StyledParagraph>
        </GridChild>
      </Grid>
      <ButtonContainer>
        <StyledButtonLink className="Black" to={props.CTALink ?? ''}>
          {props.CTAText}
        </StyledButtonLink>
      </ButtonContainer>
    </>
  );
};
