import {
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import {
  DropdownLabel,
  DropdownList,
  DropdownListItem,
  DropdownStyled,
} from './Dropdown.styled';

export interface DropdownProps {
  label: string;
  listItems: string[];
  onSelectItem(...args: any[]): unknown;
  reset?: boolean;
  selectedIndex?: number;
  className?: string;
  dark?: boolean;
  disabled?: boolean;
}

export const Dropdown: React.FC<DropdownProps> = ({
  listItems = [],
  selectedIndex,
  reset,
  dark,
  disabled,
  onSelectItem,
  label,
  className,
}) => {
  const uid = useId();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedListItem, setSelectedListItem] = useState<string>('');
  const [focusedListItem, setFocusedListItem] = useState<number>(-1);
  const dropdownList = useRef<HTMLUListElement>(null);

  const toggleListVisibilityClick = () => {
    setIsOpen(!isOpen);
  };

  const closeList = () => {
    setIsOpen(false);
  };

  const toggleListVisibility = (event: KeyboardEvent) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        setIsOpen(!isOpen);
        break;
      case 'Escape':
        closeList();
        break;
      case 'ArrowDown':
        if (isOpen) {
          focusNextListItem('ArrowDown');
        }
        break;
      case 'UpArrow':
        if (isOpen) {
          focusNextListItem('ArrowUp');
        }
        break;
      default:
        break;
    }
  };

  const selectListItemOnKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    switch (event.key) {
      case 'Enter':
        setSelectedListItemFromEvent(event);
        closeList();
        return;
      case 'ArrowDown':
        focusNextListItem('ArrowDown');
        return;
      case 'ArrowUp':
        focusNextListItem('ArrowUp');
        return;
      case 'Escape':
        closeList();
        return;

      default:
        return;
    }
  };

  const selectListItemOnClick = (event: MouseEvent<HTMLLIElement>) => {
    setSelectedListItemFromEvent(event);
  };

  const setSelectedListItemFromEvent = (
    event: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>,
  ) => {
    setSelectedListItem(event.currentTarget?.innerText);
    onSelectItem(event.currentTarget.dataset['value']);
    closeList();
  };

  const focusNextListItem = (direction: 'ArrowDown' | 'ArrowUp') => {
    let activeListItem = focusedListItem;
    if (direction === 'ArrowDown') {
      if (activeListItem === null) {
        activeListItem = 0;
      } else {
        const currentActiveElementIsNotLastItem
          = focusedListItem < listItems.length - 1;

        if (currentActiveElementIsNotLastItem) {
          activeListItem += 1;
        }
      }
    } else if (direction === 'ArrowUp') {
      if (activeListItem === null) {
        activeListItem = listItems.length - 1;
      } else {
        const currentActiveElementIsNotFirstItem = focusedListItem > 0;

        if (currentActiveElementIsNotFirstItem) {
          activeListItem -= 1;
        }
      }
    }
    setFocusedListItem(activeListItem);
    // TODO: Fix dropdown item focus
    // this.dropdownList.current?.querySelector('.focus')?.focus()
  };

  useEffect(() => {
    if (reset) {
      setSelectedListItem('');
    } else {
      if (
        typeof selectedIndex === 'number'
        && selectedIndex >= 0
        && !!listItems?.[selectedIndex]
      ) {
        setSelectedListItem(listItems[selectedIndex] ?? '');
      }
    }
  }, [reset]);

  useEffect(() => {
    if (typeof selectedIndex === 'number' && !!listItems?.[selectedIndex]) {
      setSelectedListItem(listItems[selectedIndex] ?? '');
    }
  }, [selectedIndex]);

  const itemMapper = (item: string, index: number) => {
    const key = `Option-${uid}-${index}`;
    return (
      <DropdownListItem
        $focus={index === focusedListItem}
        key={key}
        id={key}
        data-value={index}
        onClick={selectListItemOnClick}
        onKeyDown={selectListItemOnKeyDown}
        role="none"
      >
        {item}
      </DropdownListItem>
    );
  };

  return (
    <DropdownStyled $dark={dark} $disabled={disabled} className={className}>
      <DropdownLabel
        id="dropdown__selected"
        $isOpen={isOpen}
        tabIndex={0}
        onClick={toggleListVisibilityClick}
        onKeyDown={toggleListVisibility}
        $dark={dark}
        aria-label={label}
      >
        {selectedListItem ? selectedListItem : label}
      </DropdownLabel>
      <DropdownList $isOpen={isOpen} ref={dropdownList}>
        {listItems?.map(itemMapper)}
      </DropdownList>
    </DropdownStyled>
  );
};
