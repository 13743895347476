import { SearchResultHeroStyled } from './SearchResultHero.styled';

interface SearchResultHeroProps {
  children?: React.ReactNode;
  menuHeight?: number;
}
export const SearchResultHero: React.FC<SearchResultHeroProps> = ({
  children,
  menuHeight,
}) => (
  <SearchResultHeroStyled $menuHeight={menuHeight}>
    {children}
  </SearchResultHeroStyled>
);
