import Image from 'next/image';

import { ProductDetailsApiImage } from '@hultafors/shared/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { ImageSlider } from '../ImageSlider/ImageSlider';

import {
  EnvironmentImage,
  EnvironmentImagesStyled,
  ImageWrapper,
} from './EnvironmentImages.styled';

interface EnvironmentImagesProps {
  images: ProductDetailsApiImage[];
}

export const EnvironmentImages: React.FC<EnvironmentImagesProps> = ({
  images = [],
}) => {
  return (
    <EnvironmentImagesStyled $single={images.length === 1}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <EnvironmentImage>
            <ImageWrapper $aspectRatio="3 / 2">
              {images.length === 1
                ? (
                  <Image
                    src={images[0]?.url || ''}
                    alt={images[0]?.placeholder || ''}
                    fill
                    sizes="100vw, (min-width: 1440px) 1440px"
                  />
                  )
                : (
                  <ImageSlider
                    images={images}
                    width={300}
                    height={200}
                    article
                    environment
                  />
                  )}
            </ImageWrapper>
          </EnvironmentImage>
        </GridChild>
      </Grid>
    </EnvironmentImagesStyled>
  );
};
