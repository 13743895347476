import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const PageStyled = styled.main`
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  flex: 1;

  .ExploreArea + .CategoryLink {
    margin-block: 0;
    padding-block-end: ${spacing.block};
    background: ${colors.gray4};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-block-end: ${spacing.xlarge};
    }
  }
`;
