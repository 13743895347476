import Link from 'next/link';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const FavoriteCardStyled = styled.article`
  position: relative;
`;

export const ToggleContainer = styled.div`
  position: absolute;
  right: -${spacing.small};
  top: -${spacing.small};
  z-index: 1;
  padding: ${spacing.xsmall};
`;

export const ToggleButton = styled.button`
  appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  display: block;
  width: ${spacing.small};
  height: ${spacing.small};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  border: thin solid ${colors.border};
  aspect-ratio: 1 / 1;
  padding: ${spacing.xsmall};
  position: relative;
`;

export const ProductLink = styled(Link)`
  display: block;
  text-decoration: none;
`;

export const ProductName = styled.p`
  margin: 0;
  font-size: ${fontSizes.xsmall};
  letter-spacing: 0.5px;
  line-height: 1.285rem;
  font-weight: ${fontWeights.normal};
  color: ${colors.black};
  transition: 0.2s ease;
  ${ProductLink}:hover & {
    color: ${colors.gray1};
  }
`;
