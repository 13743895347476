import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

export const EnvironmentImagesStyled = styled.section<{ $single?: boolean }>`
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: ${spacing.large};
  }

  ${({ $single }) =>
    !$single
    && css`
      margin-bottom: ${spacing.large};
      padding: 0 20px;
    `}
`;

export const EnvironmentImage = styled.div`
  position: relative;
`;

interface ImageWrapperProps {
  $aspectRatio?: CSSProperties['aspectRatio'];
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  aspect-ratio: ${({ $aspectRatio = '3 / 2' }) => $aspectRatio};

  img {
    object-fit: cover;
  }
`;
