import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Icon } from '../Icon/Icon';
import { Label } from '../Label/Label';

import {
  InputContainer,
  StyledSelect,
  StyledSelectContainer,
} from './Select.styled';
interface SelectProps {
  children?: React.ReactNode;
  id: string;
  inline?: boolean;
  selected: string;
  label: string;
  options: string[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id = '',
  options = [],
  selected,
  onChange,
  disabled,
}) => (
  <FieldWrapper>
    <StyledSelectContainer>
      <Label htmlFor={id} label={label} />
      <InputContainer>
        <StyledSelect
          id={id}
          value={selected}
          onChange={onChange}
          disabled={disabled}
        >
          {children
            ? children // If children are provided, they will take precedence over the "options" prop
            : options.map((option) => (
              <option
                id={option}
                value={option}
                key={`select.${id}.${option}`}
              >
                {option}
              </option>
            ))}
        </StyledSelect>
        {' '}
        <Icon svg="chevron" width={16} height={16} />
      </InputContainer>
    </StyledSelectContainer>
  </FieldWrapper>
);
