import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const ContactInformationStyled = styled.div`
  padding: 0 ${spacing.small};
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.large};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: 72px;
  }

  .BodyText {
    margin-block-start: ${spacing.small};

    > :first-child {
      margin-block-start: 0;
    }

    > :nth-child(6) {
      margin-block: ${spacing.block} ${spacing.small};
    }
  }

  a,
  span,
  p {
    font-size: ${fontSizes.small};
    font-style: normal;
    font-weight: ${fontWeights.normal};
    line-height: ${lineHeights.h4};
    margin-block-end: 12px;
    white-space: pre-wrap;
    color: ${colors.black};

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
    }
  }
`;

export const ContactValue = styled.dd`
  margin: 0;

  > p {
    margin: 0;
  }
`;

export const ContactKey = styled.dt`
  ${ContactValue} + & {
    margin-block-start: ${spacing.block};
  }

  font-size: ${fontSizes.h4};
  font-weight: ${fontWeights.medium};
`;
