import { Accordion } from '../Accordion/Accordion';
import { AccordionItem } from '../AccordionItem/AccordionItem';
import { Paragraph } from '../Paragraph/Paragraph';

interface AdditionalInformationAccordionProps {
  label?: string;
  information?: string;
}

export const AdditionalInformationAccordion: React.FC<
  AdditionalInformationAccordionProps
> = ({ label = 'Additional information', information }) => {
  return (
    <Accordion $noPadding>
      <AccordionItem $label={label} $forceCollapsible>
        <div className="AdditionalInformation">
          <Paragraph small>{information}</Paragraph>
        </div>
      </AccordionItem>
    </Accordion>
  );
};
