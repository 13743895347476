import { createGlobalStyle, css } from 'styled-components';

const cssVariables = css`
  :root {
    --header-height: 56px;
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
  }
`;

const globalStyle = css`
  img {
    max-width: 100%;
  }

  .Map {
    width: 100%;
    position: relative;
  }

  .IE-Modal-Blur {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.9);
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .IE-Modal-Contents {
    width: 90%;
    max-width: 500px;
    text-align: center;
    color: #000000;
    background-color: #ffffff;
    padding: 24px;
    margin: 0 auto;
    transform: translateY(50%);
  }

  .IE-Modal-Contents img {
    height: 32px;
    margin-bottom: 32px;
  }

  body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 100;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(255, 255, 255, 1);
    overflow-x: hidden;
    padding-top: var(--header-height);
  }
  body * {
    box-sizing: border-box;
  }

  #__next {
    height: 100%;
    min-height: calc(100vh - var(--header-height));
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .no-js img.lazyload {
    display: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
