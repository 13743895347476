import { H2Styled } from './H2.styled';

interface H2Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H2: React.FC<H2Props> = ({ children, className, center }) => {
  return (
    <H2Styled $center={center} className={className}>
      {children}
    </H2Styled>
  );
};
