const pageRecords = {
  storyPage: { typename: 'StoryRecord', pageName: 'stories' },
  articlePage: { typename: 'ArticlePageRecord', pageName: 'articles' },
  newsPage: { typename: 'NewsPageRecord', pageName: 'news' },
  iconPage: { typename: 'IconPageRecord', pageName: 'icons' },
};

export const getParentPage = (typename: string) => {
  switch (typename) {
    case pageRecords.storyPage.typename:
      return pageRecords.storyPage.pageName;

    case pageRecords.articlePage.typename:
      return pageRecords.articlePage.pageName;

    case pageRecords.newsPage.typename:
      return pageRecords.newsPage.pageName;

    case pageRecords.iconPage.typename:
      return pageRecords.iconPage.pageName;

    default:
      return pageRecords.articlePage.pageName;
  }
};
