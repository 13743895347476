import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const StyledToggleSwitchContainer = styled.label`
  display: flex;
  align-items: center;
`;

export const StyledToggleSwitch = styled.span`
  position: relative;
  display: inline-block;
  min-width: 48px;
  height: 32px;
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 16px;
  background-color: ${colors.toggleGray};
  transition: 0.2s;

  ::before {
    border-radius: 50%;
    position: absolute;
    content: '';
    block-size: 26px;
    inline-size: 26px;
    inset-inline-start: 3px;
    inset-block-end: 3px;
    background-color: ${colors.white};
    transition: 0.2s;
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
  }

  ${StyledInput}:checked + & {
    background-color: ${colors.green};
  }

  ${StyledInput}:checked + &:before {
    transform: translateX(17px);
    box-shadow: none;
  }

  ${StyledInput}:disabled +& {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledToggleSwitchLabel = styled.span<{ $disabled?: boolean }>`
  margin-left: ${spacing.small};

  ${({ $disabled }) =>
    $disabled
    && css`
      opacity: 0.5;
    `}
`;
