import { useGlobal } from '@hultafors/hultafors/hooks';
import { IconListBlockFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { IconCard } from '../IconCard/IconCard';
import { Section } from '../Section/Section';

export const IconListBlock: React.FC<IconListBlockFragment> = ({ icons }) => {
  const { globalContent } = useGlobal();

  return (
    <Section>
      <Grid
        columnGap={[{ columnGap: 20 }]}
        columns={[
          { columns: 1 },
          { breakpoint: 'largeMobile', columns: 2 },
          { breakpoint: 'desktop', columns: 3 },
        ]}
      >
        {icons.map((item, i) => (
          <IconCard
            key={i}
            title={item.title}
            description={item.description}
            readMoreLabel={globalContent?.readMoreLabel}
            desktopImage={item.desktopImage}
            slug={item.slug}
          />
        ))}
      </Grid>
    </Section>
  );
};
