import { useRef } from 'react';

import { useGlobal } from '@hultafors/hultafors/hooks';

import { Icon, IconSvg } from '../Icon/Icon';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';

import {
  ExternalLink,
  ExternalLinksContainer,
  LanguageContainer,
  SlideInStyled,
} from './SlideIn.styled';

interface SlideInProps {
  isOpen?: boolean;
  toggle?: (e: any) => void;
  children?: JSX.Element[] | JSX.Element;
  headerText?: string;
  clearText?: string;
  clearAll?: () => void;
  headerIcon?: IconSvg;
  isMenu?: boolean;
  isCart?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  shopLinkUrl?: string;
  shopLinkLabel?: string;
  itemLength?: number;
  settings?: any;
  changeLanguageText?: string;
  headerMenuElement?: React.ReactNode;
  fromTop?: boolean;
  fromLeft?: boolean;
  small?: boolean;
  padContent?: boolean;
}

export const SlideIn: React.FC<SlideInProps> = ({
  isOpen,
  toggle,
  children,
  headerText,
  clearText,
  clearAll = null,
  isCart = false,
  headerIcon,
  isMenu,
  partnerPortalUrl,
  partnerLabel = 'Partner',
  shopLinkUrl,
  shopLinkLabel,
  itemLength,
  settings,
  changeLanguageText,
  headerMenuElement,
  fromTop = false,
  fromLeft = false,
  small = false,
  padContent = false,
}) => {
  const node = useRef(null);
  const { globalContent } = useGlobal();
  return (
    <SlideInStyled
      $fromTop={fromTop}
      $fromLeft={fromLeft}
      $small={small}
      $padContent={padContent}
    >
      <div className={`SlideInBackground ${isOpen ? 'active' : ''}`} ref={node}>
        <div className={`SlideInInside ${isOpen ? 'active' : ''}`}>
          <div className="SlideInHeader">
            <div className={isMenu ? 'HeaderText isMenu' : 'HeaderText'}>
              {isMenu
                ? (
                  <>
                    <button
                      className="HeaderIcon CloseBtn"
                      onClick={toggle}
                      aria-label={globalContent.closeLabel || ''}
                    >
                      <Icon svg="close" />
                    </button>
                    <LanguageContainer>
                      <LanguageSelector
                        chooseLanguageLabel={changeLanguageText}
                      />
                    </LanguageContainer>
                    <ExternalLinksContainer>
                      {partnerPortalUrl && (
                        <div>
                          <ExternalLink
                            href={partnerPortalUrl || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {partnerLabel}
                            <Icon svg="partnerLogin" width={23} height={24} />
                          </ExternalLink>
                        </div>
                      )}
                      {shopLinkUrl && shopLinkLabel && (
                        <div>
                          <ExternalLink
                            href={shopLinkUrl || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {shopLinkLabel}
                            <Icon svg="cart" />
                          </ExternalLink>
                        </div>
                      )}
                    </ExternalLinksContainer>
                  </>
                  )
                : (
                  <>
                    {headerIcon && (
                      <Icon svg={headerIcon} className="HeaderIcon" />
                    )}
                    <span>{headerText}</span>
                  </>
                  )}
            </div>
            <div>{headerMenuElement}</div>

            <div className="SlideInExit">
              {clearAll
              && clearText
              && typeof itemLength !== 'undefined'
              && itemLength > 0 && (
                <button onClick={clearAll} className="ClearBtn">
                  <span>{clearText}</span>
                  <Icon svg="trash" />
                </button>
              )}

              {isMenu
                ? (
                  <span />
                  )
                : (
                  <button
                    className="CloseBtn"
                    aria-label="Close"
                    onClick={(e) => toggle?.('')}
                  >
                    <Icon svg="close" />
                  </button>
                  )}
            </div>
          </div>

          <div className={isCart ? 'SlideInBody isCart' : 'SlideInBody'}>
            {children}
          </div>
        </div>
      </div>
    </SlideInStyled>
  );
};
