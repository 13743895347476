import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const BuyingOptionsListStyled = styled.div`
  a {
    display: flex;
    justify-content: space-between;
    padding: ${spacing.small} 0;
    text-decoration: none;
    border-block-end: thin solid ${colors.gray4};
    font-size: ${fontSizes.body};
    position: relative;
    color: inherit;

    &:visited {
      color: ${colors.black};
    }

    &:hover {
      color: ${colors.gray1};
    }
  }

  .internal {
    a {
      &:hover {
        transition: none;
        border-block-end: thin solid ${colors.gray4};
      }

      &:focus {
        box-shadow: inherit;
        outline: inherit;
        border: inherit;
        border-block-end: thin solid ${colors.gray4};
      }
    }
  }
`;
