import { useState } from 'react';

import { slugify } from '@hultafors/shared/helpers';
import { FavoriteItem, ProductModelData } from '@hultafors/shared/types';

import { createDetailPageUrlFromDetails } from '@hultafors/hultafors/helpers/routing';
import { useCart, useGlobal } from '@hultafors/hultafors/hooks';
import {
  BuyingOption,
  CartItemData,
  CategoryPageRecord,
  HultaforsProductDetails,
  ProductDetailPageFragment,
} from '@hultafors/hultafors/types';

import { AdditionalInformationAccordion } from '../AdditionalInformationAccordion/AdditionalInformationAccordion';
import { AddToCart } from '../AddToCart/AddToCart';
import { Button } from '../Button/Button';
import { BuyDialog } from '../BuyDialog/BuyDialog';
import { CartActions } from '../CartActions/CartActions';
import { DocumentsAccordion } from '../DocumentsAccordion/DocumentsAccordion';
import { FavoriteToggle } from '../FavoriteToggle/FavoriteToggle';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H1 } from '../H1/H1';
import { H5 } from '../H5/H5';
import { Icon } from '../Icon/Icon';
import { ModelSelector } from '../ModelSelector/ModelSelector';
import { Paragraph } from '../Paragraph/Paragraph';
import { Pictograms } from '../Pictograms/Pictograms';
import { ProductDetailsImages } from '../ProductDetailsImages/ProductDetailsImages';
import { ProductInfo } from '../ProductInfo/ProductInfo';
import { ProductIntro } from '../ProductIntro/ProductIntro';
import { RouterLink } from '../RouterLink/RouterLink';
import { Section } from '../Section/Section';

interface ProductDetailViewProps {
  content: ProductDetailPageFragment;
  product: HultaforsProductDetails;
  category: CategoryPageRecord;
  categorySlug: string;
}

export const ProductDetailView: React.FC<ProductDetailViewProps> = ({
  content,
  product,
  category,
  categorySlug,
}) => {
  const { globalContent, menuData } = useGlobal();

  const [model, setModel] = useState<ProductModelData | undefined>(
    product.models?.find(({ isDefault = false }) => isDefault) || undefined,
  );
  const [isBuyingOptionsOpen, setIsBuyingOptionsOpen] = useState(false);

  const [isModelSelectorOpen, setIsModelSelectorOpen] = useState(false);
  const [amount, setAmount] = useState<number>(1);
  const productImages = model
    ? [
        ...product.images.filter((image) => image.sku === model.sku),
        ...product.images.filter((image) => image.sku !== model.sku),
      ]
    : product.images;

  const { addCartItem } = useCart();

  const constructUrl = () => {
    let productName = product.name;
    productName = productName.replace(/\//g, '');
    productName = productName.replace('&', '');
    productName = productName.toLocaleLowerCase();
    return (
      globalContent?.buyingShopUrl
      + slugify(productName)
      + (product.sku && product.sku
        ? '-' + slugify(product.sku.toLowerCase())
        : '')
    );
  };

  const [buyingOptionsItems] = useState<BuyingOption[]>([
    {
      name: globalContent?.findRetailerLabel || '',
      url: content?.findRetailerUrl || '',
    },
    {
      name: globalContent?.buyingShopLabel || '',
      url: (product.isBuyable && constructUrl()) || '',
    },
  ]);
  const toggleModelSelector = () => {
    setIsModelSelectorOpen(!isModelSelectorOpen);
  };

  const selectModelHelper = (input: ProductModelData) => {
    setModel(input);
    toggleModelSelector();
  };

  const toggleBuyingOptions = () => {
    setIsBuyingOptionsOpen(!isBuyingOptionsOpen);
  };

  const addToCart = () => {
    if (model) {
      const cartItem: CartItemData = {
        name: product.name,
        description: model.description2 || model.description || '',
        sku: model.sku,
        url: createDetailPageUrlFromDetails(product, categorySlug),
        amount,
        image: productImages?.[0]?.url || '',
      };
      addCartItem(cartItem);
    }
  };

  const favoriteItem: FavoriteItem = {
    id: product.id,
    name: product.name,
    url: createDetailPageUrlFromDetails(product, categorySlug),
    image: productImages?.[0]?.url || '',
  };
  return (
    <Section noMarginTop>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 6 },
            { breakpoint: 'desktop', columns: 7 },
          ]}
        >
          <ProductDetailsImages
            images={productImages}
            alt={product.name || ''}
            viewAll={content.viewAll || ''}
          />
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 6, start: 7 },
            { breakpoint: 'desktop', columns: 5, start: 8 },
            { breakpoint: 'desktopMedium', columns: 4, start: 9 },
          ]}
        >
          <ProductInfo>
            <div className="ProductInfoInner">
              <div className="ProductName">
                <div className="Flags">
                  {product.isNew && (
                    <p className="NewFlag">{globalContent.newLabel}</p>
                  )}
                  {product.premiumLabel && (
                    <p className="PremiumFlag">{product.premiumLabel}</p>
                  )}
                  {product.sustainableLabel && (
                    <p className="SustainableFlag">
                      {product.sustainableLabel}
                    </p>
                  )}
                </div>
                <div className="NameAndFavorite">
                  <H1>{product.name}</H1>
                  <FavoriteToggle product={favoriteItem} />
                </div>
              </div>
              {product.intro && (
                <div className="ProductIntro Desktop">
                  <ProductIntro
                    text={product.intro}
                    readMoreText={content.readMore || ''}
                  />
                </div>
              )}

              <CartActions>
                <ModelSelector
                  onClick={toggleModelSelector}
                  toggle={toggleModelSelector}
                  isOpen={isModelSelectorOpen}
                  headerText={
                    product.models?.length
                      ? content.models || ''
                      : content.model || ''
                  }
                  models={product.models || []}
                  model={model}
                  selectModel={selectModelHelper}
                  skuLabel={globalContent.skuLabel || ''}
                  modelsLabel={globalContent.modelsLabel || ''}
                  chooseText={globalContent.chooseModelLabel || ''}
                />
                {globalContent.buyingOptionsBtn
                  ? (
                    <Button
                      fullWidth
                      className="Black Black-hover BuyingOptions"
                      onClick={toggleBuyingOptions}
                    >
                      {globalContent.buyingOptionsBtn}
                    </Button>
                    )
                  : (
                    <AddToCart
                      disabled={!model}
                      setAmount={setAmount}
                      amount={amount}
                      addToCart={addToCart}
                      addToShoppingCart={content.addToShoppingCart || ''}
                    />
                    )}
                {isBuyingOptionsOpen && (
                  <BuyDialog
                    toggle={toggleBuyingOptions}
                    items={buyingOptionsItems}
                  />
                )}
                {/* Only display findRetailer if buyingOptions is not available, as buyingOptions also includes find retailers */}
                {!globalContent.buyingOptionsBtn && buyingOptionsItems && (
                  <RouterLink className="RetailerLink" to="/find-retailers">
                    {content.findRetailer}
                    <Icon svg="placeTag" width={16} />
                  </RouterLink>
                )}
              </CartActions>

              {product.intro && (
                <div className="ProductIntro Mobile">
                  <ProductIntro
                    text={product.intro}
                    readMoreText={content.readMore || ''}
                  />
                </div>
              )}
              <Pictograms items={product.pictograms} />
              {product.features && product.features.length > 0 && (
                <div className="Features">
                  <H5>{content.features}</H5>
                  {product.features.map((item, index) => (
                    <Paragraph
                      small
                      className="Feature"
                      key={`Feature-${index}`}
                    >
                      <span>{item.value}</span>
                    </Paragraph>
                  ))}
                </div>
              )}
              <DocumentsAccordion
                product={product}
                label={content?.documents || ''}
              />
              {product.material_info?.value && (
                <AdditionalInformationAccordion
                  information={product.material_info.value}
                />
              )}
            </div>
          </ProductInfo>
        </GridChild>
      </Grid>
    </Section>
  );
};
