import { useMemo } from 'react';

import { useGlobal } from '@hultafors/hultafors/hooks';

import { Icon } from '../Icon/Icon';

import {
  AmountInputInput,
  AmountInputStyled,
  ButtonDown,
  ButtonUp,
} from './AmountInput.styled';

export interface AmountInputProps {
  setAmount(amount: number): void;
  amount: number;
  disabled?: boolean;
  label?: string;
}

export const AmountInput: React.FC<AmountInputProps> = ({
  amount,
  setAmount,
  disabled,
  label,
}) => {
  const { globalContent } = useGlobal();
  const value: string = useMemo(() => {
    return `${Math.max(amount, 1)}`;
  }, [amount]);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setAmount(parseInt(event.target.value, 10));
  };

  const onClickDown: React.MouseEventHandler<HTMLButtonElement> = () => {
    setAmount(Math.max(1, amount - 1));
  };

  const onClickUp: React.MouseEventHandler<HTMLButtonElement> = () => {
    setAmount(amount + 1);
  };

  return (
    <AmountInputStyled data-test-id="AmountInput">
      <AmountInputInput
        type="text"
        onChange={onChange}
        value={value}
        pattern="[0-9]*"
        inputMode="numeric"
        disabled={disabled}
        min={1}
        aria-label={label ?? globalContent.quantity ?? 'Quantity'}
      />

      <ButtonUp
        onClick={onClickUp}
        disabled={disabled}
        aria-label={globalContent.increaseAmountLabel ?? 'Increase'}
      >
        <Icon svg="chevron" width={14} />
      </ButtonUp>
      <ButtonDown
        onClick={onClickDown}
        disabled={disabled || amount < 2}
        aria-label={globalContent.decreaseAmountLabel ?? 'Decrease'}
      >
        <Icon svg="chevron" width={14} />
      </ButtonDown>
    </AmountInputStyled>
  );
};
