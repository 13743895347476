import { useEffect, useState } from 'react';

import { FavoriteItem } from '@hultafors/shared/types';

import { useFavorites } from '@hultafors/hultafors/hooks';

import { FavoriteCard } from '../FavoriteCard/FavoriteCard';
import { SlideInNew } from '../SlideInNew/SlideInNew';

import { FavoritesStyled } from './Favorites.styled';

interface FavoritesProps {
  isOpen?: boolean;
  toggleMenu(key?: string): void;
  header: string;
  clearText: string;
  badgeText: string;
  modelText: string;
  isSmall?: boolean;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header,
  clearText,
}) => {
  const [headerText, setHeaderText] = useState(header || '');
  const { favorites, clearFavorites } = useFavorites();
  const callback = () => toggleMenu('');

  useEffect(() => {
    setHeaderText(`${header} (${favorites.length})`);
  }, []);

  const favoritesMapper = (item: FavoriteItem) => (
    <FavoriteCard
      item={item}
      key={`FavoriteCard-${item.id}`}
      callback={callback}
    />
  );

  return (
    <SlideInNew
      toggle={toggleMenu}
      isOpen={!!isOpen}
      headerText={headerText}
      headerIcon="favorite"
      clearText={clearText}
      clearAll={clearFavorites}
      padContent
      fromLeft={false}
      itemLength={favorites.length}
    >
      <FavoritesStyled>{favorites.map(favoritesMapper)}</FavoritesStyled>
    </SlideInNew>
  );
};
