import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultafors/tokens';

interface H3StyledProps {
  $center?: boolean;
}

export const H3Styled = styled.h3<H3StyledProps>`
  font-size: ${fontSizes.h4};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.h4};
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
  margin: 0;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    font-size: ${fontSizes.h3};
    line-height: ${lineHeights.h3};
  }
`;
