import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';

import {
  ClearButton,
  ResultText,
  SearchButton,
  SearchInputChildren,
  SearchInputForm,
  SearchInputStyled,
} from './SearchInput.styled';

interface SearchInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.FormEventHandler;
  clear(): void;
  value: string;
  placeholder?: string;
  name?: string;
  iconUrl?: string;
  displaySearchMessage?: string;
  children?: React.ReactNode;
  clearLabel: string;
  searchLabel: string;
  className?: string;
  pattern?: string;
  disabled?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onSubmit,
  onChange,
  value,
  placeholder,
  clear,
  children,
  displaySearchMessage = '',
  name = 'SearchBox',
  clearLabel,
  searchLabel,
  className,
  pattern,
  disabled,
}) => {
  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === 'Escape') {
      clear();
    }
  };
  return (
    <SearchInputStyled className={className}>
      <SearchInputForm onSubmit={onSubmit} autoComplete="off">
        <Input
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          pattern={pattern}
          onKeyDown={onKeyDown}
        />
        {value && (
          <ClearButton type="button" aria-label={clearLabel} onClick={clear}>
            <Icon svg="clear" />
          </ClearButton>
        )}
        <SearchButton
          type="submit"
          disabled={disabled}
          aria-label={searchLabel || 'Search'}
        >
          <Icon svg="search" />
        </SearchButton>
      </SearchInputForm>
      {children && <SearchInputChildren>{children}</SearchInputChildren>}
      <ResultText>{displaySearchMessage}</ResultText>
    </SearchInputStyled>
  );
};
