import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const OnlineStoreListStyled = styled.div`
  a,
  span {
    display: flex;
    justify-content: space-between;
    padding: ${spacing.small} 0;
    text-decoration: none;
    border-block-end: thin solid ${colors.gray4};
    font-size: ${fontSizes.body};
    position: relative;
    color: ${colors.black};
  }
`;
