import styled from 'styled-components';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const LinkBlockStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.medium} calc(${spacing.regular} + 16px) ${spacing.medium} 0;

  a {
    font-size: ${fontSizes.small};
  }
`;
