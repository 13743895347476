import { H4Styled } from './H4.styled';

interface H4Props {
  $center?: boolean;
  $large?: boolean;
  $underline?: boolean;
  children?: React.ReactNode;
  className?: string;
}
export const H4: React.FC<H4Props> = ({
  $center: center = false,
  $large: large = false,
  $underline: underline = false,
  children,
  className,
}) => {
  return (
    <H4Styled
      $center={center}
      $underline={underline}
      $large={large}
      className={className}
    >
      {children}
    </H4Styled>
  );
};
