import Link from 'next/link';

import { Icon } from '../Icon/Icon';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  ArrowLinkDirection,
  ArrowLinkLink,
  ArrowLinkStyled,
  ArrowLinkText,
} from './ArrowLink.styled';

interface ArrowLinkProps {
  className?: string;
  label?: string;
  url?: string;
  isAnchor?: boolean;
  direction?: ArrowLinkDirection;
  white?: boolean;
}

export const ArrowLink: React.FC<ArrowLinkProps> = ({
  className,
  label = '',
  url = '',
  direction = 'down',
  isAnchor = true,
  white,
}) => {
  if (isAnchor) {
    return (
      <ArrowLinkStyled
        $direction={direction}
        $white={white}
        className={className}
      >
        <ArrowLinkLink as={Link} $direction={direction} href={url ?? ''}>
          {label}
          <Icon svg="arrowLink" />
        </ArrowLinkLink>
      </ArrowLinkStyled>
    );
  }

  return (
    <ArrowLinkStyled
      $direction={direction}
      $white={white}
      className={className}
    >
      <ArrowLinkText as={Paragraph} $direction={direction}>
        {label}
        <Icon svg="arrowLink" />
      </ArrowLinkText>
    </ArrowLinkStyled>
  );
};
