import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { H3 } from '../H3/H3';

interface ArticlePlugStyledProps {
  $image?: boolean;
  $darkText?: boolean;
}

export const ArticlePlugStyled = styled.div<ArticlePlugStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 100%;
  overflow: hidden;
  background: ${colors.white};
  aspect-ratio: 3 / 4;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    aspect-ratio: 1 / 1;
  }

  ${({ $image }) =>
    $image
    && css`
      &::after {
        content: '';
        display: flex;
        inline-size: 100%;
        block-size: 60%;
        background: linear-gradient(
          180deg,
          rgb(0 0 0 / 0%) 0%,
          rgb(0 0 0 / 70%) 97%
        );
        position: absolute;
        inset-block-end: 0;
      }
    `}

  &:hover {
    svg > g > path:nth-child(1) {
      d: path('M23.5 23H1V0.6');
    }

    svg > g > path:nth-child(3) {
      d: path('M1 1h22v22');
    }
  }
`;

export const ArrowLinkCustomStyle = styled(ArrowLink)`
  display: flex;
`;

export const ArticlePlugPicture = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    ${ArticlePlugStyled}:hover & {
      transform: scale(1.02);
    }
  }
`;

export const ArticlePlugContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${spacing.small};
  z-index: 1;
`;

export const PlugLabel = styled.p<ArticlePlugStyledProps>`
  color: ${({ $darkText, $image }) =>
    $darkText || !$image ? colors.black : colors.white};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  text-transform: uppercase;
  margin-bottom: ${spacing.xsmall};
  letter-spacing: 0.1875rem;
  ${({ $darkText, $image }) =>
    (!$darkText || $image)
    && css`
      text-shadow: 0 0 6px ${colors.black};
    `};
`;

export const PlugTitle = styled(H3)<ArticlePlugStyledProps>`
  color: ${({ $darkText, $image }) =>
    $darkText || !$image ? colors.black : colors.white};
  margin-bottom: ${spacing.small};

  ${({ $darkText, $image }) =>
    (!$darkText || $image)
    && css`
      text-shadow: 0 0 8px ${colors.black};
    `};
`;
