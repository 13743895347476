import styled from 'styled-components';

export const ContentAreaStyled = styled.div`
  a {
    color: inherit;
  }

  h1 {
    display: none;
  }

  h1:first-child {
    display: block;
  }
`;
