import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

interface BadgeStyledProps {
  $small?: boolean;
  $tiny?: boolean;
  $red?: boolean;
  $round?: boolean;
  $styleName?: string;
  $isNew?: boolean;
  $premium?: boolean;
  $sustainable?: boolean;
  $inline?: boolean;
}

export const BadgeStyled = styled.div<BadgeStyledProps>`
  position: ${({ $inline }) => ($inline ? 'relative' : 'absolute')};
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  font-size: ${fontSizes.supersmall};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.1rem;
  text-align: center;
  line-height: 18px;
  width: auto;
  height: 16px;
  text-transform: uppercase;
  margin-left: ${spacing.xsmall};
  margin-bottom: ${({ $inline }) => ($inline ? '12px' : '0')};

  ${({ $round, $red }) =>
    $round
    && css`
      margin: 0;
      padding: 0;
      border-radius: 27px;
      background-color: ${colors.white};
      border: thin solid;
      border-color: ${$red ? colors.redComplement : colors.black};
      font-weight: ${fontWeights.medium};
      color: ${$red ? colors.redComplement : colors.black};
      line-height: 40px;

      &::before {
        display: none;
      }
    `}

  ${({ $small }) =>
    $small
    && css`
      width: 32px;
      height: 32px;
      line-height: 32px;
    `}

  ${({ $tiny }) =>
    $tiny
    && css`
      width: 16px;
      height: 16px;
      line-height: 14px;
      letter-spacing: 0;
    `}

${({ $isNew }) =>
    $isNew
    && css`
      display: flex;
      align-items: center;
      padding: 0 5px;
      border: thin solid ${colors.black};
      letter-spacing: 0.05rem;
      color: ${colors.black};
    `}

  ${({ $premium }) =>
    $premium
    && css`
      display: flex;
      align-items: center;
      background: ${colors.black};
      color: ${colors.white};
      padding: 0 5px;
      letter-spacing: 0.05rem;
    `}

${({ $sustainable }) =>
    $sustainable
    && css`
      display: flex;
      align-items: center;
      background: ${colors.darkGreen};
      color: ${colors.white};
      padding: 0 5px;
      letter-spacing: 0.05rem;
    `}
`;
