import { ScreenReaderOnlyStyled } from './ScreenReaderOnly.styled';

interface ScreenReaderOnlyProps {
  children?: React.ReactNode;
  className?: string;
}
export const ScreenReaderOnly: React.FC<ScreenReaderOnlyProps> = ({
  children,
  className,
}) => (
  <ScreenReaderOnlyStyled className={className}>
    {children}
  </ScreenReaderOnlyStyled>
);
