import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const IntroTextBlockStyled = styled.div`
  margin: ${spacing.medium} 0;
  padding: 0 ${spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
  }

  h3 {
    font-size: ${fontSizes.h4};
    font-weight: ${fontWeights.normal};
    line-height: ${lineHeights.h4};
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.h3};
      line-height: ${lineHeights.h3};
    }
  }

  h4 {
    font-size: ${fontSizes.h4};
    line-height: ${lineHeights.h4};
    font-weight: ${fontWeights.normal};
    margin: 0;
  }

  div > h3,
  div > h4 {
    margin-block-start: ${spacing.block};
  }

  p + h2,
  h2 + .IntroBlock {
    margin-block-start: ${spacing.medium};
  }

  p {
    font-size: ${fontSizes.small};
    line-height: 24px;
    margin-block-end: 12px;
    white-space: pre-wrap;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.body};
      line-height: 26px;
    }

    strong {
      font-weight: ${fontWeights.medium};
    }
  }

  p:last-of-type {
    margin-block-end: 0;
  }

  .IntroBlock p {
    font-size: ${fontSizes.h4};
  }

  .IntroBlock + p,
  .IntroBlock + div {
    margin-block-start: ${spacing.medium};
  }

  .IntroBlock p:first-of-type {
    margin-block-start: 0;
  }

  h2 + .BodyText {
    margin-block-start: ${spacing.small};
  }
`;
