import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontWeights, spacing } from '@hultafors/hultafors/tokens';

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
  }
`;

export const StyledSelect = styled.select`
  background: white;
  appearance: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.small};
  border: thin solid ${colors.gray3};
  font-weight: ${fontWeights.normal};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }

  &:focus {
    outline: none;
  }

  option {
    padding: ${spacing.small} ${spacing.xxsmall};
    line-height: 1.4em !important;

    &:disabled {
      color: ${colors.gray2};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    inset-block-start: calc(50% - 8px);
    inset-inline-end: 17px;
    pointer-events: none;
  }

  ${StyledSelect}:disabled + svg {
    opacity: 0.33;
  }
`;
