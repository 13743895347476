import { CSSProperties } from 'styled-components';

import { GraphicStyled } from './Graphic.styled';

interface GraphicProps {
  width: CSSProperties['width'];
  height: CSSProperties['height'];

  image: string;
}
export const Graphic: React.FC<GraphicProps> = ({ width, height, image }) => (
  <GraphicStyled>
    <img src={image} alt="graphic" width={width} height={height} />
  </GraphicStyled>
);
