import Link from 'next/link';

import { Store } from '@hultafors/shared/types';

import { Icon } from '../Icon/Icon';

import { OnlineStoreListStyled } from './OnlineStoreList.styled';

interface OnlineStoreListProps {
  stores: Store[];
}
export const OnlineStoreList: React.FC<OnlineStoreListProps> = ({
  stores = [],
}) => {
  const storeListMapper = (store: Store, index: number) => {
    if (store.data.webSite) {
      return (
        <Link
          href={store.data.webSite || ''}
          target="_blank"
          rel="noopener noreferrer"
          key={`Online-Store-${index}`}
        >
          {store.data.companyName || store.data.webSite}
          {' '}
          <Icon svg="externalLink" width={16} />
        </Link>
      );
    }
    if (store.data.companyName) {
      return (
        <span key={`Online-Store-${index}`}>{store.data.companyName}</span>
      );
    }
    return null;
  };
  return (
    <OnlineStoreListStyled>{stores.map(storeListMapper)}</OnlineStoreListStyled>
  );
};
