import { GaugeStyled } from './Gauge.styled';
interface GaugeProps {
  soFar: number;
  toGo: number;
}
export const Gauge: React.FC<GaugeProps> = ({ soFar = 0, toGo = 100 }) => {
  return (
    <GaugeStyled className="Gauge">
      <div className="SoFar" style={{ width: soFar + '%' }} />
      <div className="ToGo" style={{ width: toGo + '%' }} />
    </GaugeStyled>
  );
};
