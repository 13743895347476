import { useGlobal } from '@hultafors/hultafors/hooks';

import { Icon } from '../Icon/Icon';

import { AlertBoxStyled, CloseIcon, ErrorIcon, Label } from './AlertBox.styled';
interface AlertBoxProps {
  text?: string;
  onClose: React.MouseEventHandler;
  children?: React.ReactNode;
}
export const AlertBox: React.FC<AlertBoxProps> = ({
  text,
  children,
  onClose,
}) => {
  const { globalContent } = useGlobal();
  return (
    <AlertBoxStyled data-test-id="AlertBox">
      <ErrorIcon>
        <Icon svg="error" width={16} />
      </ErrorIcon>
      <Label>{text}</Label>
      {children}
      <CloseIcon
        onClick={onClose}
        aria-label={globalContent.closeLabel ?? 'Close'}
      >
        <Icon svg="close" width={20} />
      </CloseIcon>
    </AlertBoxStyled>
  );
};
