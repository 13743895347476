import { CSSProperties } from 'styled-components';

import { RadioButtonStyled } from './RadioButton.styled';

interface RadioButtonProps {
  id: string;
  label: string;
  value: string;
  name: string;
  isSelected: boolean;
  changed: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color?: CSSProperties['color'];
}
export const RadioButton: React.FC<RadioButtonProps> = ({
  color,
  id,
  changed,
  value,
  name,
  isSelected,
  label,
}) => {
  return (
    <RadioButtonStyled $color={color}>
      <input
        id={id}
        onChange={changed}
        value={value}
        name={name}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </RadioButtonStyled>
  );
};
