import Link from 'next/link';

import { RouterLinkStyled } from './RouterLink.styled';

interface RouterLinkProps {
  dataValue?: string;
  to?: string;
  children?: React.ReactNode;
  name?: string;
  id?: string;
  className?: string;
  onClick?: () => void;
  external?: boolean;
  svgIcon?: any;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  to,
  children,
  className,
  id,
  onClick,
  ...props
}) => {
  const isExternal = (url?: string): boolean => {
    return !!url?.startsWith('http');
  };

  const externalProps = { rel: 'noreferrer noopener', target: '_blank' };

  return (
    <RouterLinkStyled className={className} data-value={dataValue}>
      <Link
        href={to || ''}
        id={id}
        onClick={onClick}
        {...(isExternal(to) ? externalProps : {})}
      >
        {children}
      </Link>
    </RouterLinkStyled>
  );
};
