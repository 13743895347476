import { BuyingOption } from '@hultafors/hultafors/types';

import { BuyingOptionsList } from '../BuyingOptionsList/BuyingOptionsList';
import { Icon } from '../Icon/Icon';

import { BackDrop, DialogContent, StyledDialog } from './BuyDialog.styled';

interface BuyDialogProps {
  toggle: () => void;
  items: BuyingOption[];
}
export const BuyDialog: React.FC<BuyDialogProps> = ({ toggle, items }) => {
  return (
    <div>
      <BackDrop onClick={toggle} />
      <StyledDialog onClose={toggle} closeButtonLabel={<Icon svg="close" />}>
        <DialogContent>
          <BuyingOptionsList items={items} toggle={toggle} />
        </DialogContent>
      </StyledDialog>
    </div>
  );
};
