import { CSSProperties, useMemo } from 'react';

import ArrowLink from '../../svg/arrowLink.svg';
import ArrowRight from '../../svg/arrowRight.svg';
import Cart from '../../svg/cart.svg';
import Chevron from '../../svg/chevron.svg';
import Choose from '../../svg/choose.svg';
import Clear from '../../svg/clear.svg';
import Close from '../../svg/close.svg';
import CloseOutlined from '../../svg/closeOutlined.svg';
import Edit from '../../svg/edit.svg';
import Error from '../../svg/error.svg';
import ExternalLink from '../../svg/externalLink.svg';
import Favorite from '../../svg/favorite.svg';
import File from '../../svg/file.svg';
import Filter from '../../svg/filter.svg';
import Language from '../../svg/language.svg';
import Logo from '../../svg/logo.svg';
import Mail from '../../svg/mail.svg';
import Menu from '../../svg/menu.svg';
import Next from '../../svg/next.svg';
import PartnerLogin from '../../svg/partnerLogin.svg';
import Phone from '../../svg/phone.svg';
import PlaceTag from '../../svg/placeTag.svg';
import Play from '../../svg/play.svg';
import PlayOutline from '../../svg/playOutline.svg';
import Plus from '../../svg/plus.svg';
import Scroll from '../../svg/scroll.svg';
import Search from '../../svg/search.svg';
import Shop from '../../svg/shop.svg';
import ShoppingList from '../../svg/shoppingList.svg';
import Trash from '../../svg/trash.svg';

export type IconSvg =
  | 'arrowLink'
  | 'arrowRight'
  | 'cart'
  | 'chevron'
  | 'choose'
  | 'clear'
  | 'close'
  | 'closeOutlined'
  | 'edit'
  | 'error'
  | 'externalLink'
  | 'favorite'
  | 'file'
  | 'filter'
  | 'language'
  | 'logo'
  | 'mail'
  | 'menu'
  | 'next'
  | 'partnerLogin'
  | 'phone'
  | 'placeTag'
  | 'play'
  | 'playOutline'
  | 'plus'
  | 'scroll'
  | 'search'
  | 'shop'
  | 'shoppingList'
  | 'trash';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  svg?: IconSvg;
  title?: string;
  rotation?: number;
}

export const Icon: React.FC<IconProps> = ({
  svg,
  width,
  height,
  style,
  rotation,
  ...rest
}) => {
  const SvgComponent = useMemo(() => {
    switch (svg) {
      case 'arrowLink':
        return ArrowLink;
      case 'arrowRight':
        return ArrowRight;
      case 'cart':
        return Cart;
      case 'chevron':
        return Chevron;
      case 'choose':
        return Choose;
      case 'clear':
        return Clear;
      case 'close':
        return Close;
      case 'closeOutlined':
        return CloseOutlined;
      case 'edit':
        return Edit;
      case 'error':
        return Error;
      case 'externalLink':
        return ExternalLink;
      case 'favorite':
        return Favorite;
      case 'file':
        return File;
      case 'filter':
        return Filter;
      case 'language':
        return Language;
      case 'logo':
        return Logo;
      case 'mail':
        return Mail;
      case 'menu':
        return Menu;
      case 'next':
        return Next;
      case 'partnerLogin':
        return PartnerLogin;
      case 'phone':
        return Phone;
      case 'placeTag':
        return PlaceTag;
      case 'play':
        return Play;
      case 'playOutline':
        return PlayOutline;
      case 'plus':
        return Plus;
      case 'scroll':
        return Scroll;
      case 'search':
        return Search;
      case 'shop':
        return Shop;
      case 'shoppingList':
        return ShoppingList;
      case 'trash':
        return Trash;
      default:
        return null;
    }
  }, [svg]);

  if (!SvgComponent) {
    return null;
  }

  const defaultSize = { width: 24, height: 24 };

  const iconStyle: CSSProperties = {
    ...style,
  };

  if (typeof rotation === 'number') {
    iconStyle.transform = `rotate(${rotation}deg)`;
  }

  return (
    <SvgComponent
      aria-hidden={true}
      focusable={false}
      width={width ?? defaultSize.width}
      height={height ?? width ?? defaultSize.height ?? defaultSize.width}
      {...rest}
    />
  );
};
