import { useEffect, useState } from 'react';

import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

import { getLanguages } from '@hultafors/shared/api';
import { Language } from '@hultafors/shared/types';

import { markets } from '@hultafors/hultafors/api';
import { useGlobal } from '@hultafors/hultafors/hooks';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Icon } from '../Icon/Icon';
import { Paragraph } from '../Paragraph/Paragraph';
import { RouterLink } from '../RouterLink/RouterLink';
import { Section } from '../Section/Section';
import { SubscribeArea } from '../SubscribeArea/SubscribeArea';

import {
  BottomSection,
  Brands,
  Content,
  FooterStyled,
  MiddleSection,
  SubscribeAreaWrapper,
} from './Footer.styled';

const LanguageSelector = dynamic(() =>
  import('../LanguageSelector/LanguageSelector').then(
    (mod) => mod.LanguageSelector,
  ),
);

export const Footer: React.FC = () => {
  const { settings, globalContent, footerData } = useGlobal();
  const [languages, setLanguages] = useState<Language[]>([]);

  const openDialog = () => {
    // TODO typescript, tried index.d.ts and typeRoots tsconfig.ts & .json
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  useEffect(() => {
    setLanguages(getLanguages(settings?.market, markets));
  }, [settings?.market]);
  if (!footerData || !globalContent) {
    return null;
  }
  return (
    <FooterStyled data-test-id="Footer">
      <Section>
        <Grid className="Grid">
          <GridChild>
            <div className="FooterHeader">
              <div className="LogoWrapper">
                <Icon svg="logo" height={32} width={100} />
              </div>
              <div className="FooterFunctions">
                {languages.length > 1 && (
                  <LanguageSelector
                    chooseLanguageLabel={
                      globalContent?.changeLanguageText || ''
                    }
                    dark
                  />
                )}
                {globalContent?.partnerPortalUrl && (
                  <Paragraph>
                    <Link
                      href={globalContent.partnerPortalUrl || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="PartnerLoginLink"
                    >
                      {globalContent?.partnerLoginLabel}
                      <Icon svg="partnerLogin" width={16} />
                    </Link>
                  </Paragraph>
                )}
              </div>
            </div>
            <MiddleSection>
              <Content>
                <Paragraph className="IntroText">
                  {footerData.hultaforsBrands.length > 0 && (
                    <>
                      <Markdown>{footerData.introText || ''}</Markdown>
                      {` ${footerData.togetherWith}: `}
                    </>
                  )}
                  <Brands>
                    {footerData.hultaforsBrands.map((item) => {
                      return (
                        <Link href={item.url || ''} key={`Company-${item.id}`}>
                          {item.title}
                        </Link>
                      );
                    })}
                  </Brands>
                </Paragraph>
                <Paragraph>
                  {/* This space is intentional */}
                  {footerData.contactInfo}
                  {' '}
                  <RouterLink
                    className="Underline"
                    to={footerData.contactUrl || ''}
                  >
                    {footerData.contactLinkLabel}
                  </RouterLink>
                </Paragraph>
              </Content>

              {footerData.userEmailFormUrl
              && footerData.subscribeInfoText
              && footerData.subscribeCtaButton && (
                <SubscribeAreaWrapper>
                  <SubscribeArea
                    subscribeInfoText={footerData.subscribeInfoText}
                    subscribeCtaButton={footerData.subscribeCtaButton}
                    userEmailFormUrl={footerData.userEmailFormUrl}
                    userEmailFormImage={
                        footerData.userEmailFormImage || undefined
                      }
                  />
                </SubscribeAreaWrapper>
              )}
            </MiddleSection>
            <BottomSection>
              <Paragraph>
                {footerData.menu
                && footerData.menu.map((item, index) => {
                  return (
                    <span key={`Company-${index}`}>
                      <RouterLink to={item.url || ''}>
                        {item.title}
                      </RouterLink>

                      {index < footerData.menu.length - 1 && <>{' | '}</>}
                    </span>
                  );
                })}
                {'\u00A0|\u00A0'}
                <button
                  className="cookieSettings"
                  onClick={(e) => {
                    e.preventDefault();
                    openDialog();
                  }}
                >
                  {footerData.cookieSettings}
                </button>
              </Paragraph>
              <div className="SocialMedia">
                {footerData.socialMediaMenu.map((item, index) =>
                  item.url
                    ? (
                      <Link
                        key={`SocialMedia-${index}`}
                        href={item.url || ''}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={item.icon?.url || ''}
                          alt="social media"
                          height={40}
                          width={40}
                        />
                      </Link>
                      )
                    : null,
                )}
              </div>
            </BottomSection>
          </GridChild>
        </Grid>
      </Section>
    </FooterStyled>
  );
};
