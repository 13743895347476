import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/hultafors/tokens';

export const CategoryPlugStyled = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.black};
  width: 100%;
  text-align: center;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  z-index: 0;
  text-decoration: none;
`;
export const CategoryPlugText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.white};
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: ${fontWeights.medium};
  line-height: 22px;
  text-transform: uppercase;
  text-shadow: 0 1px 8px ${colors.textshadow};
  z-index: 10;

  ${CategoryPlugStyled}:hover &:after {
    inline-size: 64px;
  }

  @media (hover: hover) {
    &::after {
      content: '';
      display: flex;
      inline-size: 21px;
      block-size: 2px;
      margin-block-start: ${spacing.xsmall};
      background: ${colors.white};
      box-shadow: 0 1px 8px ${colors.textshadow};
      transition: 0.2s linear;
    }
  }
`;

export const CategoryPlugPicture = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    ${CategoryPlugStyled}:hover & {
      transform: scale(1.02);
    }
  }
`;
