import Head from 'next/head';
import { useRouter } from 'next/router';

import { createMeta, MetaData, SeoField } from '@hultafors/shared/helpers';
import { Language } from '@hultafors/shared/types';

import { SeoFragment } from '@hultafors/hultafors/types';

import { PageStyled } from './Page.styled';

interface PageProps {
  seo?: SeoFragment | null;
  metadata?: MetaData;
  children?: React.ReactNode;
  testId?: string;
  hrefLanguages?: Language[];
  canonical?: string;
}

export const Page: React.FC<PageProps> = ({
  metadata: metaDataInput,
  children,
  testId,
  hrefLanguages,
  seo,
  canonical,
}) => {
  const { asPath, locale } = useRouter();
  const url = asPath.split('?')[0];

  const metadata: MetaData
    = metaDataInput ?? createMeta(seo ? (seo as SeoField) : undefined, locale);

  function languagesMapper({
    lang,
    urlPrefix,
    hostname: marketHostname,
  }: Language) {
    const hrefLang = lang.toLowerCase();
    let path = url;
    if (urlPrefix) {
      path = `/${urlPrefix}${url}`;
    }
    const href = `https://${marketHostname}${path}`;
    if (hrefLang === 'en') {
      return (
        <link rel="alternate" hrefLang="x-default" href={href} key={href} />
      );
    }
    return <link rel="alternate" hrefLang={hrefLang} href={href} key={href} />;
  }

  return (
    <PageStyled data-test-id={testId}>
      <Head>
        {canonical && (
          <link rel="canonical" href={canonical || ''} key="canonical" />
        )}
        {metadata?.title && (
          <>
            <title>{metadata.title}</title>
            <meta property="og:title" content={metadata.title} />
            <meta name="twitter:title" content={metadata.title} />
          </>
        )}
        {metadata?.description && (
          <>
            <meta name="description" content={metadata.description} />
            <meta property="og:description" content={metadata.description} />
            <meta name="twitter:description" content={metadata.description} />
          </>
        )}
        {metadata?.type && <meta property="og:type" content={metadata.type} />}
        {metadata?.image?.url && (
          <>
            <meta property="og:image" content={metadata.image.url} />
            <meta property="twitter:image" content={metadata.image.url} />
          </>
        )}
        {metadata?.image?.alt && (
          <meta property="twitter:image:alt" content={metadata.image.alt} />
        )}
        <meta name="twitter:card" content="summary" />
        {hrefLanguages?.map(languagesMapper)}
      </Head>
      {children}
    </PageStyled>
  );
};
