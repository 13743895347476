import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { H3 } from '../H3/H3';
import { Paragraph } from '../Paragraph/Paragraph';

export const StyledParagraph = styled(Paragraph)`
  font-size: ${fontSizes.body};
  margin-top: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: 0;
  }

  &.First {
    margin-block-end: 20px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: 0;
    }
  }
`;

export const StyledHeader = styled(H3)`
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: start;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    text-align: start;
  }
`;

export const StyledArrowLink = styled(ArrowLink)`
  margin-top: ${spacing.regular};
`;
