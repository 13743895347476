import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { getFilterQuery, getFilterQueryObject } from '@hultafors/shared/api';
import { useUpdateQuery } from '@hultafors/shared/hooks';
import {
  ParsedProductsApiResponse,
  ProductFilter,
  ProductFilterValue,
  ProductListResponse,
  QueryProductFilter,
} from '@hultafors/shared/types';

import { parseIncomingFilters } from '@hultafors/hultafors/api';
import { PRODUCTS_PAGE_SIZE } from '@hultafors/hultafors/helpers/constants';
import { useGlobal } from '@hultafors/hultafors/hooks';
import { HultaforsProduct } from '@hultafors/hultafors/types';

import { ProductListBlock } from '../ProductListBlock/ProductListBlock';

interface CategoryPageContentProps {
  productCatalogNodeId?: string;
  productListApiBaseUrl: string;
  fallbackData: ParsedProductsApiResponse<HultaforsProduct>[];
  initialFilters: ProductFilter[];
  categorySlug: string;
}
export const CategoryPageContent: React.FC<CategoryPageContentProps> = ({
  productCatalogNodeId = '',
  fallbackData = [],
  initialFilters = [],
  categorySlug,
}) => {
  const { siteLocale } = useGlobal();
  const { locale, ...router } = useRouter();
  // Hack to not fetch new data on initial filter set
  const [filters, setFilters] = useState<ProductFilter[]>();
  const updateQuery = useUpdateQuery({
    filter: ['category'],
  });

  // Creates api path that also acts as cache key for swr
  const getKey: KeyLoader = (
    index: number,
    previousPageData: ParsedProductsApiResponse<HultaforsProduct> | null,
  ) => {
    if (!productCatalogNodeId) return null;
    if (
      previousPageData
      && previousPageData.paging.pageCount === previousPageData.paging.pageNumber
    ) {
      return null;
    }
    const path = '/api/productslist/23';

    const searchParams = new URLSearchParams({
      productCatalogNodeId: productCatalogNodeId,
      pageSize: `${PRODUCTS_PAGE_SIZE}`,
      includeFilter: 'true',
      siteId: siteLocale.parttrapSiteId ?? '',
      market: siteLocale.parttrapMarket ?? '',
      pageNumber: `${index + 1}`,
    });

    if (siteLocale.parttrapLanguage) {
      searchParams.set('lang', siteLocale.parttrapLanguage);
    }

    if (filters) {
      Object.entries(getFilterQueryObject(filters ?? [])).forEach(
        ([key, value]) => {
          searchParams.set(key, value);
        },
      );
    }
    if (!filters && typeof window !== 'undefined') {
      const incomingFilters = new URLSearchParams(window.location.search);
      incomingFilters.forEach((value, key) => {
        if (key.startsWith('f')) {
          searchParams.set(key, value);
        }
      });
    }

    return [path, searchParams.toString()].join('?');
  };

  // Config swr to use server side data
  const swrConfig: SWRInfiniteConfiguration<
    ParsedProductsApiResponse<HultaforsProduct>
  > = {
    fallbackData,
    initialSize: fallbackData?.length || 0,
  };

  // Initialize swr for product data fetching
  const { data, error, isLoading, size, setSize } = useSWRInfinite<
    ProductListResponse<HultaforsProduct>
  >(getKey, swrConfig);

  const paging = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.paging;
  }, [data, size]);

  const products = useMemo(() => {
    return data?.flatMap(({ items }) => items) || [];
  }, [data]);

  useEffect(() => {
    if (data?.length) {
      if (!filters) {
        setFilters((previousState) => {
          if (previousState?.length) {
            return previousState;
          }

          let incomingFilters: QueryProductFilter[] = [];

          if (!filters && typeof window !== 'undefined') {
            incomingFilters = parseIncomingFilters(
              new URLSearchParams(window.location.search),
            );
          }

          return (
            data[data.length - 1]?.filters?.map(({ values, ...filter }) => {
              return {
                ...filter,
                values: values.map((value) => {
                  return {
                    ...value,
                    active:
                      incomingFilters.some(
                        ({ ValueId }) => ValueId === `${value.id}`,
                      ) ?? value.active,
                  };
                }),
              };
            }) ?? []
          );
        });
      }
    }
  }, [data]);

  useEffect(() => {
    setSize(1);
  }, [filters]);

  /**
   * Handles changed filters further down the tree
   * @param param0 Filter to toggle
   */
  const filterChange = ({ AttrId, ValueId }: QueryProductFilter) => {
    const filterMapper = ({ values, ...filter }: ProductFilter) => {
      const valueMapper = (value: ProductFilterValue) => ({
        ...value,
        active:
          `${filter.id}` === `${AttrId}` && `${value.id}` === `${ValueId}`
            ? !value.active
            : value.active,
      });
      return {
        ...filter,
        values: values.map(valueMapper),
      };
    };
    setFilters((previousState) => {
      const newState: ProductFilter[] = filters?.map(filterMapper) ?? [];
      if (previousState) {
        updateQuery(getFilterQuery(newState));
      }
      return newState;
    });
  };

  const clearFilters = () => {
    setFilters((previousState) => {
      const newState: ProductFilter[]
        = filters?.map((filter) => ({
          ...filter,
          values: filter.values.map((value) => ({ ...value, active: false })),
        })) ?? [];
      if (previousState?.length) {
        updateQuery(getFilterQuery(newState));
      }
      return newState;
    });
  };

  const loadMore = () => {
    const newSize = size + 1;
    setSize(newSize);
    const params = new URLSearchParams(router.query as Record<string, string>);
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }

    updateQuery(params);
  };

  if (!data) {
    return null;
  }

  return (
    <ProductListBlock
      loading={false}
      error={error}
      products={products}
      paging={paging}
      filters={filters ?? []}
      productsPageSize={PRODUCTS_PAGE_SIZE}
      filterChange={filterChange}
      clearFilters={clearFilters}
      loadMore={loadMore}
      loadingMore={isLoading}
      categorySlug={categorySlug}
    />
  );
};
