import { Fragment } from 'react';

import Image from 'next/image';

import { ProductModelData } from '@hultafors/shared/types';

import { createProductImageSrc } from '@hultafors/hultafors/helpers/create-product-image-src/create-product-image-src';

import {
  Label,
  ModelImage,
  ModelInfo,
  ModelName,
  Outline,
  ProductInfo,
  ProductModelStyled,
  Value,
} from './ProductModel.styled';

interface ProductModelProps {
  model: ProductModelData;
  chooseText: string;
  selectModel(model: ProductModelData): void;
  skuLabel: string;
}
export const ProductModel: React.FC<ProductModelProps> = ({
  model,
  chooseText,
  selectModel,
  skuLabel,
}) => {
  return (
    <ProductModelStyled>
      <>
        {model.name && <ModelName>{model.name}</ModelName>}
        <ModelImage>
          <Image
            src={createProductImageSrc(
              model.image?.url,
              '/assets/img/noimage.png',
            )}
            alt={model.name || ''}
            width={336}
            height={336}
            style={{ objectFit: 'contain' }}
          />
        </ModelImage>

        <ModelInfo>
          <ProductInfo>
            <Label>{skuLabel}</Label>
            <Value>{model.sku || ''}</Value>

            {model.attributes?.map(({ label, value }) => (
              <Fragment key={`PM-ATTR-${label}`}>
                <Label>{label}</Label>
                <Value>{value}</Value>
              </Fragment>
            ))}
          </ProductInfo>

          <Outline onClick={() => selectModel(model)}>{chooseText}</Outline>
        </ModelInfo>
      </>
    </ProductModelStyled>
  );
};
