import { GlobalFragment } from '@hultafors/hultafors/types';

import { H1 } from '../H1/H1';
import { Page } from '../Page/Page';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';

import { ErrorPageStyled } from './ErrorPage.styled';
interface ErrorPageProps {
  globalData: GlobalFragment;
  settings: object;
}
export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const title = props.globalData.errorTitle
    ? props.globalData.errorTitle
    : 'Error';
  const description = props.globalData.errorDescription
    ? props.globalData.errorDescription
    : 'Ooops, an error ocurred!!!';
  return (
    <ErrorPageStyled>
      <Page>
        <Section largePaddingTop>
          <H1>{title}</H1>
          <Paragraph>{description}</Paragraph>
        </Section>
      </Page>
    </ErrorPageStyled>
  );
};
