import { ArticleQuoteFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { QuoteBlockStyled } from './QuoteBlock.styled';

export const QuoteBlock: React.FC<ArticleQuoteFragment> = ({
  quote,
  quoteName,
  quoteProfession,
}) => {
  return (
    <QuoteBlockStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
            { breakpoint: 'desktopMedium', columns: 6, start: 4 },
          ]}
        >
          <Grid
            columns={[
              { columns: 6 },
              { breakpoint: 'largeMobile', columns: 8 },
              { breakpoint: 'mobileMax', columns: 6 },
            ]}
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
            className="QuoteWrapper"
          >
            <GridChild
              columnSpan={[
                { columns: 1 },
                { breakpoint: 'largeMobile', columns: 1, start: 2 },
                { breakpoint: 'mobileMax', columns: 1, start: 1 },
              ]}
            >
              <span className="QuoteDash" />
            </GridChild>
            <GridChild
              columnSpan={[
                { columns: 5, start: 2 },
                { breakpoint: 'largeMobile', columns: 5, start: 3 },
                { breakpoint: 'mobileMax', columns: 5, start: 2 },
              ]}
            >
              <span className="Quote">{`"${quote}"`}</span>
              {quoteName && quoteProfession && (
                <div className="Quoter">
                  <span className="Name">{quoteName}</span>
                  <span className="Title">{quoteProfession}</span>
                </div>
              )}
            </GridChild>
          </Grid>
        </GridChild>
      </Grid>
    </QuoteBlockStyled>
  );
};
