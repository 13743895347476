import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './StyledMarkdown';
interface MarkdownProps {
  variant?: 'light' | 'dark';
  children?: string;
  className?: string;
  options?: MarkdownToJSX.Options;
}
export const Markdown: React.FC<MarkdownProps> = ({
  children,
  className,
  options,
  variant,
}) => {
  return (
    <StyledMarkdown className={className} options={options} $variant={variant}>
      {children ?? ''}
    </StyledMarkdown>
  );
};
