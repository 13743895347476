import Link from 'next/link';

import {
  ProductDetailsApiImage,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { H4 } from '../H4/H4';
import { Icon } from '../Icon/Icon';
import { Paragraph } from '../Paragraph/Paragraph';

import { ImageAttributeStyled } from './ImageAttribute.styled';

interface ImageAttributeProps {
  title: string;
  attributes?: ProductDetailsValue[];
  url?: string;
  size?: string;
  attributeName: string;
  image?: ProductDetailsApiImage;
}
export const ImageAttribute: React.FC<ImageAttributeProps> = (props) => (
  <ImageAttributeStyled>
    {props.attributeName === 'documents'
      ? (
        <div className="DocumentWrapper">
          <div className="ImgWrapper">
            <Icon svg="file" width={16} />
          </div>
          <Paragraph small>
            <Link href={props.url || ''} download>
              {props.title}
            </Link>
            {props.size && ' (' + props.size + ')'}
          </Paragraph>
        </div>
        )
      : (
          props.title && (
            <H4 className={props.attributeName + ' Title'}>
              {props.image && <img src={props.image.url} alt={props.title} />}
              {props.title}
            </H4>
          )
        )}
    {props.attributes?.map((attr, i) => {
      return (
        <div
          key={`${props.attributeName}-${i}`}
          className={props.attributeName + ' Attribute'}
        >
          {attr.image && (
            <div className="ImgWrapper">
              <img src={`${attr.image.url}`} alt={attr.text} />
            </div>
          )}
          <Paragraph>{attr.text}</Paragraph>
        </div>
      );
    })}
  </ImageAttributeStyled>
);
