export const slugify = (value: string) => {
  const specialCharacters
    = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const acceptedChars
    = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(specialCharacters.split('').join('|'), 'g');

  return (
    value
      ?.trim()
      ?.toLowerCase()
      ?.replace(/\s+/g, '-') // Replace spaces with -
      ?.replace(p, (c) => acceptedChars.charAt(specialCharacters.indexOf(c))) // Replace special chars
      ?.replace(/&/g, '-and-') // Replace & with 'and'
      ?.replace(/[^\w-]+/g, '') // Remove non word chars
      ?.replace(/--+/g, '-') || '' // Replace multiple dashes with single
  );
};

export const cleanTextFromUrl = (str: string) =>
  str?.replace(/[+]/g, ' ').replace(/%20/g, ' ') || '';

export const removeLastWordInString = (value: string, wordCriteria: string) => {
  if (!value) {
    return '';
  }

  const words = value.split(' ');
  // Only remove if we have multiple words
  if (words.length > 1) {
    const lastWord = words?.pop()?.toLowerCase();
    // Remove last word + space
    if (lastWord === wordCriteria) {
      return value.substring(0, value.length - (lastWord.length + 1));
    }
  }

  return value;
};

export const cleanTextFromNonLettersAndNumbers = (
  str: string,
  replacement = '_',
): string => str.toLowerCase().replace(/[^A-Z0-9]/gi, replacement);

export const areStringsEqualWhenCleaned = (
  str: string,
  str2: string,
): boolean =>
  cleanTextFromNonLettersAndNumbers(str)
  === cleanTextFromNonLettersAndNumbers(str2);

export const hasStringMoreCharsThanMax = (
  str: string,
  maxCount: number,
): boolean => str.length > maxCount;
