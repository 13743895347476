import { Graphic } from '../Graphic/Graphic';

import { LoaderStyled } from './Loader.styled';

interface LoaderProps {
  green?: boolean;
  small?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ small, green }) => (
  <LoaderStyled $small={small} $green={green}>
    <Graphic image="/assets/gfx/icon-spinner.svg" width={56} height={56} />
  </LoaderStyled>
);
