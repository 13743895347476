import { useMemo, useState } from 'react';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { TagFilter, TagFilterOption } from '@hultafors/hultafors/types';

import { Button } from '../Button/Button';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';
import { Icon } from '../Icon/Icon';
import { SlideIn } from '../SlideIn/SlideIn';

import {
  Actions,
  ArticleFilterStyled,
  BoxFilters,
  ButtonFilters,
  ClearContainer,
  ClearIcon,
  DropDownFilters,
  FilterButton,
  FilterLabel,
  FilterWrapper,
  IconButton,
  Info,
  MobileFilterBar,
  SubFilter,
  SubFilters,
} from './ArticleFilter.styled';

export interface ArticleFilterProps {
  filters: TagFilter[];
  toggle(id: string): void;
  clear(): void;
  loading?: boolean;
  clearLabel?: string;
}

export const ArticleFilter: React.FC<ArticleFilterProps> = ({
  filters = [],
  toggle,
  clear,
  loading,
  clearLabel = '',
}) => {
  const { globalContent } = useGlobal();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleMobileFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const toggleActive = (index: number) => {
    const currentIndex = activeIndex === index ? null : index;
    setActiveIndex(currentIndex);
  };

  const isSelectionUnderParent = (parentId: string): boolean => {
    return (
      !!filters
        .find(({ id }) => parentId === id)
        ?.options?.filter((option: TagFilterOption) => option.active)?.length
        || false
    );
  };

  const activeFilters: string[] = useMemo(() => {
    return (
      filters.flatMap((filter) => {
        return (
          filter.options
            ?.filter(({ active }) => !!active)
            .map(({ label, id }) => `${label || id}`) || []
        );
      }) || []
    );
  }, [filters]);

  const filterMapper = (filter: TagFilter, i: number) => {
    if (!filter.options?.length) {
      return null;
    }
    const onClick = () => {
      toggleActive(i);
    };

    return (
      <FilterWrapper key={`Filter-${filter.id}`}>
        <Button
          text
          className={`Icon MobileFilter ${activeIndex === i ? 'Active ' : ''} ${
            isSelectionUnderParent(filter.id) ? 'ChildSelected' : ''
          }`}
          onClick={onClick}
          svgIcon="chevron"
        >
          <span>{filter.name}</span>
        </Button>
        <SubFilters
          $numberOfOptions={filter.options.length}
          $active={activeIndex === i}
        >
          {filter.options.map((option: TagFilterOption, j: number) => {
            const onChange = () => {
              toggle(option.id);
              setActiveIndex(null);
            };

            return (
              <SubFilter key={`SubFilter-${j}`}>
                <FilterLabel $checked={option.active}>
                  <input
                    type="checkbox"
                    onChange={onChange}
                    checked={option.active}
                    disabled={loading}
                  />
                  <span>{option.label}</span>
                </FilterLabel>
              </SubFilter>
            );
          })}
        </SubFilters>
      </FilterWrapper>
    );
  };

  const onClearClick = () => {
    clear?.();
  };

  const onMobileClearClick = () => {
    clear();
    toggleMobileFilter();
  };

  return (
    <>
      <ArticleFilterStyled>
        <ButtonFilters />
        <MobileFilterBar>
          <FilterButton
            onClick={toggleMobileFilter}
            aria-label={globalContent?.showFilters ?? ''}
          >
            <Icon svg="filter" />
          </FilterButton>
        </MobileFilterBar>
        <BoxFilters>
          <DropDownFilters>{filters.map(filterMapper)}</DropDownFilters>
          <Actions>
            <Button
              onClick={onClearClick}
              disabled={loading}
              aria-label={clearLabel}
              text
            >
              <ClearIcon>
                <Icon svg="trash" width={16} />
              </ClearIcon>
            </Button>
          </Actions>
        </BoxFilters>

        <Info>
          {!activeFilters?.length
            ? globalContent?.showingAllArticles
            : `${globalContent?.filterLabel}: ${activeFilters.join(', ')}`}
        </Info>
      </ArticleFilterStyled>
      <SlideIn
        isOpen={isFilterOpen}
        toggle={toggleMobileFilter}
        headerText={globalContent?.filterLabel ?? ''}
        headerIcon="filter"
        padContent
        clearText={globalContent?.clearLabel ?? ''}
        clearAll={clear}
      >
        <div>
          <ClearContainer>
            <IconButton onClick={onMobileClearClick} text>
              {globalContent?.clearFilterLabel}
              <Icon svg="trash" width={16} />
            </IconButton>
          </ClearContainer>

          <BoxFilters>
            <DropDownFilters $isMobileFilter={true}>
              {filters.map(filterMapper)}
            </DropDownFilters>

            <Actions>
              <Button
                onClick={onClearClick}
                disabled={loading}
                aria-label={clearLabel}
                text
              >
                <ClearIcon>
                  <Icon svg="trash" width={16} />
                </ClearIcon>
              </Button>
            </Actions>
          </BoxFilters>

          <ButtonWrapper>
            <Button
              onClick={() => toggleMobileFilter()}
              className="Black"
              fullWidth
            >
              {globalContent?.doneLabel || 'Done'}
            </Button>
          </ButtonWrapper>
        </div>
      </SlideIn>
    </>
  );
};
