import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

interface ImageBlockStyledProps {
  $fullWidthImage?: boolean;
}
export const ImageBlockStyled = styled.div<ImageBlockStyledProps>`
  margin: ${spacing.medium} 0;
  padding: 0
    ${({ $fullWidthImage }) => !$fullWidthImage && spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
    max-block-size: unset;
    padding: 0
      ${({ $fullWidthImage }) => !$fullWidthImage && spacing.pagePadding};
  }
`;
