import { RouterLink } from '../RouterLink/RouterLink';

import { LinkBlockStyled } from './LinkBlock.styled';

interface LinkBlockProps {
  linkText?: string;
  linkUrl?: string;
  external?: boolean;
}

export const LinkBlock: React.FC<LinkBlockProps> = ({
  linkText,
  linkUrl,
  external,
}) => {
  return (
    <LinkBlockStyled>
      {linkUrl && linkText && (
        <RouterLink
          className="TextLink"
          to={linkUrl || ''}
          external={external}
          svgIcon="next"
        >
          {linkText}
        </RouterLink>
      )}
    </LinkBlockStyled>
  );
};
