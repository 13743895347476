import { useRef, useState } from 'react';

import { ArticleVideoFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Icon } from '../Icon/Icon';

import {
  PlayButton,
  Video,
  VideoBlockStyled,
  VideoControls,
  VideoWrapper,
} from './Video.styled';

interface VideoBlockProps extends ArticleVideoFragment {
  src?: string;
}

export const VideoBlock: React.FC<VideoBlockProps> = ({
  fullWidth,
  image,
  video,
  src,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [paused, setPaused] = useState(true);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.setAttribute('controls', 'true');
      setPaused(false);
    }
  };

  return (
    <VideoBlockStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
          ]}
        >
          <VideoWrapper>
            <Video
              ref={videoRef}
              poster={image?.url || ''}
              $fullWidth={!!fullWidth}
            >
              <source
                src={src || video?.video?.mp4Url || ''}
                type="video/mp4"
              />
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a
                {' '}
                <a href={src || video?.video?.mp4Url || ''}>
                  link to the video
                </a>
                {' '}
                instead.
              </p>
            </Video>
            <VideoControls>
              <PlayButton title="Play" onClick={playVideo} $visible={paused}>
                <Icon svg="play" width={84} />
              </PlayButton>
            </VideoControls>
          </VideoWrapper>
        </GridChild>
      </Grid>
    </VideoBlockStyled>
  );
};
