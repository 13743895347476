import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

const desktopMenuHeight = 500;

interface SlideInNewStyledProps {
  $fromTop?: boolean;
  $fromLeft?: boolean;
  $small?: boolean;
  $padContent?: boolean;
}

export const SlideInNewStyled = styled.div<SlideInNewStyledProps>`
  .SlideInBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow-x: hidden;
    background-color: ${colors.overlayTransparent};
    opacity: 0;
    transition: opacity 0.3s, z-index 0.1s 0.3s;

    &.active {
      z-index: ${({ $fromTop }) => ($fromTop ? '9' : '10')};
      opacity: 1;
      transition: opacity 0.3s, z-index 0.1s;
    }
  }

  .SlideInInside {
    position: absolute;
    top: 0;
    right: ${({ $fromLeft }) => ($fromLeft ? 'auto' : 0)};
    left: ${({ $fromLeft }) => ($fromLeft ? 0 : 'auto')};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 12;
    box-sizing: border-box;
    background-color: ${colors.white};
    transform: ${({ $fromTop, $fromLeft }) =>
      $fromTop
        ? 'translateY(-110%)'
        : $fromLeft
        ? 'translateX(-110%)'
        : 'translateX(110%)'};
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: ${({ $fromTop, $small }) =>
        $fromTop && $small
          ? 'translateY(-61%)'
          : $fromTop
          ? 'translateY(0%)'
          : 'translateX(0%)'};
      transition: transform 0.3s ease-in-out;
    }

    @media screen and (min-width: ${breakpoints.bigMobile}) {
      width: 100%;
      max-width: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
      max-height: ${({ $fromTop }) =>
        $fromTop ? desktopMenuHeight + 'px' : 'none'};
    }

    .SlideInHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      min-height: 56px;
      padding-left: ${spacing.pagePaddingMobile};
      border-bottom: thin solid ${colors.gray4};

      .HeaderText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          font-size: ${fontSizes.body};
          font-weight: ${fontWeights.normal};
          letter-spacing: 0.5px;

          @media screen and (min-width: ${breakpoints.mediumMobile}) {
            font-size: ${fontSizes.h5};
          }
        }

        .HeaderIcon {
          appearance: none;
          border: 0;
          border-radius: 0;
          padding: 0;
          background: transparent;
          width: ${spacing.regular};
          height: ${spacing.regular};
          display: inline-flex;
          margin-right: ${spacing.xsmall};

          @media screen and (min-width: ${breakpoints.mediumMobile}) {
            margin-right: ${spacing.small};
          }
        }
      }
    }

    .SlideInBody {
      padding: ${({ $padContent }) =>
        $padContent
          ? `${spacing.regular} ${spacing.pagePaddingMobile} ${spacing.medium} ${spacing.pagePaddingMobile}`
          : ''};
      overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'scroll')};
      height: ${({ $fromTop }) => ($fromTop ? 'auto' : '100%')};
      width: 100%;
      box-sizing: border-box;

      &.isCart {
        padding: ${spacing.pagePaddingMobile} ${spacing.pagePaddingMobile} 150px
          ${spacing.pagePaddingMobile};
      }
    }
  }
`;

export const SlideInExit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:empty {
    display: none;
  }
`;

export const Clear = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  margin-inline: ${spacing.small};
  cursor: pointer;
  transition: 0.2s ease;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    margin-right: ${spacing.regular};
  }

  svg {
    width: ${spacing.small};
    height: ${spacing.small};
    margin-left: ${spacing.xsmall};

    path {
      transition: 0.2s ease;
    }
  }

  &:hover {
    color: ${colors.gray1};
  }
`;

export const ClearLabel = styled.span`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.03125rem;
`;

export const CloseButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: transparent;
  display: flex;
  margin-inline: ${spacing.xsmall};
  height: 100%;
  cursor: pointer;

  svg {
    width: ${spacing.regular};
    height: ${spacing.regular};
  }

  svg path {
    transition: 0.2s ease;
    fill: ${colors.black};
  }

  &:hover {
    color: ${colors.gray1};
  }
`;

export const ExternalLinksContainer = styled.div`
  margin-inline-start: auto;
  display: flex;
  align-items: center;
  div {
    display: flex;
    height: 100%;
    width: max-content;
    padding: ${spacing.small};
    border-left: thin solid ${colors.border};
    border-right: thin solid ${colors.border};
  }
`;

export const ExternalLink = styled(Link)`
  color: ${colors.black};
  display: flex;
  align-items: center;
  letter-spacing: 0.15rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.medium};

  svg {
    margin-left: ${spacing.xsmall};
  }
`;

export const CartIcon = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  .Badge {
    top: -7px;
    right: -8px;
  }
`;

export const LanguageContainer = styled.div`
  padding-inline: ${spacing.xsmall};
`;
