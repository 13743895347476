import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { getLanguages } from '@hultafors/shared/api';
import { Language } from '@hultafors/shared/types';

import { markets } from '@hultafors/hultafors/api';
import { useGlobal } from '@hultafors/hultafors/hooks';

import { Dropdown } from '../Dropdown/Dropdown';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  Content,
  InfoText,
  InnerWrapper,
  LanguageSelectorStyled,
  OuterWrapper,
} from './LanguageSelector.styled';

interface LanguageSelectorProps {
  chooseLanguageLabel?: string;
  infoText?: string;
  dark?: boolean;
  isDesktopMenu?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  chooseLanguageLabel = 'Choose language',
  infoText = '',
  isDesktopMenu,
  dark,
}) => {
  const { settings } = useGlobal();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const languages: Language[] = useMemo(
    () => getLanguages(settings?.market, markets),
    [settings?.market],
  );
  const listItems: string[] = useMemo(
    () => languages.map(({ name, lang }: Language) => name || lang || ''),
    [languages],
  );

  const router = useRouter();

  const getIndexOfSelected = () => {
    return languages?.findIndex((item) => {
      return (
        !!settings.language?.lang
        && !!item.lang
        && item.lang === settings.language?.lang
      );
    });
  };

  useEffect(() => {
    if (
      settings
      && typeof languages?.length === 'number'
      && languages.length > 1
      && selectedIndex < 0
    ) {
      setSelectedIndex(getIndexOfSelected());
    }
  }, [languages, settings]);

  const changeLanguage = (index: number) => {
    const selectedLang = languages[index];
    router.replace(router.asPath, router.asPath, {
      locale: selectedLang?.urlPrefix || 'com',
    });
  };

  const hasDomainMultipleLanguages = languages && languages.length > 1;
  const isVisible = hasDomainMultipleLanguages;

  return isVisible
    ? (
      <LanguageSelectorStyled $dark={dark} $isDesktopMenu={isDesktopMenu}>
        {isDesktopMenu
          ? (
            <OuterWrapper>
              <InnerWrapper>
                {infoText && (
                  <InfoText>
                    <Paragraph>{infoText}</Paragraph>
                  </InfoText>
                )}
                <Content>
                  <Dropdown
                    label={chooseLanguageLabel}
                    onSelectItem={changeLanguage}
                    listItems={listItems}
                    selectedIndex={selectedIndex}
                    dark={dark}
                  />
                </Content>
              </InnerWrapper>
            </OuterWrapper>
            )
          : (
            <Content>
              <Dropdown
                label={chooseLanguageLabel}
                onSelectItem={changeLanguage}
                selectedIndex={selectedIndex}
                listItems={listItems}
                dark={dark}
              />
            </Content>
            )}
      </LanguageSelectorStyled>
      )
    : null;
};
