import dynamic from 'next/dynamic';
import image from 'next/image';

import { MobileDesktopImages } from '@hultafors/hultafors/types';

import { RouterLink } from '../RouterLink/RouterLink';

import {
  ArrowLinkCustomStyle,
  ArticlePlugContent,
  ArticlePlugStyled,
  PlugLabel,
  PlugTitle,
} from './ArticlePlug.styled';

const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

export interface ArticlePlugProps extends MobileDesktopImages {
  darkText?: boolean;
  label?: string;
  url?: string;
  title?: string;
}

export const ArticlePlug: React.FC<ArticlePlugProps> = ({
  darkText,
  label,
  title,
  url,
  mobileImage,
  desktopImage,
}) => {
  return (
    <RouterLink to={url}>
      <ArticlePlugStyled
        $darkText={darkText}
        $image={
          !!mobileImage?.responsiveImage || !!desktopImage?.responsiveImage
        }
      >
        {desktopImage?.responsiveImage && (
          <AspectRatioImage
            mobileImage={mobileImage}
            desktopImage={desktopImage}
          />
        )}

        <ArticlePlugContent>
          {label && (
            <PlugLabel
              $darkText={darkText}
              $image={!!desktopImage || !!mobileImage}
            >
              {label}
            </PlugLabel>
          )}
          <PlugTitle $darkText={darkText} $image={!!image}>
            {title}
          </PlugTitle>
          <ArrowLinkCustomStyle
            white={!darkText && desktopImage ? true : false}
            direction="forward"
            isAnchor={false}
          />
        </ArticlePlugContent>
      </ArticlePlugStyled>
    </RouterLink>
  );
};
