import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { H3 } from '../H3/H3';
import { Paragraph } from '../Paragraph/Paragraph';

export const ButtonContainer = styled.div`
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: ${fontSizes.body};
  margin: 0;

  &.BottomSpacing {
    margin-block-end: ${spacing.block};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: 0;
    }
  }
`;

export const StyledHeader = styled(H3)`
  letter-spacing: 1px;
  text-align: start;
  margin-bottom: ${spacing.regular};
`;

export const StyledButtonLink = styled(ButtonLink)`
  margin-top: ${spacing.regular};
`;
