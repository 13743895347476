import { TextButton } from '../TextButton/TextButton';

import {
  DialogContent,
  DialogHeader,
  DialogWrapper,
} from './DialogComponent.styled';

interface DialogCompontentProps {
  children?: React.ReactNode;
  closeButtonLabel?: React.ReactNode;
  onClose?(...args: any[]): void;
  isFullscreen?: boolean;
  height?: string;
  width?: string;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
}

export const DialogComponent: React.FC<DialogCompontentProps> = ({
  children,
  closeButtonLabel = 'Close',
  onClose,
  isFullscreen = true,
  height = '100%',
  width = '100%',
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => (
  <DialogWrapper
    $height={height}
    $width={width}
    $top={top}
    $right={right}
    $bottom={bottom}
    $left={left}
  >
    <DialogHeader $isFullscreen={isFullscreen}>
      <TextButton data-test="DialogClose" aria-label="Close" onClick={onClose}>
        {closeButtonLabel}
      </TextButton>
    </DialogHeader>
    <DialogContent>{children}</DialogContent>
  </DialogWrapper>
);
