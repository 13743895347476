import { FC, useEffect, useRef, useState } from 'react';

import {
  useIntersectionObserver,
  useLocalStorageValue,
} from '@react-hookz/web';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { FormBlockFragment } from '@hultafors/hultafors/types';

import { Button } from '../Button/Button';
import { Dialog } from '../Dialog/Dialog';
import { Icon } from '../Icon/Icon';
import { Markdown } from '../Markdown/Markdown';

import {
  FormBlockStyled,
  HiddenFormBlock,
  InlineForm,
  StyledToast,
} from './FormBlock.styled';
const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

// TODO move to better place
declare global {
  interface Window {
    SurveyEmbed: any;
  }
}

export const FormBlock: FC<FormBlockFragment> = ({
  ctaText,
  description,
  displayInline,
  formHeight,
  formUrl,
  formKey,
  formLocation,
  invisibleBlock,
  title,
  desktopImage,
  mobileImage,
}) => {
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const { settings, globalContent } = useGlobal();
  const formRef = useRef(null);
  const entry = useIntersectionObserver(formRef, {});
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const form = useLocalStorageValue<boolean>(
    `${formKey}-${settings?.market || 'com'}`,
    { initializeWithValue: false, defaultValue: false },
  );
  // check with hulta if they get these variables when sending the form
  // otherwise it's useless to use the script embed
  const ctx = {
    site: 'hultafors',
    market: settings.market || 'com ',
    location: formLocation || '',
  };

  const toggleDialog = () => {
    setDisplayDialog(!displayDialog);
  };

  useEffect(() => {
    if (displayDialog) {
      form.set(true);
    }
  }, [displayDialog]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setDisplayToast(true);
    }
  }, [entry]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src
      = 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.SurveyEmbed) {
        const SurveyEmbed = window.SurveyEmbed;
        // TODO this should come from dato, might only need ID
        const survey = new SurveyEmbed(
          '7au4sn6fmU20ZPg_YS2yQgyAeyrnaj1Jo01bK8zeR8lUM1JXRVg3NkRHWTdHOTVETlZZSEdUWVNZOC4u',
          'https://customervoice.microsoft.com/',
          'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/',
          'true',
        );

        if (invisibleBlock) {
          survey.renderButton(ctx);
        }

        if (displayInline) {
          survey.renderInline('inlineSurvey', ctx);
        }
      }
    });
  }, [displayInline]);

  const handlePopUp = async () => {
    const script = document.createElement('script');
    script.src
      = 'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.SurveyEmbed) {
        const SurveyEmbed = window.SurveyEmbed;
        const survey = new SurveyEmbed(
          '7au4sn6fmU20ZPg_YS2yQgyAeyrnaj1Jo01bK8zeR8lUM1JXRVg3NkRHWTdHOTVETlZZSEdUWVNZOC4u',
          'https://customervoice.microsoft.com/',
          'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/',
          'true',
        );
        if (survey) {
          survey.renderPopup(ctx);
          form.set(true);
          setDisplayToast(false);
        }
      }
    });
  };

  return (
    <>
      <Head>
        {/* // TODO appropriate as value, is it working? */}
        {formUrl && <link rel="preload" href={formUrl} as="script" />}
        <link
          rel="stylesheet"
          type="text/css"
          href="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.css"
        />
      </Head>
      <Script
        src="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js"
        type="text/javascript"
      />
      {!displayInline && displayDialog && (
        <Dialog onHandleClose={() => toggleDialog()} formUrl={formUrl || ''} />
      )}
      {!invisibleBlock
        ? (
          <FormBlockStyled $image={!!desktopImage || !!mobileImage}>
            <div className="ContentWrapper">
              <div className="TextContent">
                {title && <h2>{title}</h2>}
                {description && <Markdown variant="dark">{description}</Markdown>}
                {ctaText && (
                  <Button onClick={() => toggleDialog()}>{ctaText}</Button>
                )}
              </div>
              {desktopImage && (
                <div className="ImageContainer">
                  <AspectRatioImage
                    desktopImage={desktopImage}
                    mobileImage={mobileImage || undefined}
                  />
                </div>
              )}
            </div>
            {displayInline && (
              <InlineForm $height={formHeight} id="inlineSurvey" />
            )}
          </FormBlockStyled>
          )
        : !form.value && displayToast
            ? (
              <StyledToast>
                <div className="TextContent">
                  <button
                    className="CloseBtn"
                    onClick={() => handlePopUp()}
                    aria-label={globalContent.closeLabel || 'Close'}
                  >
                    <Icon svg="close" />
                  </button>
                  {title && <h2>{title}</h2>}
                  {description && <Markdown variant="dark">{description}</Markdown>}
                  {ctaText && (
                    <Button onClick={() => handlePopUp()}>{ctaText}</Button>
                  )}
                </div>
              </StyledToast>
              )
            : (
              <HiddenFormBlock ref={formRef} />
              )}
    </>
  );
};
