import styled from 'styled-components';

import { fontWeights, spacing } from '@hultafors/hultafors/tokens';

import { Paragraph } from '../Paragraph/Paragraph';

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${spacing.regular};
  font-weight: ${fontWeights.medium};
`;
