import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultafors/tokens';

interface H1StyledProps {
  $center?: boolean;
}

export const H1Styled = styled.h1<H1StyledProps>`
  font-size: ${fontSizes.h1mobile};
  line-height: ${lineHeights.h1mobile};
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
  font-weight: ${fontWeights.normal};
  margin: 0;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.h1};
    line-height: ${lineHeights.h1};
  }
`;
