import { LabelStyled } from './Label.styled';

interface LabelProps {
  for?: string;
  htmlFor?: string;
  label?: string;
  children?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = (props) => (
  <LabelStyled htmlFor={props.for ?? props.htmlFor}>
    {props.label ?? props.children}
  </LabelStyled>
);
