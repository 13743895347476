import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const AccordionStyled = styled.div`
  width: 100%;

  &.ProductDetails {
    margin-block-start: 16px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-start: 0;
    }

    .MaterialsText {
      margin-block: 0 24px;
    }
  }

  .notCollapsibleItem {
    display: flex;
    block-size: ${spacing.medium};
    padding: 0 ${spacing.pagePaddingMobile};
    margin: 0 -${spacing.pagePaddingMobile} 0 -${spacing.pagePaddingMobile};

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
      inline-size: 100%;
      margin: 0;
      line-height: ${spacing.medium};
      cursor: pointer;

      &:hover {
        color: ${colors.gray1};
      }

      h5 {
        margin: 0;
      }
    }
  }

  .CollapseContent .notCollapsibleItem {
    padding: 0;
    margin: 0;
  }
`;
