import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultafors/tokens';

type HTagStyle = 'header1' | 'header2' | 'header3' | 'header4';

interface HTagStyleBreak {
  breakpoint?: Breakpoint;
  styleType: HTagStyle;
}
export type HTagStyleTypeProp = HTagStyle | HTagStyleBreak[];

interface HTagStyledProps {
  $styleType?: HTagStyleTypeProp;
  $center?: boolean;
}

const fontStyle = (styleType?: HTagStyleTypeProp) => {
  if (styleType === 'header2') {
    return css`
      font-size: ${fontSizes.h2};
      line-height: ${lineHeights.h2};
      font-weight: ${fontWeights.normal};
    `;
  }
  if (styleType === 'header3') {
    return css`
      font-size: ${fontSizes.h3};
      line-height: ${lineHeights.h3};
      font-weight: ${fontWeights.normal};
    `;
  }
  if (styleType === 'header4') {
    return css`
      font-size: ${fontSizes.h4};
      line-height: ${lineHeights.h4};
      font-weight: ${fontWeights.normal};
    `;
  }

  return css`
    font-size: ${fontSizes.h1};
    line-height: ${lineHeights.h1};
    font-weight: ${fontWeights.normal};
  `;
};

const generalFontStyle = ({ $center, $styleType }: HTagStyledProps) => css`
  letter-spacing: 0.01em;
  color: ${colors.black};
  text-align: ${$center ? 'center' : 'initial'};
  ${handleStyleType($styleType)}
`;

const handleStyleType = ($styleType?: HTagStyleTypeProp) => {
  const styleTypeMapper = ({ breakpoint, styleType }: HTagStyleBreak) => {
    if (breakpoint) {
      return css`
        @media all and (min-width: ${breakpoints[breakpoint]}) {
          ${fontStyle(styleType)}
        }
      `;
    }
    return fontStyle(styleType);
  };
  if (Array.isArray($styleType)) {
    $styleType.map(styleTypeMapper);
  }
  return fontStyle($styleType);
};

export const StyledHTag = styled.h1<HTagStyledProps>`
  ${generalFontStyle};
`;
