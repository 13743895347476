import Markdown from 'markdown-to-jsx';
import Link from 'next/link';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { H3 } from '../H3/H3';

import {
  ContactInformationStyled,
  ContactKey,
  ContactValue,
} from './ContactInformation.styled';

export interface ContactInformationProps {
  enableContactInformation?: boolean;
  addressLabel?: string;
  address?: string;
  companyTitle?: string;
  emailOnlineShop?: string;
  emailOnlineShopLabel?: string;
  email?: string;
  emailLabel?: string;
  fax?: string;
  faxLabel?: string;
  organisationBlock?: string;
  organisationLabel?: string;
  organisation?: string;
  phone?: string;
  phoneLabel?: string;
  visitUsLabel?: string;
  visitUs?: string;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  enableContactInformation,
  addressLabel,
  address,
  companyTitle,
  emailOnlineShop,
  emailOnlineShopLabel,
  email,
  emailLabel,
  fax,
  faxLabel,
  organisationBlock,
  organisationLabel,
  phone,
  phoneLabel,
  visitUsLabel,
  visitUs,
}) => {
  const renderTextBlock = (text: string) => {
    if (!text) return;
    // empty Markdown tag will throw a hissy fit
    return text ? <Markdown>{text}</Markdown> : '';
  };

  const renderNumbersBlock = (numberLabel: string, numbersString: string) => {
    if (!numberLabel && !numbersString) return;
    return numberLabel && numbersString
      ? (
        <p>
          {numberLabel}
        &nbsp;
          {splitNumbers(numbersString)}
        </p>
        )
      : (
          ''
        );
  };

  const splitNumbers = (phoneNumbersString: string) => {
    const numbersArr = phoneNumbersString.split(/[\r\n]+/);
    if (numbersArr.length < 1) return '';
    return numbersArr.map((number, i) => (
      <Link href={`tel:${number}`} key={`nKey${i}`}>
        {number}
      </Link>
    ));
  };

  const renderEmailBlock = (
    emailLabel: string,
    email: string,
    emailOnlineShopLabel: string,
    emailOnlineShop: string,
  ) => {
    if (!emailLabel && !email && !emailOnlineShop && !emailOnlineShopLabel)
      return;
    return email && emailOnlineShop
      ? (
        <p>
          {emailLabel}
        &nbsp;
          <Link href={`mailto:${email}`}>{email}</Link>
          <br />
          {emailOnlineShopLabel}
        &nbsp;
          <Link href={`mailto:${emailOnlineShop}`}>{emailOnlineShop}</Link>
        </p>
        )
      : (
          ''
        );
  };

  if (!address) {
    return null;
  }

  return (
    <ContactInformationStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 3 },
            { breakpoint: 'desktopMedium', columns: 3, start: 4 },
          ]}
        >
          <H2>{companyTitle}</H2>
          <dl>
            {enableContactInformation && (
              <>
                {address && addressLabel && (
                  <>
                    <ContactKey>{addressLabel}</ContactKey>
                    <ContactValue>
                      <Markdown>{address}</Markdown>
                    </ContactValue>
                  </>
                )}
                {phone && phoneLabel && (
                  <>
                    <ContactKey>{phoneLabel}</ContactKey>
                    <ContactValue>
                      <Link href={`tel:${phone}`}>{phone}</Link>
                    </ContactValue>
                  </>
                )}

                {emailOnlineShop && emailOnlineShopLabel && (
                  <>
                    <ContactKey>{emailOnlineShopLabel}</ContactKey>
                    <ContactValue>
                      <Link href={`mailto:${emailOnlineShop}`}>
                        {emailOnlineShop}
                      </Link>
                    </ContactValue>
                  </>
                )}

                {email && emailLabel && (
                  <>
                    <ContactKey>{emailLabel}</ContactKey>
                    <ContactValue>
                      <Link href={`mailto:${email}`}>{email}</Link>
                    </ContactValue>
                  </>
                )}

                {fax && faxLabel && (
                  <>
                    <ContactKey>{faxLabel}</ContactKey>
                    <ContactValue>
                      <Link href={`tel:${fax}`}>{fax}</Link>
                    </ContactValue>
                  </>
                )}

                {organisationBlock && organisationLabel && (
                  <>
                    <ContactKey>{organisationLabel}</ContactKey>
                    <ContactValue>
                      <Markdown>{organisationBlock}</Markdown>
                    </ContactValue>
                  </>
                )}

                {visitUs && visitUsLabel && (
                  <>
                    <ContactKey>{visitUsLabel}</ContactKey>
                    <ContactValue>
                      <Markdown>{visitUs}</Markdown>
                    </ContactValue>
                  </>
                )}
              </>
            )}
            {!enableContactInformation && (
              <div className="BodyText">
                {renderTextBlock(address)}
                {renderNumbersBlock(phoneLabel, phone)}
                {renderEmailBlock(
                  emailLabel,
                  email,
                  emailOnlineShopLabel,
                  emailOnlineShop,
                )}
                {renderNumbersBlock(faxLabel, fax)}
                {renderTextBlock(visitUs)}
                <H3>{organisationLabel}</H3>
                {renderTextBlock(organisationBlock)}
              </div>
            )}
          </dl>
        </GridChild>
      </Grid>
    </ContactInformationStyled>
  );
};
