import { useState } from 'react';

import { Icon } from '../Icon/Icon';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  IntroText,
  ProductIntroStyled,
  ReadMoreButton,
  ReadMoreWrapper,
} from './ProductIntro.styled';

interface ProductIntroProps {
  text?: string | null;
  readMoreText?: string | null;
}

export const ProductIntro: React.FC<ProductIntroProps> = ({
  text,
  readMoreText,
}) => {
  const [readMoreOpen, setReadMoreOpen] = useState<boolean>(false);

  function toggleReadMore() {
    setReadMoreOpen(!readMoreOpen);
  }
  if (text?.length && text.length < 400) {
    return (
      <ProductIntroStyled className="ShortIntro">
        <Paragraph small>{text}</Paragraph>
      </ProductIntroStyled>
    );
  }
  return (
    <ProductIntroStyled>
      <IntroText small readMoreOpen={readMoreOpen}>
        {text}
      </IntroText>
      <ReadMoreWrapper>
        <ReadMoreButton
          role="button"
          type="button"
          open={readMoreOpen}
          onClick={() => toggleReadMore()}
        >
          {readMoreText}
          <Icon svg="plus" />
        </ReadMoreButton>
      </ReadMoreWrapper>
    </ProductIntroStyled>
  );
};
