import { HTagStyleTypeProp, StyledHTag } from './HTag.styled';

type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HTagProps {
  className?: string;
  children?: React.ReactNode;
  type?: HTagType;
  styleType?: HTagStyleTypeProp;
  center?: boolean;
}
export const HTag: React.FC<HTagProps> = ({
  className,
  children,
  type = 'h1',
  styleType = 'header1',
  center,
}) => {
  return (
    <StyledHTag
      as={type}
      className={className}
      $styleType={styleType}
      $center={center}
    >
      {children}
    </StyledHTag>
  );
};
