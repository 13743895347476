import styled, { css, CSSProperties } from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

interface RadioButtonStyledProps {
  $color?: CSSProperties['color'];
}

export const RadioButtonStyled = styled.div<RadioButtonStyledProps>`
  width: 100%;
  box-sizing: border-box;
  font-size: ${fontSizes.body};
  position: relative;

  label {
    ${({ $color }) =>
      $color
      && css`
        color: ${$color};
      `}
  }

  input[type='radio'] {
    margin-block-end: ${spacing.xsmall};
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    inset-inline-start: -9999px;
  }

  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-inline-start: ${spacing.regular};
    padding-block-end: ${spacing.xsmall};
    cursor: pointer;
    line-height: 16px;
    display: inline-block;
    color: ${colors.white};
  }

  [type='radio']:checked + label::before,
  [type='radio']:not(:checked) + label::before {
    content: '';
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    block-size: 14px;
    inline-size: 14px;
    border: thin solid ${colors.white};
    border-radius: 100%;
    background: ${colors.black};
  }

  [type='radio']:checked + label::after,
  [type='radio']:not(:checked) + label::after {
    content: '';
    inset-block-start: 3px;
    inset-inline-start: 3px;
    inline-size: 10px;
    block-size: 10px;
    background: ${colors.white};
    position: absolute;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type='radio']:not(:checked) + label::after {
    opacity: 0;
    transform: scale(0);
  }

  [type='radio']:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }
`;
