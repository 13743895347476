import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const VideoStyled = styled.div`
  aspect-ratio: 16 / 9;

  .amp-flush-skin .vjs-big-play-button {
    inset-inline-start: calc(50% - 0.05em);
    inset-block-start: calc(50% - 0.05em);
  }

  .vjs-big-play-button::before {
    font-size: ${fontSizes.h4};
    padding: ${spacing.regular};
    background-color: ${colors.black};
    border-radius: 100%;
    opacity: 0.8;
    color: ${colors.gray4};
  }

  /* Need increase width to make poster-image fill entire video */
  .vjs-poster {
    img {
      object-fit: cover;
      inline-size: 100%;
      block-size: 100%;
    }
  }
`;
