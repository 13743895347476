import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

export const RelatedProductsStyled = styled.div`
  /* padding: ${spacing.pagePadding}; */
  h2 {
    margin-block-end: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: ${spacing.large};
    }
  }

  .ButtonWrapper {
    max-inline-size: 335px;
    margin: 0 auto;
  }

  .Product {
    &:last-of-type {
      margin-block-end: 0;
    }

    @media screen and (min-width: ${breakpoints.mediumMobile}) {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-block-end: 0;
      }
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-block-end: 0;
    }
  }

  .LinkWrapper {
    display: flex;
    justify-content: center;
    margin: ${spacing.medium} 0;
  }
`;
