import Link from 'next/link';
import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const CartItemStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 120px 1fr 16px;
  gap: 24px;
  width: 100%;

  &:not(:first-of-type) {
    border-block-start: thin solid ${colors.gray4};
    padding-block-start: ${spacing.regular};
    margin-block-start: ${spacing.regular};
  }

  .ProductInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-inline-end: ${spacing.block};

    .ProductName {
      display: block;
      margin-block-end: ${spacing.small};
      font-size: ${fontSizes.xsmall};
      letter-spacing: 0.5px;
    }

    .ProductDetails {
      margin-block-end: ${spacing.small};
      font-size: ${fontSizes.supersmall};
      letter-spacing: 0.5px;

      span {
        display: block;
        inline-size: 100%;
        color: ${colors.black};
        line-height: 1.5;
      }
    }

    .AmountInput {
      max-inline-size: ${spacing.large};
    }
  }
`;

export const ProductImage = styled(Link)`
  color: ${colors.black};
  display: block;
  aspect-ratio: 1 / 1;
  border: thin solid ${colors.border};
  position: relative;
  line-height: 0;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProductDetails = styled(Link)`
  color: ${colors.black};
  display: block;
  text-decoration: none;

  &:hover {
    color: ${colors.black};
  }

  > * + * {
    margin-block-start: ${spacing.small};
  }
`;

export const ProductName = styled.span`
  display: block;
  font-size: ${fontSizes.xsmall};
  letter-spacing: 0.5px;
`;

export const ProductSku = styled.span`
  display: block;
  font-size: ${fontSizes.supersmall};
  letter-spacing: 0.5px;
`;

export const CloseButton = styled.button`
  box-sizing: content-box;
  appearance: none;
  background: transparent;
  border: 0;
  display: flex;
  padding: none;
  margin: -2px 0 0;
  border-radius: 0;
  width: 16px;
  height: 16px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;

  svg {
    inline-size: 16px;
    block-size: 16px;
  }

  svg circle,
  svg path {
    transition: all 0.2s;
  }

  &:hover {
    svg circle {
      stroke: ${colors.gray2};
    }

    svg path {
      fill: ${colors.gray2};
    }
  }
`;
