import { useRef, useState } from 'react';

import Image from 'next/image';
import Slider from 'react-slick';
import { useKey } from 'react-use';

import { ProductAttribute } from '@hultafors/shared/types';

import { ImageSliderStyled } from './ImageSlider.styled';

const Arrow: React.FC<{
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}> = ({ className, onClick, children }) => (
  <button className={`${className}`} onClick={onClick}>
    {children}
  </button>
);

interface ImageSliderProps {
  images?: any[];
  productPage?: boolean;
  inModal?: boolean;
  productvideo?: ProductAttribute;
  className?: string;
  currentImageIndex?: number;
  toggleVideoModal?(): void;
  width?: number;
  height?: number;
  article?: boolean;
  environment?: boolean;
}

export const ImageSlider: React.FC<ImageSliderProps> = ({
  images = [],
  productPage,
  inModal,
  productvideo,
  article,
  className,
  currentImageIndex = 0,
  environment,
}) => {
  const slider = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useKey('ArrowLeft', () => {
    slider.current?.slickPrev();
  });
  useKey('ArrowRight', () => {
    slider.current?.slickNext();
  });

  const changedIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const SETTINGS = {
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    lazyLoad: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow className="Next" aria-label="Next" />,
    prevArrow: <Arrow className="Prev" aria-label="Previous" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
    initialSlide: currentImageIndex ?? 0,
    afterChange: changedIndex,
  };

  return (
    <ImageSliderStyled
      className={className}
      $article={article}
      $environment={environment}
    >
      <Slider
        {...SETTINGS}
        className={inModal ? 'inModal' : article ? 'article' : ''}
        ref={slider}
      >
        {images.map((item, index) => (
          <div
            key={`Carousel-${index}`}
            className={inModal ? 'ImgWrapper inModal' : 'ImgWrapper'}
          >
            <Image
              src={item.url}
              alt="product"
              className={productPage ? 'ProductImage' : ''}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(min-width: 1440px) 1440px, 100vw"
            />
            {item.text && (
              <div className={currentIndex == index ? 'Text' : 'Text hidden'}>
                <p>{item.text}</p>
              </div>
            )}
          </div>
        ))}
      </Slider>
      {images.length > 1 && (
        <div
          className={
            productPage
              ? 'Counter ProductPage'
              : article
                ? 'Counter Article'
                : inModal
                  ? 'Counter inModal'
                  : 'Counter'
          }
        >
          <span className="ActiveSlide">
            {currentIndex + 1}
            &nbsp;
          </span>
          /
          {' '}
          {productvideo ? images.length + 1 : images.length}
        </div>
      )}
    </ImageSliderStyled>
  );
};
