import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

export const StyledSmallContentPlug = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: ${spacing.regular};
`;

export const StyledNoImageDiv = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.gray5};
  margin-bottom: ${spacing.regular};
`;

export const StyledTextBoxTitle = styled(HTag)`
  margin-top: 0;
  margin-bottom: ${spacing.xsmall};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.small};
  }
`;
export const StyledTextBoxText = styled(Paragraph)`
  margin-top: 0;
  margin-bottom: ${spacing.small};
`;

export const StyledTextBoxCTA = styled(Paragraph)`
  margin: 0;
`;
