import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

export const CartActionsStyled = styled.div`
  margin: ${spacing.regular} 0 ${spacing.medium} 0;

  > * + * {
    margin-block-start: ${spacing.small};

    @media screen and (min-width: ${breakpoints.mediumMobile}) {
      margin-block-start: ${spacing.small};
    }
  }
`;
