import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useCart, useFavorites } from '@hultafors/hultafors/hooks';

import { Badge } from '../Badge/Badge';
import { Icon } from '../Icon/Icon';

import {
  CartIcon,
  FavoriteIcon,
  LanguageIcon,
  NavItemStyled,
  SearchIcon,
} from './NavItem.styled';

interface NavItemProps {
  isFavorite?: boolean;
  isCart?: boolean;
  isLanguage?: boolean;
  isSearch?: boolean;
  toggle?: () => void;
  inTopMenu?: boolean;
  label: string;
  itemsCount?: number;
  className?: string;
  link?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  toggle,
  isFavorite,
  isLanguage,
  isCart,
  isSearch,
  label = '',
  className,
}) => {
  const { cart } = useCart();
  const { favorites } = useFavorites();
  const { activeMarket } = useMarketPicker();

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return <Icon svg="language" />;
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }
  return (
    <NavItemStyled
      onClick={toggle}
      aria-label={label || ''}
      className={className}
    >
      {isFavorite && (
        <FavoriteIcon aria-hidden="true">
          <Icon svg="favorite" />
          {favorites.length > 0 && (
            <Badge tiny round>
              {favorites.length}
            </Badge>
          )}
        </FavoriteIcon>
      )}
      {isCart && (
        <CartIcon aria-hidden="true">
          <Icon svg="cart" />
          {cart.length > 0 && (
            <Badge tiny round red>
              {cart.length}
            </Badge>
          )}
        </CartIcon>
      )}

      {isLanguage && <LanguageIcon>{renderLanguageIcon()}</LanguageIcon>}

      {isSearch && (
        <SearchIcon>
          <Icon svg="search" />
        </SearchIcon>
      )}
    </NavItemStyled>
  );
};
