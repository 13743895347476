import { ImageBasicFragment, ImageFragment } from '@hultafors/hultafors/types';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { Markdown } from '../Markdown/Markdown';
import { VideoBlock } from '../VideoBlock/VideoBlock';

import { ArticleItemStyled } from './ArticleItem.styled';
interface ArticleItemProps {
  title?: string;
  intro?: string;
  text?: string;
  CTAText?: string;
  CTALink?: string;
  wideImage?: ImageFragment;
  image?: ImageFragment;
  videoUrl?: string;
  videoPreviewImage?: ImageBasicFragment;
  quote?: string;
  quoteName?: string;
  quoteProfession?: string;
  center?: boolean;
  fullWidth?: boolean;
}
export const ArticleItem: React.FC<ArticleItemProps> = ({
  title,
  intro,
  text,
  CTAText,
  CTALink,
  wideImage,
  image,
  videoUrl,
  videoPreviewImage,
  quote,
  quoteName,
  quoteProfession,
  center,
  fullWidth,
}) => {
  if (title || intro || text) {
    return (
      <ArticleItemStyled $center={center} $fullWidth={fullWidth}>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 10, start: 2 },
              { breakpoint: 'desktop', columns: 6, start: 3 },
              { breakpoint: 'desktopMedium', columns: 6, start: 4 },
            ]}
          >
            {title && <H2>{title}</H2>}
            {intro && (
              <Grid
                columns={[
                  { columns: 5 },
                  { breakpoint: 'mobileMax', columns: 10, start: 2 },
                  { breakpoint: 'desktop', columns: 6 },
                ]}
                className="IntroBlock"
              >
                <GridChild
                  columnSpan={[
                    { columns: 4 },
                    { breakpoint: 'mobileMax', columns: 8, start: 3 },
                    { breakpoint: 'desktop', columns: 5, start: 2 },
                  ]}
                >
                  <Markdown options={{ forceBlock: true }}>{intro}</Markdown>
                </GridChild>
              </Grid>
            )}
            {text && (
              <Markdown className="BodyText" options={{ forceBlock: true }}>
                {text ?? ''}
              </Markdown>
            )}
            {!!CTAText && !!CTALink && (
              <ArrowLink
                label={CTAText}
                url={CTALink}
                direction="forward"
                className="ArrowLinkBtn"
              />
            )}
          </GridChild>
        </Grid>
      </ArticleItemStyled>
    );
  }
  if (wideImage) {
    return (
      <ArticleItemStyled $center={center} $fullWidth>
        <img src={wideImage.url} alt={title} className="FullwidthImage" />
      </ArticleItemStyled>
    );
  }
  if (videoUrl) {
    return (
      <ArticleItemStyled $center={center}>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 8, start: 3 },
            ]}
          >
            <VideoBlock src={videoUrl} image={videoPreviewImage} />
          </GridChild>
        </Grid>
      </ArticleItemStyled>
    );
  }
  if (image) {
    return (
      <ArticleItemStyled $center={center}>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 12 },
              { breakpoint: 'desktop', columns: 8, start: 3 },
            ]}
          >
            <img src={image.url} alt={title} />
          </GridChild>
        </Grid>
      </ArticleItemStyled>
    );
  }
  if (quote) {
    return (
      <ArticleItemStyled $center={center}>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 5 },
              { breakpoint: 'mobileMax', columns: 10, start: 2 },
              { breakpoint: 'desktop', columns: 8, start: 3 },
              { breakpoint: 'desktopMedium', columns: 6, start: 4 },
            ]}
          >
            <Grid
              columns={[
                { columns: 6 },
                { breakpoint: 'largeMobile', columns: 8 },
                { breakpoint: 'mobileMax', columns: 6 },
              ]}
              columnGap={[
                { columnGap: 15 },
                { breakpoint: 'desktop', columnGap: 30 },
              ]}
              className="QuoteWrapper"
            >
              <GridChild
                columnSpan={[
                  { columns: 1 },
                  { breakpoint: 'largeMobile', columns: 1, start: 2 },
                  { breakpoint: 'mobileMax', columns: 1, start: 1 },
                ]}
              >
                <span className="QuoteDash" />
              </GridChild>
              <GridChild
                columnSpan={[
                  { columns: 5, start: 2 },
                  { breakpoint: 'largeMobile', columns: 5, start: 3 },
                  { breakpoint: 'mobileMax', columns: 5, start: 2 },
                ]}
              >
                <span className="Quote">{`"${quote}"`}</span>
                {quoteName && quoteProfession && (
                  <div className="Quoter">
                    <span className="Name">{quoteName}</span>
                    <span className="Title">{quoteProfession}</span>
                  </div>
                )}
              </GridChild>
            </Grid>
          </GridChild>
        </Grid>
      </ArticleItemStyled>
    );
  }
  return null;
};
