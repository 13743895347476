import { createContext } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings, FavoriteItem } from '@hultafors/shared/types';

export interface FavoritesContextValue {
  favorites: FavoriteItem[];
  toggleFavorite(product: FavoriteItem): void;
  clearFavorites(): void;
}

export const FavoritesContext = createContext<FavoritesContextValue | null>(
  null,
);

interface FavoritesProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
  settings,
}) => {
  const favorites = useLocalStorageValue<FavoriteItem[]>(
    `favorites-${settings?.market ?? 'com'}`,
    { initializeWithValue: false, defaultValue: [] },
  );

  const toggleFavorite = (product: FavoriteItem) => {
    favorites.set(
      favorites.value?.find(({ id }) => id === product.id)
        ? favorites.value?.filter(({ id }) => id !== product.id) || []
        : [...(favorites.value || []), product],
    );
  };

  const clearFavorites = () => {
    favorites.set([]);
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites: favorites.value || [],
        toggleFavorite,
        clearFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
