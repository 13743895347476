import { DoubleImageStyled } from './DoubleImage.styled';
const createImageUrl = (path: string) => `${path}?width=1440&format=jpg`;
interface DoubleImageProps {
  firstImage?: string;
  secondImage?: string;
}
export const DoubleImage: React.FC<DoubleImageProps> = ({
  firstImage,
  secondImage,
}) => {
  return (
    <DoubleImageStyled>
      {firstImage && <img alt="" src={createImageUrl(firstImage)} />}
      {secondImage && <img alt="" src={createImageUrl(secondImage)} />}
    </DoubleImageStyled>
  );
};
