import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';

export const AddToCartStyled = styled.div`
  display: flex;
  align-content: stretch;
  margin-bottom: ${spacing.small};

  button {
    flex-grow: 1;
  }
`;

export const AddToCartButton = styled(Button)`
  border: 0;
  background-color: ${colors.red};
  color: ${colors.white};
  transition: 0.2s;
  margin-left: ${spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.redHover};
  }

  &:disabled {
    color: ${colors.white};

    &:hover {
      color: ${colors.white};
      background-color: ${colors.red};
    }
  }

  svg {
    color: ${colors.white};
    margin-inline-start: 1rem;
  }
`;
