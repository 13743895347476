import { useState } from 'react';

import dynamic from 'next/dynamic';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { MenuItem } from '@hultafors/hultafors/types';

import { Navigation } from '../Navigation/Navigation';

const DesktopMenu = dynamic(() =>
  import('../DesktopMenu/DesktopMenu').then((mod) => mod.DesktopMenu),
);

const DynamicMobileMenu = dynamic(() =>
  import('../MobileMenu/MobileMenu').then((mod) => mod.MobileMenu),
);

const Favorites = dynamic(() =>
  import('../Favorites/Favorites').then((mod) => mod.Favorites),
);

const DynamicCart = dynamic(() =>
  import('../Cart/Cart').then((mod) => mod.Cart),
);

export const Nav = () => {
  const { settings, globalContent, menuData } = useGlobal();

  const defaultOpenSettings = {
    desktopMenu: false,
    mobileMenu: false,
    favorites: false,
    cart: false,
  };

  const [openDesktopMenuType, setOpenDesktopMenuType] = useState<string>('');
  const [isOpen, setIsOpen] = useState(defaultOpenSettings);
  const [dropDownMenuItem, setDropDownMenuItem] = useState<MenuItem>();
  const { toggle } = useMarketPicker();

  const toggleMobileMenu = () => {
    const open = !isOpen.mobileMenu;
    setIsOpen({ ...defaultOpenSettings, mobileMenu: open });
  };

  const toggleDesktopMenu = (type: string) => {
    let open = !isOpen.desktopMenu;
    if (openDesktopMenuType && type && openDesktopMenuType !== type) {
      open = true;
    } else if (openDesktopMenuType === type || !type || type === 'main') {
      open = false;
      type = '';
    }

    setOpenDesktopMenuType(type);
    const menuChildren = menuData.find((item) => item.title === type);
    setDropDownMenuItem(menuChildren);
    setIsOpen({ ...defaultOpenSettings, desktopMenu: open });
  };

  const toggleFavoritesMenu = () => {
    const open = !isOpen.favorites;
    setIsOpen({ ...defaultOpenSettings, favorites: open });
  };

  const toggleCartMenu = () => {
    const open = !isOpen.cart;
    setIsOpen({ ...defaultOpenSettings, cart: open });
  };

  function toggleMarketPicker() {
    toggle(true);
  }

  return (
    <nav data-test-id="Nav">
      <Navigation
        isOpen={isOpen.desktopMenu}
        globalContent={globalContent}
        menuData={menuData}
        settings={settings}
        openDesktopMenuType={openDesktopMenuType}
        toggleMobileMenu={toggleMobileMenu}
        toggleDesktopMenu={toggleDesktopMenu}
        toggleFavoritesMenu={toggleFavoritesMenu}
        toggleMarketPicker={toggleMarketPicker}
        toggleCartMenu={toggleCartMenu}
        shopLinkUrl={globalContent.shopLinkUrl}
        shopLinkLabel={globalContent.shopLinkLabel}
      />

      {isOpen.desktopMenu && (
        <DesktopMenu
          isOpen={isOpen.desktopMenu}
          type={openDesktopMenuType}
          dropDownMenuItem={dropDownMenuItem}
          toggleDesktopMenu={toggleDesktopMenu}
          searchPlaceholder={globalContent.searchBoxPlaceholder ?? ''}
          badgeText={globalContent.newLabel ?? ''}
          premiumText={globalContent.premiumLabel ?? ''}
          modelsText={globalContent.modelsLabel ?? ''}
          showMoreText={globalContent.showMoreLabel ?? ''}
          noSearchResult={globalContent.noSearchResult ?? ''}
          clearLabel={globalContent.clearLabel ?? ''}
          searchLabel={globalContent.searchLabel ?? ''}
        />
      )}

      {isOpen.mobileMenu && (
        <DynamicMobileMenu
          isOpen={isOpen.mobileMenu}
          toggleMenu={toggleMobileMenu}
          headerText={globalContent.menuLabel ?? ''}
          menuItems={menuData ?? []}
          partnerPortalUrl={globalContent.partnerPortalUrl ?? ''}
          shopLinkUrl={globalContent.shopLinkUrl ?? ''}
          shopLinkLabel={globalContent.shopLinkLabel ?? ''}
          badgeText={globalContent.newLabel ?? ''}
          modelsText={globalContent.modelsLabel ?? ''}
          showMoreText={globalContent.showMoreLabel ?? ''}
          noSearchResult={globalContent.noSearchResult ?? ''}
          changeLanguageText={globalContent.changeLanguageText ?? ''}
          searchPlaceholder={globalContent.searchBoxPlaceholder ?? ''}
          clearLabel={globalContent.clearLabel ?? ''}
          searchLabel={globalContent.searchLabel ?? ''}
          toggleCartMenu={toggleCartMenu}
        />
      )}

      {isOpen.favorites && (
        <Favorites
          isOpen={isOpen.favorites}
          toggleMenu={toggleFavoritesMenu}
          header={globalContent.favoritesHeader ?? ''}
          clearText={globalContent.clearLabel ?? ''}
          badgeText={globalContent.newLabel ?? ''}
          modelText={globalContent.modelsLabel ?? ''}
          isSmall
        />
      )}

      {isOpen.cart && (
        <DynamicCart
          isOpen={isOpen.cart}
          toggleMenu={toggleCartMenu}
          header={globalContent.shoppingListHeader ?? ''}
          clearText={globalContent.clearLabel ?? ''}
          sendCtaButton={globalContent.sendCtaButton ?? ''}
          shoppingListText={globalContent.shoppingListHeader ?? ''}
          skuLabel={globalContent.skuLabel ?? ''}
          closeLabel={globalContent.closeLabel ?? ''}
        />
      )}
    </nav>
  );
};
