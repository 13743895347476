import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const ListLink = styled(Link)`
  text-decoration-line: none;
  margin-bottom: ${spacing.block};
`;

export const ListBlockStyled = styled.div``;

export const ImageWrapper = styled.div`
  position: relative;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ListImage = styled.div`
  position: relative;
  display: flex;
  aspect-ratio: 1 / 1;
  border: thin solid ${colors.border};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ListArticleTitle = styled.div`
  padding: ${spacing.xsmall} 0 0 0;
`;

export const ListArticleLabel = styled.span`
  display: block;
  font-size: ${fontSizes.small};
  letter-spacing: 0.03125rem;

  line-height: 1.285rem;
  font-weight: ${fontWeights.normal};
  margin-bottom: ${spacing.xsmall};
  color: ${colors.black};
  transition: 0.2s ease;

  &:hover {
    color: ${colors.gray1};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    line-height: 1.175rem;
  }
`;

export const Empty = styled.p`
  width: 100%;
  text-align: center;
`;

export const Error = styled.p`
  width: 100%;
  text-align: center;
`;
