import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

const desktopMenuHeight = 500;

interface SlideInStyled {
  $fromTop?: boolean;
  $fromLeft?: boolean;
  $small?: boolean;
  $padContent?: boolean;
}

export const SlideInStyled = styled.div<SlideInStyled>`
  .SlideInBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    overflow-x: hidden;
    background-color: ${colors.overlayTransparent};
    opacity: 0;
    transition: opacity 0.3s, z-index 0.1s 0.3s;

    &.active {
      z-index: ${({ $fromTop }) => ($fromTop ? '9' : '10')};
      opacity: 1;
      transition: opacity 0.3s, z-index 0.1s;
    }
  }

  .SlideInInside {
    position: absolute;
    top: 0;
    right: ${({ $fromLeft }) => ($fromLeft ? 'auto' : 0)};
    left: ${({ $fromLeft }) => ($fromLeft ? 0 : 'auto')};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 12;
    box-sizing: border-box;
    background-color: ${colors.white};
    transform: ${({ $fromTop, $fromLeft }) =>
      $fromTop
        ? 'translateY(-110%)'
        : $fromLeft
        ? 'translateX(-110%)'
        : 'translateX(110%)'};
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: ${({ $fromTop, $small }) =>
        $fromTop && $small
          ? 'translateY(-61%)'
          : $fromTop
          ? 'translateY(0%)'
          : 'translateX(0%)'};
      transition: transform 0.3s ease-in-out;
    }

    @media screen and (min-width: ${breakpoints.bigMobile}) {
      width: 100%;
      max-width: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
      max-height: ${({ $fromTop }) =>
        $fromTop ? desktopMenuHeight + 'px' : 'none'};
    }

    .SlideInHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      min-height: 56px;
      padding: 0 ${spacing.pagePaddingMobile};
      border-bottom: thin solid ${colors.gray4};

      .HeaderText {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: ${fontSizes.body};
          font-weight: ${fontWeights.normal};
          letter-spacing: 0.5px;

          @media screen and (min-width: ${breakpoints.mediumMobile}) {
            font-size: ${fontSizes.h5};
          }
        }

        .HeaderIcon {
          appearance: none;
          border: 0;
          border-radius: 0;
          padding: 0;
          background: transparent;
          width: ${spacing.regular};
          height: ${spacing.regular};
          display: inline-flex;
          margin-right: ${spacing.xsmall};

          @media screen and (min-width: ${breakpoints.mediumMobile}) {
            margin-right: ${spacing.small};
          }
        }
      }

      .SlideInExit {
        display: flex;
        align-items: center;

        span {
          font-size: ${fontSizes.small};
          letter-spacing: 0.03125rem; /* 0.5px */
        }
      }

      .ClearBtn {
        appearance: none;
        border: 0;
        border-radius: 0;
        padding: 0;
        background: transparent;
        display: flex;
        align-items: center;
        margin-right: ${spacing.small};
        cursor: pointer;
        transition: 0.2s ease;

        @media screen and (min-width: ${breakpoints.mediumMobile}) {
          margin-right: ${spacing.regular};
        }

        svg {
          width: ${spacing.small};
          height: ${spacing.small};
          margin-left: ${spacing.xsmall};

          path {
            transition: 0.2s ease;
          }
        }

        &:hover {
          color: ${colors.gray1};

          svg path {
            fill: ${colors.gray1};
          }
        }
      }

      .CloseBtn {
        appearance: none;
        border: 0;
        border-radius: 0;
        padding: 0;
        background: transparent;
        display: flex;
        width: ${spacing.regular};
        height: 100%;
        cursor: pointer;
      }

      .SlideInBody {
        padding: ${({ $padContent }) =>
          $padContent
            ? `${spacing.regular} ${spacing.pagePaddingMobile} ${spacing.medium} ${spacing.pagePaddingMobile}`
            : ''};
        overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'scroll')};
        height: ${({ $fromTop }) => ($fromTop ? 'auto' : '100%')};
        width: 100%;
        box-sizing: border-box;

        &.isCart {
          padding: ${spacing.pagePaddingMobile} ${spacing.pagePaddingMobile}
            150px ${spacing.pagePaddingMobile};
        }
      }
    }
  }
`;

export const ExternalLinksContainer = styled.div`
  position: absolute;
  right: ${spacing.pagePaddingMobile};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  div {
    display: flex;
    height: 100%;
    padding: ${spacing.small};
    border-left: thin solid ${colors.border};
  }
  div:last-child {
    padding-right: 0;
  }
`;

export const ExternalLink = styled(Link)`
  color: ${colors.black};
  display: flex;
  align-items: center;
  letter-spacing: 0.15rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.medium};

  svg {
    margin-left: ${spacing.xsmall};
  }
`;

export const LanguageContainer = styled.div``;
