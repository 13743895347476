import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface SearchResultHeroStyledProps {
  $menuHeight?: number;
}

export const SearchResultHeroStyled = styled.div<SearchResultHeroStyledProps>`
  margin-top: ${({ $menuHeight }) =>
    $menuHeight ? `${$menuHeight}px` : 'inherit'};
  padding: ${spacing.medium} 0 ${spacing.regular} 0;
  margin-bottom: ${spacing.block};

  .SearchField {
    block-size: ${spacing.block};
    border-block-end: thin solid ${colors.black};

    input {
      padding: 0;
    }

    button {
      block-size: ${spacing.regular};
    }
  }

  .ResultText {
    position: absolute;
  }
`;
