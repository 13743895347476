function setCorrectProtocol(path: any) {
  if (path.match(/http:\/\/|https:\/\//gi)) {
    // |:\/\/|\/\/
    return path.replace(/http:\/\/|https:\/\//gi, ''); // |:\/\/|\/\/
  }
  return path;
}

export const createImageUrl = (
  sourceImage: any,
  format: any,
  size: any,
  qualityLevel: any,
) => {
  if (sourceImage !== undefined) {
    const protocol = 'https://';
    const protocollessPath = setCorrectProtocol(sourceImage);

    if (!sourceImage.includes('images.ctfassets.net')) {
      const pathMinusFormat = protocollessPath.substring(
        0,
        protocollessPath.length - 4,
      ); // Take the entire path except file ending
      return `${pathMinusFormat}_${size}.${format}`;
    } else if (sourceImage.includes('images.ctfassets.net')) {
      const windowWidth
        = typeof window !== 'undefined' ? window.innerWidth : 768;
      let quality = 1;
      let newSize = size;

      if (qualityLevel) {
        if (windowWidth <= 1024) {
          newSize = 1024;
          quality = qualityLevel * 0.7;
        } else {
          quality = qualityLevel;
        }
      }

      return `${protocol}${protocollessPath}?w=${newSize}&fm=${format}&q=${quality}`;
    }
    return false;
  }
  return false;
};
