import { ArticleIntroFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';

import { IntroTextBlockStyled } from './IntroTextBlock.styled';

interface IntroTextBlockProps extends ArticleIntroFragment {
  indexKey?: string;
}

export const IntroTextBlock: React.FC<IntroTextBlockProps> = ({
  indexKey,
  intro,
}) => {
  return (
    <IntroTextBlockStyled>
      <Grid
        key={indexKey}
        columns={[
          { columns: 5 },
          { breakpoint: 'mobileMax', columns: 10, start: 2 },
          { breakpoint: 'desktop', columns: 6 },
        ]}
        className="IntroBlock"
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 5, start: 2 },
          ]}
        >
          <Markdown variant="dark" options={{ forceBlock: true }}>
            {intro ?? ''}
          </Markdown>
        </GridChild>
      </Grid>
    </IntroTextBlockStyled>
  );
};
