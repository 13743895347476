import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const ModalStyled = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.white};
  z-index: 11;
  opacity: 0;
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  transition: opacity 0.4s ease;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'initial' : 'none')};

  .ModalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    inline-size: 100%;
    block-size: 100%;
    opacity: 0;
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    transition: opacity 0.4s ease 0.6s;
  }

  .CloseButton {
    position: absolute;
    inline-size: ${spacing.block};
    block-size: ${spacing.block};
    inset-block-start: ${spacing.small};
    inset-inline-end: ${spacing.regular};
    padding: 0;
    background: none;
    border: 0;
    z-index: 1;
    cursor: pointer;

    img {
      inline-size: ${spacing.regular};
      block-size: ${spacing.regular};
    }
  }
`;
