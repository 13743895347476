import { ProductModelData } from '@hultafors/shared/types';

import { Icon } from '../Icon/Icon';
import { ProductModel } from '../ProductModel/ProductModel';
import { SlideInNew } from '../SlideInNew/SlideInNew';

import { ToggleButton } from './ModelSelector.styled';
interface ModelSelectorProps {
  toggle(): void;
  selectModel(model: ProductModelData): void;
  models: ProductModelData[];
  model?: ProductModelData;
  isOpen?: boolean;
  headerText?: string;
  chooseText?: string;
  modelsLabel?: string;
  skuLabel: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ModelSelector: React.FC<ModelSelectorProps> = ({
  toggle,
  onClick,
  isOpen,
  headerText,
  chooseText = 'Choose',
  selectModel,
  skuLabel,
  models = [],
  model,
  modelsLabel,
}) => (
  <>
    <ToggleButton fullWidth onClick={onClick}>
      {model
        ? (
          <>
            {model.name}
            {' '}
            <Icon svg="edit" />
          </>
          )
        : (
          <>
            {modelsLabel}
            {' '}
            (
            {models.length}
            )
            <Icon svg="edit" />
          </>
          )}
    </ToggleButton>
    <SlideInNew
      toggle={toggle}
      isOpen={!!isOpen}
      headerText={headerText}
      fromLeft={false}
    >
      {models.map((model, index) => (
        <ProductModel
          key={`PM-${index}`}
          model={model}
          chooseText={chooseText}
          selectModel={selectModel}
          skuLabel={skuLabel}
        />
      ))}
    </SlideInNew>
  </>
);
