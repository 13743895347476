import styled from 'styled-components';

import { colors } from '@hultafors/hultafors/tokens';

export const GaugeStyled = styled.div`
  width: 100%;
  display: flex;

  div {
    block-size: 2px;
  }

  .SoFar {
    background-color: ${colors.red};
  }

  .ToGo {
    background-color: ${colors.black};
  }
`;
