import {
  Slider,
  StyledInput,
  StyledToggleSwitch,
  StyledToggleSwitchContainer,
  StyledToggleSwitchLabel,
} from './ToggleSwitch.styled';

interface ToggleSwitchProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
  disabled,
  label,
}) => (
  <StyledToggleSwitchContainer>
    <StyledToggleSwitch>
      <StyledInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Slider className="slider" />
    </StyledToggleSwitch>
    {label && (
      <StyledToggleSwitchLabel $disabled={disabled}>
        {label}
      </StyledToggleSwitchLabel>
    )}
  </StyledToggleSwitchContainer>
);
