import { HultaforsProduct } from '@hultafors/hultafors/types';

import { EmptyProducts } from '../EmptyProducts/EmptyProducts';
import { Product } from '../Product/Product';

interface ProductsProps {
  products: HultaforsProduct[];
  badgeText: string;
  fromText?: string;
  premiumText?: string;
  modelText?: string;
  loading: boolean;
  numberOfItems: number;
  categorySlug?: string;
}
export const Products: React.FC<ProductsProps> = ({
  products,
  badgeText,
  modelText,
  loading,
  numberOfItems = 12,
  categorySlug,
}) => {
  return (
    <>
      {loading
        ? (
          <EmptyProducts pageSize={numberOfItems} />
          )
        : (
          <>
            {products.map((product, i) => (
              <Product
                key={`p-${i}`}
                product={product}
                badgeText={badgeText}
                modelText={modelText}
                categorySlug={categorySlug}
              />
            ))}
          </>
          )}
    </>
  );
};
