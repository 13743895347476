import { useState } from 'react';

import { ImageBasicFragment } from '@hultafors/hultafors/types';

import { Dialog } from '../Dialog/Dialog';

import { SubscribeButton, SubscribeText } from './SubscribeArea.styled';

interface SubscribeAreaProps {
  subscribeInfoText: string;
  subscribeCtaButton: string;
  userEmailFormUrl: string;
  userEmailFormImage?: ImageBasicFragment;
}

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeCtaButton,
  userEmailFormUrl,
  userEmailFormImage,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <>
      <SubscribeText>{subscribeInfoText}</SubscribeText>
      <SubscribeButton onClick={toggleDialog} className="White">
        {subscribeCtaButton}
      </SubscribeButton>

      {isDialogOpen && (
        <Dialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
        />
      )}
    </>
  );
};
