import Link from 'next/link';

import { StoreBrand, StoreCoords } from '@hultafors/shared/types';

import { Icon } from '../Icon/Icon';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  ContactVisitSite,
  StoreItemStyled,
  StyledArrowIcon,
} from './StoreItem.styled';

interface StoreItemProps {
  label: string;
  subLabel: string;
  coordinates: StoreCoords;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  mapLink?: string;
  view_on_map?: string;
  emailAddress?: string;
  phone?: string;
  storeSells: StoreBrand[];
  this_store_also_sells?: string;
  website?: string;
  visit_web_site?: string;
  local_web?: string;
  openingHours?: string;
  openingHoursLabel?: string;
  $forceCollapsible?: boolean;
}

export const StoreItem: React.FC<StoreItemProps> = (props) => {
  const {
    address,
    zipCode,
    city,
    country,
    mapLink,
    view_on_map,
    emailAddress,
    phone,
    storeSells,
    this_store_also_sells,
    website,
    visit_web_site,
    local_web,
    openingHoursLabel,
    openingHours,
  } = props;

  return (
    <StoreItemStyled>
      <Paragraph className="Address">
        {address && (
          <>
            {address}
            <br />
          </>
        )}
        {zipCode && zipCode}
        {' '}
        {city && city}
        <br />
        {country && country}
      </Paragraph>
      {openingHours && (
        <Paragraph className="ViewOnMap">
          {`${openingHoursLabel}:`}
          <br />
          {openingHours}
        </Paragraph>
      )}
      {view_on_map && mapLink && (
        <Paragraph className="ViewOnMap">
          <Link
            href={mapLink || ''}
            target="_blank"
            rel="noopener noreferrer"
            className="LeftIconLink"
          >
            <Icon svg="placeTag" width={16} />
            {view_on_map}
          </Link>
        </Paragraph>
      )}

      {emailAddress || phone
        ? (
          <div className="Contact EmailAndPhoneWrapper">
            {emailAddress && (
              <div className="Email">
                <Link href={`mailto:${emailAddress}`} className="LeftIconLink">
                  <Icon svg="mail" width={16} />
                  {emailAddress}
                </Link>
              </div>
            )}
            {phone && (
              <div className="Phone">
                <Link href={`tel:${phone}`} className="LeftIconLink">
                  <Icon svg="phone" width={16} />
                  {phone}
                </Link>
              </div>
            )}
          </div>
          )
        : (
            ''
          )}

      {storeSells && storeSells.length > 0 && (
        <div className="Contact StoreSells">
          <div className="StoresWrapper">
            <label className="storeText">{this_store_also_sells}</label>
            <div className="Stores">
              {storeSells.map((brand, i) => {
                return (
                  <div key={`Brand-${i}`}>
                    {brand.url
                      ? (
                        <Link
                          href={brand.url || ''}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {brand.name}
                        </Link>
                        )
                      : (
                        <>{brand.name}</>
                        )}
                    {i < storeSells.length - 1 && (
                      <div className="Separator">|</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {website && (
        <ContactVisitSite>
          <Link href={website || ''} target="_blank" rel="noopener noreferrer">
            {visit_web_site && visit_web_site}
            {visit_web_site && <Icon svg="externalLink" width={16} />}
            {local_web && local_web}
            {local_web && <StyledArrowIcon svg="chevron" />}
          </Link>
        </ContactVisitSite>
      )}
    </StoreItemStyled>
  );
};
