import { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  GridColumnGapProp,
  GridColumnsProp,
  GridRowGapProp,
  GridStyled,
} from './Grid.styled';

interface GridProps {
  children?: React.ReactNode;
  className?: string;
  columnGap?: GridColumnGapProp;
  rowGap?: GridRowGapProp;
  columns?: GridColumnsProp;
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  minColumnWidth?: string;
}

export const Grid: React.FC<GridProps> = ({
  children,
  align = 'stretch',
  columnGap = [{ columnGap: 40 }, { breakpoint: 'mobileMax', columnGap: 20 }],
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  margin = '0 auto',
  maxWidth = `${breakpoints.maxPageWidth}`,
  rowGap = 0,
  center,
  minColumnWidth,
  className,
}) => (
  <GridStyled
    className={`Grid ${className}`}
    $align={align}
    $columnGap={columnGap}
    $columns={columns}
    $margin={margin}
    $maxWidth={maxWidth}
    $rowGap={rowGap}
    $center={center}
    $minColumnWidth={minColumnWidth}
  >
    {children}
  </GridStyled>
);
