import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

interface ArticleItemStyledProps {
  $center?: boolean;
  $fullWidth?: boolean;
}

export const ArticleItemStyled = styled.div<ArticleItemStyledProps>`
  margin: ${spacing.medium} 0;
  padding: ${({ $fullWidth }) =>
    $fullWidth ? '0' : '0 ' + spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
    padding: ${({ $fullWidth }) =>
      $fullWidth ? '0' : '0 ' + spacing.pagePadding};
  }

  h2 {
    margin: 0;
    margin-block-end: ${spacing.regular};
    font-size: ${fontSizes.h2mobile};
    line-height: ${lineHeights.h2mobile};
    font-weight: ${fontWeights.normal};
    text-align: ${({ $center }) => ($center ? 'center' : '')};

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.h2};
      line-height: ${lineHeights.h2};
    }
  }

  h3 {
    font-size: ${fontSizes.h4};
    font-weight: ${fontWeights.normal};
    line-height: ${lineHeights.h4};
    margin: 0;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.h3};
      line-height: ${lineHeights.h3};
    }
  }

  h4 {
    font-size: ${fontSizes.h4};
    line-height: ${lineHeights.h4};
    font-weight: ${fontWeights.normal};
    margin: 0;
  }

  div > h3,
  div > h4 {
    margin-block-start: ${spacing.block};
  }

  p + h2,
  h2 + .IntroBlock {
    margin-block-start: ${spacing.medium};
  }

  h2 + .BodyText {
    margin-block-start: ${spacing.small};
  }

  p {
    font-size: ${fontSizes.small};
    line-height: 24px;
    margin-block-end: 12px;
    white-space: pre-wrap;

    @media screen and (min-width: ${breakpoints.desktop}) {
      font-size: ${fontSizes.body};
      line-height: 26px;
    }

    strong {
      font-weight: ${fontWeights.medium};
    }
  }

  p:last-of-type {
    margin-block-end: 0;
  }

  .IntroBlock p {
    font-size: ${fontSizes.h4};
  }

  .IntroBlock + p,
  .IntroBlock + div {
    margin-block-start: ${spacing.medium};
  }

  .IntroBlock p:first-of-type {
    margin-block-start: 0;
  }

  .BodyText *:first-child {
    margin-block-start: 0;
  }

  .FullwidthImage {
    inline-size: 100%;
  }

  .QuoteWrapper {
    .QuoteDash {
      display: block;
      block-size: 1px;
      inline-size: 100%;
      min-inline-size: 28px;
      background-color: ${colors.red};
      margin-block-start: ${spacing.small};

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin-block-start: ${spacing.regular};
      }
    }

    .Quote {
      font-size: ${fontSizes.h4};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.h2};
      }
    }

    .Quoter {
      margin: ${spacing.small} 0 0 0;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        margin: ${spacing.regular} 0 0 0;
      }

      span {
        font-size: ${fontSizes.xsmall};
        line-height: ${lineHeights.h4};

        @media screen and (min-width: ${breakpoints.mobileMax}) {
          font-size: ${fontSizes.small};
        }
      }

      .Name {
        font-weight: ${fontWeights.medium};
      }

      .Title {
        position: relative;
        margin-inline-start: ${spacing.regular};
        color: ${colors.gray2};

        &::before {
          content: '';
          position: absolute;
          inset-block-start: 8px;
          inset-inline-start: -${spacing.small};
          block-size: 1px;
          inline-size: 10px;
          display: block;
          background-color: ${colors.gray2};
          z-index: -1;
        }
      }
    }
  }

  .ArrowLinkBtn {
    margin-block-start: ${spacing.regular};
  }
`;
