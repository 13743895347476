import dynamic from 'next/dynamic';

import { Store } from '@hultafors/shared/types';

import { FindRetailersListStyled } from './FindRetailersList.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion),
);
const StoreItem = dynamic(() =>
  import('../StoreItem/StoreItem').then((mod) => mod.StoreItem),
);

interface FindRetailersListProps {
  stores: Store[];
  selectItem(id?: string): void;
  selectedItem?: Store;
  viewOnMap: string;
  storeAlsoSells: string;
  visitWebsite: string;
  openingHours: string;
}

export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  selectItem,
  stores,
  ...props
}) => {
  const keyPrefix = 'Store';

  function storeMapper(item: Store) {
    if (!item.data) {
      return null;
    }
    return (
      <StoreItem
        coordinates={{
          longitude: parseFloat(item.data.longitude || ''),
          latitude: parseFloat(item.data.latitude || ''),
        }}
        label={item.data.companyName || ''}
        subLabel={item.data.city || ''}
        key={`${keyPrefix}-${item.id}`}
        address={item.data.address1 || ''}
        zipCode={item.data.zipCode || ''}
        city={item.data.city || ''}
        country={item.data.country || ''}
        mapLink={item.mapLink}
        view_on_map={props.viewOnMap}
        emailAddress={item.data.emailAddress || ''}
        phone={item.data.phone || ''}
        storeSells={item.storeSells}
        this_store_also_sells={props.storeAlsoSells}
        website={item.data.webSite || ''}
        visit_web_site={props.visitWebsite}
        openingHours={item.data.openingHours || ''}
        openingHoursLabel={props.openingHours}
        $forceCollapsible
      />
    );
  }
  return (
    <FindRetailersListStyled>
      {stores.length > 0 && (
        <Accordion
          selectItem={selectItem}
          selectedItemId={props.selectedItem?.id || ''}
          $keyPrefix={keyPrefix}
          $noPadding
        >
          {stores.map(storeMapper)}
          <span />
        </Accordion>
      )}
    </FindRetailersListStyled>
  );
};
