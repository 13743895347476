import Link from 'next/link';
import styled, { css } from 'styled-components';

import { colors, fontWeights, spacing } from '@hultafors/hultafors/tokens';

interface ButtonLinkStyledProps {
  $disabled?: boolean;
  $inverted?: boolean;
  $center?: boolean;
}

export const ButtonLinkStyled = styled(Link)<ButtonLinkStyledProps>`
  appearance: none;
  width: auto;
  display: inline-flex;
  align-items: center;
  height: ${spacing.medium};
  padding: 0 ${spacing.small};
  box-sizing: border-box;
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  color: ${colors.white};
  letter-spacing: 3px;
  cursor: pointer;
  transition: 0.2s;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background-color: ${colors.linkhover};
    border-color: ${colors.linkhover};
  }

  &.Red {
    border: thin solid ${colors.red};
    background-color: ${colors.red};
  }

  &.Black {
    border: thin solid ${colors.black};
    background-color: ${colors.black};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.gray1};
      border: thin solid ${colors.gray1};
    }
  }

  &.Outline {
    background-color: transparent;
    border: thin solid ${colors.black};
    color: ${colors.black};

    &:hover {
      background-color: ${colors.black};
      color: ${colors.white};
    }
  }

  ${({ $inverted }) =>
    $inverted
    && css`
      background-color: transparent;
      border: thin solid ${colors.white};
      color: ${colors.white};

      &:link,
      &:visited {
        color: ${colors.white};
      }

      &:hover {
        background-color: ${colors.white};
        color: ${colors.black};
      }
    `}

  ${({ $center }) =>
    $center
    && css`
      text-align: center;
    `}


  &:disabled {
    cursor: not-allowed;
    border: 0;
    color: ${colors.gray1};
    background-color: ${colors.red};
    transition: 0.2s;
    opacity: 0.5;

    svg {
      opacity: 0.3;
    }
  }
`;
