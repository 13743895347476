import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

// Hack for build dependencies
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import {
  findRetailersMode,
  useFindRetailers,
  useOnlineStores,
} from '@hultafors/shared/api';
import { Store, StoreCoords } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { FindRetailersPageFragment } from '@hultafors/hultafors/types';

import { FindRetailersList } from '../FindRetailersList/FindRetailersList';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H4 } from '../H4/H4';
import { Loader } from '../Loader/Loader';
import { OnlineStoreList } from '../OnlineStoreList/OnlineStoreList';
import { RetailersAdditionalSelections } from '../RetailersAdditionalSelections/RetailersAdditionalSelections';
import { RetailerTabs } from '../RetailerTabs/RetailerTabs';
import { SearchInput } from '../SearchInput/SearchInput';
import { Section } from '../Section/Section';

const ErrorBoundary = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.ErrorBoundary),
);

const Square = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Square),
);

const Map = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.Map),
  {
    ssr: false,
  },
);

const DEFAULT_RADIUS = 50;

const TABS = {
  search: 0,
  onlineStores: 1,
};

interface FindRetailersPageContentProps {
  content: FindRetailersPageFragment;
}

export const FindRetailersPageContent: React.FC<
  FindRetailersPageContentProps
> = ({ content }) => {
  const { settings, globalContent, siteLocale } = useGlobal();
  const {
    term,
    setTerm,
    international,
    setInternational,
    radius,
    setRadius,
    mode,
    stores,
    loading,
    clear,
    setCoords,
    coords,
    geoLoading,
  } = useFindRetailers({
    defaultRadius: DEFAULT_RADIUS,
    radiusOptions: content.distanceFilter.map(
      ({ radiusValue }): number => radiusValue,
    ) || [DEFAULT_RADIUS],
    defaultCoords: siteLocale.defaultCoordinates as StoreCoords,
  });
  const { stores: onlineStores } = useOnlineStores();

  const [tab, setTab] = useState<number>(0);
  const [search, setSearch] = useState<string>(term || '');

  const [selectedItem, setSelectedItem] = useState<Store | undefined>(
    undefined,
  );

  const [displaySearchMessage, setDisplaySearchMessage] = useState('');

  useEffect(() => {
    if (loading) {
      setDisplaySearchMessage(content.searchingLabel || '');
    } else {
      switch (mode) {
        case findRetailersMode.findRetailersByCoord:
          setDisplaySearchMessage(
            stores.length
              ? `${stores.length} ${content.retailersLocationResultLabel || ''}`
              : stores
                ? content.noResultForLocationLabel || ''
                : content.searchError || '',
          );
          break;
        case findRetailersMode.findRetailers:
          setDisplaySearchMessage(
            term && stores?.length
              ? `${stores.length} ${
                  globalContent.matchesForLabel?.charAt(0).toLocaleLowerCase()
                  + (globalContent.matchesForLabel?.slice(1) || '')
                } "${search}"`
              : term
                ? `${content.noResultForText || ''} "${search}"`
                : content.searchError || '',
          );
          break;
      }
    }
  }, [stores, loading]);

  const onChangeTerm: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setSearch('');
    clear();
  };

  const onSubmitSearch: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setTerm(search);
  };

  const onChangeRadius: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    setRadius(parseInt(event.target.value.replace(' km', ''), 10));
  };

  const onChangeInternational: React.ChangeEventHandler<
    HTMLInputElement
  > = () => {
    setInternational(!international);
  };

  const selectItem = (id?: string) => {
    if (id) {
      setSelectedItem(stores?.find((x) => x.id === id) || selectedItem);
    }
  };

  const mapPositionChanged = async (latitude: number, longitude: number) => {
    setCoords({ latitude, longitude });
    setTerm('');
  };
  return (
    <>
      <RetailerTabs
        active={tab}
        setActive={setTab}
        tabs={[
          content.retailersLabel || 'Retailers',
          content.onlineStoresLabel || 'Online stores',
        ]}
      />
      <ErrorBoundary>
        <Section noMarginTop hide={tab !== TABS.search}>
          <Grid>
            <GridChild
              columnSpan={[
                { columns: 6 },
                { breakpoint: 'mobileMax', columns: 6, start: 4 },
              ]}
            >
              <SearchInput
                name="findRetailersInput"
                onChange={onChangeTerm}
                value={search}
                placeholder={content.searchBarPlaceholder || ''}
                onSubmit={onSubmitSearch}
                clear={clearSearch}
                displaySearchMessage={displaySearchMessage}
                clearLabel={globalContent.clearLabel || ''}
                searchLabel={globalContent.searchLabel || ''}
              />
              <RetailersAdditionalSelections
                expandable={true}
                international={international}
                showInternational={settings?.market !== 'com'}
                internationalLabel={content.otherCountriesIncludedText || ''}
                onChangeInternational={onChangeInternational}
                radius={radius || DEFAULT_RADIUS}
                radiusLabel={content.radiusHelpText || ''}
                radiusOptions={content.distanceFilter.map(
                  ({ radiusValue }) => `${radiusValue} km`,
                )}
                onChangeRadius={onChangeRadius}
                loading={loading}
              />
            </GridChild>
          </Grid>
        </Section>
        <Section
          className="ZeroTop MoreMarginBottom"
          hide={tab !== TABS.search}
        >
          <Grid columnGap="small">
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 6 },
                { breakpoint: 'desktopSmall', columns: 5 },
              ]}
            >
              {loading
                ? (
                  <Square>
                    <Loader small />
                  </Square>
                  )
                : (
                  <FindRetailersList
                    stores={stores || []}
                    selectItem={selectItem}
                    selectedItem={selectedItem}
                    viewOnMap={content.viewOnMapLabel || ''}
                    storeAlsoSells={content.storeAlsoSellsText || ''}
                    visitWebsite={content.visitWebsiteLabel || ''}
                    openingHours={content.openingHoursLabel || ''}
                  />
                  )}
            </GridChild>
            <GridChild
              padding="0 0 0 0px"
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 6, start: 7 },
              ]}
            >
              <Map
                center={coords}
                stores={stores}
                viewOnMap={content.viewOnMapLabel || ''}
                visitWebsiteLabel={content.visitWebsiteLabel || ''}
                sticky
              />
            </GridChild>
          </Grid>
        </Section>

        <Section
          style={{ minHeight: '15vh' }}
          hide={tab !== TABS.onlineStores}
          noMarginTop
          moreMarginBottom
        >
          {loading
            ? (
              <Loader small />
              )
            : (
              <Grid
                columns={[
                  { columns: 4 },
                  { breakpoint: 'largeMobile', columns: 12 },
                ]}
              >
                <GridChild
                  columnSpan={[
                    { columns: 4 },
                    { breakpoint: 'largeMobile', columns: 8, start: 3 },
                    { breakpoint: 'desktop', columns: 4, start: 5 },
                  ]}
                >
                  {onlineStores.length > 0
                    ? (
                      <OnlineStoreList stores={onlineStores} />
                      )
                    : (
                      <H4 $center>{content.noOnlineStoresFound}</H4>
                      )}
                </GridChild>
              </Grid>
              )}
        </Section>
      </ErrorBoundary>
    </>
  );
};
