import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/hultafors/tokens';

interface GridColumnGap {
  breakpoint?: Breakpoint;
  columnGap: number | string;
}

interface GridColumns {
  breakpoint?: Breakpoint;
  columns: number;
  start?: number;
}

export type GridColumnGapProp = number | Spacing | GridColumnGap[];
export type GridRowGapProp = number | Spacing;
export type GridColumnsProp = number | 'auto-fit' | 'auto-fill' | GridColumns[];

interface GridStyledProps {
  $columnGap?: GridColumnGapProp;
  $rowGap?: GridRowGapProp;
  $columns?: GridColumnsProp;
  $align?: CSSProperties['alignItems'];
  $margin?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $center?: boolean;
  $minColumnWidth?: string;
}

function getColumnGapMapper({ columnGap, breakpoint }: GridColumnGap) {
  if (!breakpoint) {
    return css`
      column-gap: ${columnGap}px;
    `;
  }
  return css`
    @media all and (min-width: ${breakpoints[breakpoint]}) {
      column-gap: ${columnGap}px;
    }
  `;
}
function getColumnGap({ $columnGap }: GridStyledProps) {
  if (typeof $columnGap === 'object') {
    return $columnGap.map(getColumnGapMapper);
  }
  if (typeof $columnGap === 'number') {
    return css`
      column-gap: ${$columnGap}px;
    `;
  }
  if ($columnGap) {
    return css`
      column-gap: ${spacing[$columnGap]}px;
    `;
  }
  return;
}

function getColumnsMapper({ columns, breakpoint }: GridColumns) {
  if (!breakpoint) {
    return css`
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    `;
  }
  return css`
    @media all and (min-width: ${breakpoints[breakpoint]}) {
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    }
  `;
}

function getColumns({ $columns, $minColumnWidth }: GridStyledProps) {
  if (typeof $columns === 'object') {
    return $columns.map(getColumnsMapper);
  }
  return css`
    grid-template-columns: repeat(
      ${$columns},
      ${$minColumnWidth ? `minmax(${$minColumnWidth}, 1fr)` : 'minmax(0, 1fr)'}
    );
  `;
}

function getRowGap({ $rowGap }: GridStyledProps) {
  if (typeof $rowGap === 'number') {
    return css`
      row-gap: ${$rowGap}px;
    `;
  }
  if ($rowGap) {
    return css`
      row-gap: ${spacing[$rowGap]};
    `;
  }
  return;
}
export const GridStyled = styled.div<GridStyledProps>`
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  display: grid;
  grid-auto-flow: row;
  justify-content: ${({ $center }) => $center && 'center'};
  margin: ${({ $margin }) => $margin};
  max-width: ${({ $maxWidth }) => $maxWidth};
  ${getColumnGap};
  ${getColumns};
  ${getRowGap};
`;
