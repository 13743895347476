import Link from 'next/link';

import { BuyingOption } from '@hultafors/hultafors/types';

import { Icon } from '../Icon/Icon';
import { RouterLink } from '../RouterLink/RouterLink';

import { BuyingOptionsListStyled } from './BuyingOptionsList.styled';

interface BuyingOptionsListProps {
  items: BuyingOption[];
  toggle: () => void;
}

export const BuyingOptionsList: React.FC<BuyingOptionsListProps> = ({
  items,
  toggle,
}) => {
  // https://stackoverflow.com/a/19709846
  const absoluteRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');

  const optionsMapper = (item: BuyingOption, index: number) => {
    if (!item.url || !item.name) {
      return null;
    } else {
      return absoluteRegExp.test(item.url)
        ? (
          <Link
            href={item.url || ''}
            key={`Buying-Option-${index}`}
            target="_blank"
            rel="noopener noreferrer"
            className="external"
            onClick={toggle}
          >
            {item.name}
            {' '}
            <Icon svg="externalLink" width={16} />
          </Link>
          )
        : (
          <RouterLink
            className="internal"
            to={item.url}
            key={`Buying-Option-${index}`}
            onClick={toggle}
          >
            {item.name}
            {' '}
            <Icon svg="chevron" width={16} />
          </RouterLink>
          );
    }
  };

  return (
    <BuyingOptionsListStyled>
      {items.map(optionsMapper)}
    </BuyingOptionsListStyled>
  );
};
