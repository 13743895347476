import { Icon } from '../Icon/Icon';

import { ModalStyled } from './Modal.styled';

export interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  toggle(): void;
  isOpen: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  toggle,
  isOpen,
}) => {
  return (
    <ModalStyled className={className} $isOpen={isOpen}>
      <button onClick={toggle} aria-label="Close" className="CloseButton">
        <Icon svg="close" />
      </button>
      <div className="ModalContent">{children}</div>
    </ModalStyled>
  );
};
