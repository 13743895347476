import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const PictogramsStyled = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.regular};
  margin-bottom: ${spacing.block};
  width: 100%;

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    gap: ${spacing.small};
  }
`;

export const Pictogram = styled.li`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
  }
`;

export const PictogramImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin-bottom: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    inline-size: 48px;
    margin-block-end: 0;
    margin-inline-end: ${spacing.small};
  }
`;

export const PictogramText = styled.p`
  font-size: ${fontSizes.xsmall};
  margin: 0;
`;
