import dynamic from 'next/dynamic';

import { createIconUrl } from '@hultafors/hultafors/helpers/routing';
import { ImageFragment } from '@hultafors/hultafors/types';

import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';
import { RouterLink } from '../RouterLink/RouterLink';

import { IconWrapper, ReadMoreLink } from './IconCard.styled';
const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

export interface IconCardProps {
  description: string;
  readMoreLabel?: string;
  desktopImage?: ImageFragment;
  slug: string;
  title: string;
}

export const IconCard: React.FC<IconCardProps> = ({
  description,
  readMoreLabel,
  desktopImage,
  title,
  slug,
}) => {
  return (
    <IconWrapper>
      <RouterLink to={createIconUrl(slug)}>
        <AspectRatioImage desktopImage={desktopImage} />
      </RouterLink>

      <div>
        <RouterLink to={createIconUrl(slug)}>
          {title && (
            <HTag styleType="header4" type="h4" className="TitleHeader">
              {title}
            </HTag>
          )}
          {description && <Paragraph>{description}</Paragraph>}
        </RouterLink>
        {readMoreLabel && slug && (
          <ReadMoreLink
            direction="forward"
            label={readMoreLabel}
            url={`/icons/${slug}`}
            className="ArrowLink"
          />
        )}
      </div>
    </IconWrapper>
  );
};
