import { H3Styled } from './H3.styled';

interface H3Props {
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const H3: React.FC<H3Props> = ({ children, className, center }) => {
  return (
    <H3Styled $center={center} className={className}>
      {children}
    </H3Styled>
  );
};
