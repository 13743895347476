import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { Section } from '../Section/Section';

import { ResultNotFoundStyled } from './ResultNotFound.styled';
interface ResultNotFoundProps {
  text?: string | null;
}
export const ResultNotFound: React.FC<ResultNotFoundProps> = (props) => {
  return (
    <ResultNotFoundStyled>
      <Section>
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12 },
            ]}
          >
            <H2 center>{props.text}</H2>
          </GridChild>
        </Grid>
      </Section>
    </ResultNotFoundStyled>
  );
};
