import { BoxStyled } from './Box.styled';

interface BoxProps {
  children?: React.ReactNode;
  marginTop?: boolean;
  shadow?: boolean;
  className?: string;
}

export const Box: React.FC<BoxProps> = ({
  children,
  className,
  marginTop,
  shadow,
}) => (
  <BoxStyled className={className} $shadow={shadow} $marginTop={marginTop}>
    {children}
  </BoxStyled>
);
