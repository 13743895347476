import { CategoryListBlockFragment } from '@hultafors/hultafors/types';

import { CategoryPlug } from '../CategoryPlug/CategoryPlug';

import { CategoryListBlockStyled } from './CategoryListBlock.styled';

export const CategoryListBlock: React.FC<CategoryListBlockFragment> = ({
  categoryList,
}) => (
  <CategoryListBlockStyled>
    {categoryList.map((item, i) => (
      <CategoryPlug
        key={`CategoryPlug-${item.slug}-${i}`}
        image={item.image}
        title={item.title}
        slug={item.slug}
      />
    ))}
  </CategoryListBlockStyled>
);
