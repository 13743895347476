import Link from 'next/link';

import { Icon } from '../Icon/Icon';

import { LinkButtonStyled } from './LinkButton.styled';

interface LinkButtonProps {
  className?: string;
  fullWidth?: boolean;
  text?: string;
  mailTo?: boolean;
  mailToLink?: string;
  iconUrl?: string;
  url?: string;
}
export const LinkButton: React.FC<LinkButtonProps> = ({
  fullWidth,
  iconUrl,
  mailTo,
  mailToLink,
  text,
  url,
}) => {
  return (
    <LinkButtonStyled $fullWidth={fullWidth} $iconUrl={iconUrl}>
      {mailTo
        ? (
          <Link
            href={`mailto:${mailToLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
            {' '}
            <Icon svg="mail" />
          </Link>
          )
        : (
          <Link href={url || ''}>{text}</Link>
          )}
    </LinkButtonStyled>
  );
};
