import { Fragment, useMemo, useState } from 'react';

import {
  ProductFilter,
  ProductFilterValue,
  QueryProductFilter,
} from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/hultafors/hooks';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import {
  Actions,
  ButtonFilters,
  ClearIcon,
  FilterButton,
  FilterStyled,
  SubFilter,
  SubFilters,
} from './Filter.styled';

export interface FilterProps {
  filters: ProductFilter[];
  // selectedFilters: ProductFilter[];
  filterChange(input: QueryProductFilter): void;
  clearFilter(): void;
  clearFilterLabel: string;
  toggleFilter(): void;
  slideInOpen?: boolean;
  isMobileFilter?: boolean;
}

export const Filter: React.FC<FilterProps> = ({
  filters,
  filterChange,
  clearFilter,
  clearFilterLabel,
  toggleFilter,
  isMobileFilter = false,
}) => {
  const { globalContent } = useGlobal();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const selectedFilters = useMemo(
    () =>
      filters.filter(
        ({ values }) => !!values.filter(({ active }) => active).length,
      ),
    [filters],
  );

  const toggleActive = (e: React.MouseEvent, index: number) => {
    e.preventDefault();

    const currentIndex = activeIndex === index ? null : index;
    setActiveIndex(currentIndex);
  };

  const isSelectionUnderParent = (parent: any): boolean => {
    return (
      !!filters
        ?.find(({ id }: ProductFilter) => parent?.id === id)
        ?.values.filter((value: ProductFilterValue) => value.active)?.length
        || false
    );
  };

  const isHighlightedFilter = ({ isHighLighted }: ProductFilter) =>
    isHighLighted;

  const notHighlightedFilter = ({ isHighLighted }: ProductFilter) =>
    !isHighLighted;

  const highlightedMapper = (filter: ProductFilter) => (
    <Fragment key={`FilterFrag-${filter.id}`}>
      {filter.values.map((value, index) => {
        const onClick = () =>
          filterChange({ AttrId: `${filter.id}`, ValueId: `${value.id}` });
        return (
          <Button
            key={`Filter-${index}`}
            className={`Filter ${value.active ? 'Selected' : ''}`.trim()}
            onClick={onClick}
          >
            {value.description}
          </Button>
        );
      })}
    </Fragment>
  );

  const filterMapper = (filter: ProductFilter, i: number) => {
    return (
      <div key={`Filter-${filter.id}`} className="FilterWrapper">
        <FilterButton
          className={`Icon MobileFilter ${activeIndex === i ? 'Active ' : ''} ${
            isSelectionUnderParent(filter) ? 'ChildSelected' : ''
          }`.trim()}
          onClick={(e) => toggleActive(e, i)}
          $filterActive={activeIndex === i}
        >
          <Icon svg="chevron" width={16} />
        </FilterButton>

        <SubFilters $active={activeIndex === i}>
          {filter.values.map((value, j) => {
            const onClick = () =>
              filterChange({ AttrId: `${filter.id}`, ValueId: `${value.id}` });

            return (
              <SubFilter
                key={`SubFilter-${j}`}
                className="SubFilter"
                checked={!!value.active}
              >
                <label className={value.active ? 'checked' : ''}>
                  <input
                    type="checkbox"
                    onChange={onClick}
                    checked={value.active}
                  />
                  {filter.type === 'numeric'
                    ? (
                      <span>
                        {value.value}
                        {' '}
                        {value.unit}
                      </span>
                      )
                    : (
                      <span>{value.description}</span>
                      )}
                </label>
              </SubFilter>
            );
          })}
        </SubFilters>
      </div>
    );
  };

  return (
    <FilterStyled>
      <>
        {!isMobileFilter && (
          <ButtonFilters>
            {filters.filter(isHighlightedFilter).map(highlightedMapper)}
          </ButtonFilters>
        )}
        {!isMobileFilter && (
          <div className="MobileFilterBar">
            <FilterButton
              onClick={toggleFilter}
              aria-label={globalContent?.showFilters || ''}
            >
              <Icon svg="filter" width={16} />
            </FilterButton>
          </div>
        )}

        <div className="BoxFilters">
          <div
            className={`${
              !isMobileFilter
                ? 'DropDownFiltersDesktop'
                : 'DropDownFiltersMobile'
            }`}
          >
            {filters.filter(notHighlightedFilter).map(filterMapper)}
          </div>
          {selectedFilters.length > 0 && (
            <Actions>
              <Button text onClick={clearFilter}>
                {clearFilterLabel}
                <ClearIcon>
                  <Icon svg="trash" width={16} />
                </ClearIcon>
              </Button>
            </Actions>
          )}
        </div>
      </>
    </FilterStyled>
  );
};
