import styled from 'styled-components';

import { colors } from '@hultafors/hultafors/tokens';

export const ButtonWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  margin: 0 -20px;
  background-color: ${colors.white};
`;
