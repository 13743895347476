import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const ProductInfoStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: var(--header-height);
    padding-block-start: ${spacing.large};
  }

  .Accordion {
    .CollapseHeader {
      block-size: 3.5rem;
    }

    .AdditionalInformation {
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .ProductName {
    .Flags {
      display: flex;
      margin: 0 0 ${spacing.small} 0;
      block-size: ${spacing.small};

      p {
        display: inline-flex;
        align-items: center;
        block-size: 100%;
        margin: 0;
        padding: 0 5px;
        text-transform: uppercase;
        font-size: ${fontSizes.supersmall};
        font-weight: ${fontWeights.medium};
        letter-spacing: 0.05rem;
        border: thin solid ${colors.black};
        line-height: 1;

        &:nth-of-type(2) {
          margin-inline-start: ${spacing.small};
        }
      }

      .PremiumFlag {
        background: ${colors.black};
        color: ${colors.white};
      }

      .SustainableFlag {
        background: ${colors.darkGreen};
        color: ${colors.white};
        border: thin solid ${colors.darkGreen};
      }
    }

    .NameAndFavorite {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block-end: ${spacing.small};

      h1 {
        font-size: ${fontSizes.h5};
        font-weight: ${fontWeights.normal};
        margin: 0;
        line-height: ${lineHeights.xsmall};
      }
    }

    .Price {
      .PriceLabel {
        color: ${colors.gray2};
        font-size: ${fontSizes.supersmall};
        margin-inline-end: 4px;
      }

      margin-block-end: ${spacing.block};
    }
  }

  .ProductIntro {
    margin-block-end: ${spacing.regular};

    &.Mobile {
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        display: none;
      }
    }

    &.Desktop {
      display: none;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        display: block;
      }
    }
  }

  .CartActions {
    margin: ${spacing.regular} 0 ${spacing.medium} 0;

    .BuyingOptions,
    .ChooseModel {
      margin-block-end: ${spacing.small};

      @media screen and (min-width: ${breakpoints.mediumMobile}) {
        margin-block-end: ${spacing.regular};
      }
    }

    .AmountInputWrapper {
      margin-inline-end: ${spacing.xsmall};

      @media screen and (min-width: ${breakpoints.mediumMobile}) {
        margin-inline-end: ${spacing.regular};
      }
    }

    .AddToCart {
      display: flex;
      align-content: stretch;
      margin-block-end: ${spacing.small};

      button {
        flex-grow: 1;
      }
    }
  }

  .RetailerLink {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      font-size: ${fontSizes.small};
      color: ${colors.black};
      letter-spacing: 0.5px;

      svg {
        inline-size: ${spacing.small};
        block-size: ${spacing.small};
        margin-inline-start: ${spacing.xsmall};

        path {
          fill: ${colors.black};
          transition: all 0.2s;
        }
      }

      &:hover {
        color: ${colors.gray1};

        svg path {
          fill: ${colors.gray1};
        }
      }
    }
  }

  .Features {
    .Feature {
      display: flex;
      align-items: flex-start;
      border-block-end: thin solid ${colors.gray4};
      margin: 0;
      padding: ${spacing.small} 0;

      &::before {
        content: '';
        display: inline-flex;
        block-size: 1px;
        inline-size: 20px;
        background: ${colors.red};
        margin-inline-end: ${spacing.small};
        margin-block-start: 10px;
        flex-shrink: 0;
      }
    }
  }

  .Accordion + .Accordion {
    border-block-start: thin solid ${colors.border};
  }
`;
