/* eslint-disable import/no-unresolved */
import { useEffect, useMemo, useState } from 'react';

import { stringify } from 'querystring';

import Image from 'next/image';
import { useRouter } from 'next/router';
import { KeyLoader } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { breakpoints } from '@hultafors/shared/constants';

import { ALL_ARTICLES_PAGE_SIZE } from '@hultafors/hultafors/helpers/constants';
import { useGlobal } from '@hultafors/hultafors/hooks';
import {
  AllArticlePagesResponse,
  ArticlePageLinkFragment,
  FilterFragment,
  TagFilter,
  TagFilterOption,
} from '@hultafors/hultafors/types';

import { ArticleFilter } from '../ArticleFilter/ArticleFilter';
import { Grid } from '../Grid/Grid';
import { Pager } from '../Pager/Pager';
import { Section } from '../Section/Section';

import {
  Empty,
  Error,
  ImageWrapper,
  ListArticleLabel,
  ListArticleTitle,
  ListBlockStyled,
  ListImage,
  ListLink,
} from './ListBlock.styled';

export interface ListBlockProps {
  fallbackData?: AllArticlePagesResponse[];
  pageListType?: string | null;
  filters: FilterFragment[];
  tags?: string[];
}

export const ListBlock: React.FC<ListBlockProps> = ({
  fallbackData = [],
  pageListType,
  tags: initialTags = [],
  filters: initialFilters,
}) => {
  const { globalContent } = useGlobal();
  const { locale, ...router } = useRouter();
  const [tags, setTags] = useState<string[]>(initialTags);
  const queryTags = router?.query['tags'];

  const filters: TagFilter[] = useMemo(() => {
    return initialFilters.map((filter) => {
      const options: TagFilterOption[] = filter.options.map((option) => {
        if (queryTags && typeof queryTags !== 'undefined') {
          return { ...option, active: queryTags.includes(option.id) };
        }
        return { ...option, active: tags.includes(option.id) };
      });
      return { ...filter, options };
    });
  }, [initialFilters, tags]);

  const getKey: KeyLoader = (
    index: number,
    previousPageData: AllArticlePagesResponse | null,
  ) => {
    const pageSize = ALL_ARTICLES_PAGE_SIZE;
    const count = previousPageData?.allArticlePagesMeta?.count || 0;
    const total = previousPageData?.allArticlePages
      ? pageSize * (index - 1) + previousPageData.allArticlePages.length
      : pageSize * index;
    const page = index + 1;
    // No more if we are on last page
    if (count && total >= count) {
      return null;
    }

    const params = new URLSearchParams({
      page: `${page}`,
      ...(locale && locale !== 'com' && locale !== 'en' ? { locale } : {}),
    });
    tags.forEach((tag) => {
      params.append('tags', tag);
    });

    return `api/allArticles?${params.toString()}`;
  };

  const { data, isValidating, error, setSize, size }
    = useSWRInfinite<AllArticlePagesResponse>(getKey, {
      fallbackData,
      initialSize: fallbackData?.length || 0,
    });
  const allArticlePages: ArticlePageLinkFragment[] = useMemo(() => {
    return data?.flatMap(({ allArticlePages }) => allArticlePages) || [];
  }, [data]);

  const count: number = useMemo(() => {
    const length = data?.length || 0;
    return data?.[length - 1]?.allArticlePagesMeta?.count || 0;
  }, [data]);

  useEffect(() => {
    if (
      JSON.stringify([...tags].sort())
      !== JSON.stringify(
        [...new URLSearchParams(stringify(router.query)).getAll('tags')].sort(),
      )
    ) {
      const params = new URLSearchParams();
      if (data?.length) {
        params.set('page', `${data.length}`);
      }
      tags.forEach((tag) => params.append('tags', tag));
      const query = params.toString();
      const pathname = router.asPath.split('?')[0];
      router.push(`${pathname}${query && `?${query}`}`, undefined, {
        shallow: true,
      });
      setSize(1);
    }
  }, [tags]);

  const clearFilters = () => {
    setTags([]);
  };

  const toggleFilter = (id: string) => {
    if (tags.includes(id)) {
      setTags([...tags.filter((tag) => tag !== id)]);
    } else {
      setTags([...tags, id]);
    }
  };

  const loadMore = () => {
    const newSize = size + 1;
    setSize(newSize);
    const params = new URLSearchParams(stringify(router.query));
    params.delete('category');
    if (!newSize || newSize === 1) {
      params.delete('page');
    } else {
      params.set('page', `${newSize}`);
    }
    const query = params.toString();
    const path = router.asPath.split('?')[0];
    router.push(`${path}${query && `?${query}`}`, undefined, {
      shallow: true,
    });
  };

  const sizes = [
    '(min-width: 1500px) 295px',
    `(min-width: ${breakpoints.desktopMedium}) calc((100vw - 128px - 12rem) / 4)`,
    `(min-width: ${breakpoints.desktop}) calc((100vw - 106px - 9rem) / 3)`,
    `(min-width: ${breakpoints.mediumMobile}) calc((100vw - 64px - 6rem) / 2)`,
    'calc(100vw - 90px)',
  ].join(', ');

  return (
    <ListBlockStyled>
      <Section>
        {filters && (
          <ArticleFilter
            filters={filters}
            toggle={toggleFilter}
            clear={clearFilters}
            loading={isValidating}
            clearLabel={globalContent?.clearArticleFilters || ''}
          />
        )}
        {error
          ? (
            <Error>{globalContent?.articleListError}</Error>
            )
          : count < 1 && globalContent?.noArticlesFound
            ? (
              <Empty>{globalContent.noArticlesFound}</Empty>
              )
            : (
              <>
                <Grid
                  columnGap={[{ columnGap: 20 }]}
                  columns={[
                    { columns: 1 },
                    { breakpoint: 'mediumMobile', columns: 2 },
                    { breakpoint: 'desktop', columns: 3 },
                    { breakpoint: 'desktopMedium', columns: 4 },
                  ]}
                >
                  {allArticlePages?.map((article, index) => {
                    const key = `${article?.title}-${index}`;
                    const articleUrl
                  = `/${pageListType}/${article?.slug}`.toLowerCase();
                    return (
                      <ListLink key={key} href={articleUrl}>
                        <ListImage>
                          <ImageWrapper>
                            <Image
                              src={
                            article?.image?.responsiveImage?.src
                            || '/assets/img/noimage.png'
                          }
                              alt={article?.title || ''}
                              style={{ objectFit: 'cover' }}
                              sizes={sizes}
                              fill
                            />
                          </ImageWrapper>
                        </ListImage>
                        <ListArticleTitle>
                          <ListArticleLabel>{article?.title}</ListArticleLabel>
                        </ListArticleTitle>
                      </ListLink>
                    );
                  })}
                </Grid>
                <Pager
                  buttonLabel={globalContent?.showMoreLabel || ''}
                  ofLabel={globalContent?.ofLabel || ''}
                  productsLabel={globalContent?.articlesLabel || ''}
                  onClick={loadMore}
                  paging={{
                    pageNumber: size,
                    pageSize: ALL_ARTICLES_PAGE_SIZE,
                    pageCount: Math.ceil(count / ALL_ARTICLES_PAGE_SIZE),
                    itemCount: count,
                  }}
                  morePages={allArticlePages.length < count}
                  loading={isValidating}
                />
              </>
              )}
      </Section>
    </ListBlockStyled>
  );
};
