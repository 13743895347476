import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultafors/tokens';

interface H4StyledProps {
  $center?: boolean;
  $underline?: boolean;
  $large?: boolean;
}

export const H4Styled = styled.h4<H4StyledProps>`
  text-align: ${({ $center }) => ($center ? 'center' : 'inherit')};
  font-size: ${fontSizes.h4};
  line-height: ${lineHeights.h4};
  font-weight: ${fontWeights.normal};
  text-decoration: ${({ $underline }) => ($underline ? 'underline' : 'none')};
  margin: 0;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    text-decoration: none;
  }
`;
