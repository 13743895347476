import dynamic from 'next/dynamic';

import { ArticleImageFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { ImageBlockStyled } from './ImageBlock.styled';

const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

export const ImageBlock: React.FC<ArticleImageFragment> = ({
  fullWidthImage,
  image,
}) => {
  if (!image?.responsiveImage) {
    return null;
  }
  if (fullWidthImage) {
    const blockStyle = {
      aspectRatio: `${image?.responsiveImage?.aspectRatio}`,
    };
    return (
      <ImageBlockStyled $fullWidthImage={fullWidthImage} style={blockStyle}>
        <AspectRatioImage desktopImage={image} />
      </ImageBlockStyled>
    );
  }
  return (
    <ImageBlockStyled $fullWidthImage={fullWidthImage}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 12 },
            { breakpoint: 'desktop', columns: 8, start: 3 },
          ]}
        >
          <AspectRatioImage desktopImage={image} />
        </GridChild>
      </Grid>
    </ImageBlockStyled>
  );
};
