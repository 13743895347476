import { CSSProperties } from 'styled-components';

import { GridChildColumnSpan, GridChildStyled } from './GridChild.styled';

interface GridChildProps {
  children?: React.ReactNode;
  className?: string;
  columnSpan?: GridChildColumnSpan[];
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  padding?: CSSProperties['padding'];
  rowSpan?: string;
  rowStart?: string;
}

/**
 * Usage of columnSpan (12 columns up until 900px then 6):
 * columnSpan={[{ columns: 12 }, { breakpoint: '900px', columns: 6 }]}
 * @param props
 * @returns
 */
export const GridChild: React.FC<GridChildProps> = ({
  children,
  className,
  align = 'stretch',
  columnSpan,
  alignSelf,
  padding,
  rowSpan,
  rowStart,
}) => (
  <GridChildStyled
    $align={align}
    $columnSpan={columnSpan}
    $alignSelf={alignSelf}
    $padding={padding}
    $rowSpan={rowSpan}
    $rowStart={rowStart}
    className={`GridChild ${className}`}
  >
    {children}
  </GridChildStyled>
);
