import { StructuredText } from 'react-datocms';

import { ArticleTextFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { TextBlockStyled } from './TextBlock.styled';

interface TextBlockProps extends ArticleTextFragment {
  indexKey: string;
  center?: boolean;
  fullWidth?: boolean;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  indexKey,
  text,
  fullWidth = false,
  center,
}) => {
  return (
    <TextBlockStyled $fullWidth={fullWidth} $center={center}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 5 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
            { breakpoint: 'desktop', columns: 6, start: 3 },
            { breakpoint: 'desktopMedium', columns: 6, start: 4 },
          ]}
        >
          <StructuredText data={text?.value} key={indexKey} />
        </GridChild>
      </Grid>
    </TextBlockStyled>
  );
};
