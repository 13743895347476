import { useCart } from '@hultafors/hultafors/hooks';
import { CartItemData } from '@hultafors/hultafors/types';

import { CartItem } from '../CartItem/CartItem';
import { LinkButton } from '../LinkButton/LinkButton';
import { SlideInNew } from '../SlideInNew/SlideInNew';

import { CartItems, CartStyled } from './Cart.styled';

interface CartProps {
  isOpen: boolean;
  toggleMenu(value?: string): void;
  header: string;
  clearText: string;
  sendCtaButton: string;
  shoppingListText: string;
  skuLabel: string;
  closeLabel: string;
}

export const Cart: React.FC<CartProps> = ({
  isOpen,
  toggleMenu,
  header,
  clearText,
  sendCtaButton,
  shoppingListText,
  skuLabel,
  closeLabel,
  ...props
}) => {
  const { cart, clearCart } = useCart();

  const createMailToLink = (cart: CartItemData[]) =>
    `?subject=${shoppingListText}&body=${
      cart.map(({ sku, name }) => `${sku} ${name} %0D%0A%0D%0A`).join('') || ''
    }`;

  const calllback = () => toggleMenu('');

  return (
    <CartStyled>
      <SlideInNew
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={`${header} (${cart.length})`}
        headerIcon="shoppingList"
        clearText={clearText}
        clearAll={clearCart}
        padContent
        fromLeft={false}
        isCart
        itemLength={cart.length}
      >
        <CartItems>
          {cart.map((item) => (
            <CartItem
              key={`CartItem-${item.sku}`}
              item={item}
              skuLabel={skuLabel}
              closeLabel={closeLabel}
              callback={calllback}
            />
          ))}
        </CartItems>
        <div className="SendListWrapper">
          <LinkButton
            className="Black Icon"
            fullWidth
            text={sendCtaButton}
            mailTo
            mailToLink={createMailToLink(cart)}
          />
        </div>
      </SlideInNew>
    </CartStyled>
  );
};
