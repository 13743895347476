import dynamic from 'next/dynamic';

import {
  ArticlePageLinkFragment,
  ContentAreaField,
  isArticleCta,
  isArticleFactBox,
  isArticleImage,
  isArticleIntro,
  isArticleQuote,
  isArticleTextBlock,
  isArticleVideo,
  isCategoryListBlock,
  isExploreAreaArticleBlock,
  isExploreAreaBlock,
  isExploreAreaNewsBlock,
  isExploreAreaStoriesBlock,
  isFormBlock,
  isHeroBlock,
  isIconListBlock,
  isListBlock,
  isOfficesBlock,
  isPersonellList,
  isRelatedProductsBlock,
  isSliderBlock,
  isTextBlock,
  isVisibleSeoBlock,
  NewsPagePlugFragment,
} from '@hultafors/hultafors/types';

import { ContentAreaStyled } from './ContentArea.styled';

const ArticleCta = dynamic(() =>
  import('../ArticleCta/ArticleCta').then((mod) => mod.ArticleCta),
);

const FactBox = dynamic(() =>
  import('../FactBox/FactBox').then((mod) => mod.FactBox),
);
const ImageBlock = dynamic(() =>
  import('../ImageBlock/ImageBlock').then((mod) => mod.ImageBlock),
);
const IntroTextBlock = dynamic(() =>
  import('../IntroTextBlock/IntroTextBlock').then((mod) => mod.IntroTextBlock),
);
const QuoteBlock = dynamic(() =>
  import('../QuoteBlock/QuoteBlock').then((mod) => mod.QuoteBlock),
);
const TextBlock = dynamic(() =>
  import('../TextBlock/TextBlock').then((mod) => mod.TextBlock),
);
const VideoBlock = dynamic(() =>
  import('../VideoBlock/VideoBlock').then((mod) => mod.VideoBlock),
);

const CategoryListBlock = dynamic(() =>
  import('../CategoryListBlock/CategoryListBlock').then(
    (mod) => mod.CategoryListBlock,
  ),
);
const ExploreAreaBlock = dynamic(() =>
  import('../ExploreAreaBlock/ExploreAreaBlock').then(
    (mod) => mod.ExploreAreaBlock,
  ),
);
const ExploreAreaBlockNew = dynamic(() =>
  import('../ExploreAreaBlockNew/ExploreAreaBlockNew').then(
    (mod) => mod.ExploreAreaBlockNew,
  ),
);
const FormBlock = dynamic(() =>
  import('../FormBlock/FormBlock').then((mod) => mod.FormBlock),
);
const HeroBlock = dynamic(() =>
  import('../HeroBlock/HeroBlock').then((mod) => mod.HeroBlock),
);
const IconListBlock = dynamic(() =>
  import('../IconListBlock/IconListBlock').then((mod) => mod.IconListBlock),
);

const ListBlock = dynamic(() =>
  import('../ListBlock/ListBlock').then((mod) => mod.ListBlock),
);

const OfficesBlock = dynamic(() =>
  import('../ContactStores/ContactStores').then((mod) => mod.ContactStores),
);
const PersonellList = dynamic(() =>
  import('../personell-list/personell-list').then((mod) => mod.PersonellList),
);
const RelatedProductsBlock = dynamic(() =>
  import('../RelatedProductsBlock/RelatedProductsBlock').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const TextBlockMarkdown = dynamic(() =>
  import('../text-block/text-block').then((mod) => mod.TextBlock),
);
const SliderBlock = dynamic(() =>
  import('../SliderBlock/SliderBlock').then((mod) => mod.SliderBlock),
);
const VisibleSeoBlock = dynamic(() =>
  import('../VisibleSeoBlock/VisibleSeoBlock').then(
    (mod) => mod.VisibleSeoBlock,
  ),
);

interface ContentAreaProps {
  content: ContentAreaField[];
  allNewsPages?: NewsPagePlugFragment[];
  allArticlePages?: ArticlePageLinkFragment[];
}

export const ContentArea = ({
  content,
  allNewsPages = [],
}: ContentAreaProps) => {
  function contentMapper(item: ContentAreaField, index: number) {
    const key = `${item.__typename}-${index}`;
    const priority = index === 0;

    if (isExploreAreaArticleBlock(item)) {
      return (
        <ExploreAreaBlock
          parentSlug="articles"
          key={key}
          index={key}
          list={item.articleList}
          {...item}
        />
      );
    }

    if (isExploreAreaNewsBlock(item)) {
      return (
        <ExploreAreaBlock
          parentSlug="news"
          key={key}
          index={key}
          list={allNewsPages}
          {...item}
        />
      );
    }

    if (isExploreAreaStoriesBlock(item)) {
      return (
        <ExploreAreaBlock
          parentSlug="stories"
          key={key}
          index={key}
          list={item.storiesList}
          {...item}
        />
      );
    }

    if (isArticleCta(item)) {
      return <ArticleCta key={key} {...item} />;
    }

    if (isArticleImage(item)) {
      return <ImageBlock key={key} {...item} />;
    }

    if (isArticleIntro(item)) {
      return <IntroTextBlock key={key} indexKey={key} {...item} />;
    }

    if (isArticleQuote(item)) {
      return <QuoteBlock key={key} {...item} />;
    }

    if (isArticleFactBox(item)) {
      return <FactBox key={key} indexKey={key} {...item} />;
    }

    if (isArticleTextBlock(item)) {
      return <TextBlock key={key} indexKey={key} {...item} />;
    }

    if (isArticleVideo(item)) {
      return <VideoBlock key={key} {...item} />;
    }

    if (isCategoryListBlock(item)) {
      return <CategoryListBlock key={key} {...item} />;
    }
    if (isOfficesBlock(item)) {
      return <OfficesBlock key={key} {...item} />;
    }

    if (isExploreAreaBlock(item)) {
      return <ExploreAreaBlockNew key={key} {...item} />;
    }

    if (isFormBlock(item)) {
      return <FormBlock key={key} {...item} />;
    }

    if (isHeroBlock(item)) {
      return <HeroBlock key={key} {...item} priority={priority} />;
    }

    if (isIconListBlock(item)) {
      return <IconListBlock key={key} {...item} />;
    }

    if (isListBlock(item)) {
      return <ListBlock key={key} {...item} />;
    }

    if (isPersonellList(item)) {
      return <PersonellList key={key} {...item} />;
    }

    if (isSliderBlock(item)) {
      return <SliderBlock key={key} {...item} />;
    }

    if (isRelatedProductsBlock(item)) {
      return <RelatedProductsBlock key={key} {...item} />;
    }
    if (isTextBlock(item)) {
      return <TextBlockMarkdown key={key} {...item} />;
    }
    if (isVisibleSeoBlock(item)) {
      return <VisibleSeoBlock key={key} {...item} />;
    }
    return null;
  }

  return <ContentAreaStyled>{content?.map(contentMapper)}</ContentAreaStyled>;
};
