import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultafors/tokens';

export const CartStyled = styled.div`
  .SlideInBody {
    position: relative;
    padding-block-end: 150px;
  }

  .SendListWrapper {
    position: fixed;
    box-sizing: border-box;
    inline-size: 100%;
    inset-block-end: 0;
    margin: 0 -20px;
    background-color: ${colors.white};

    h4 {
      margin-block-start: 0;
    }
  }
`;
export const CartItems = styled.div`
  > * + * {
    margin-block-start: ${spacing.regular};
  }
`;
