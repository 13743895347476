import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

export interface GridChildColumnSpan {
  breakpoint?: Breakpoint;
  start?: number;
  columns?: number;
  display?: CSSProperties['display'];
}

interface GridChildStyledProps {
  $columnSpan?: GridChildColumnSpan[];
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowSpan?: string;
  $rowStart?: string;
}

function getColumnSpanMapper({
  start,
  columns,
  display,
  breakpoint,
}: GridChildColumnSpan) {
  if (!breakpoint) {
    return css`
      grid-column: ${start && `${start} /`} ${columns ? `span ${columns}` : -1};
      display: ${display ? display : 'initial'};
    `;
  }

  return css`
    @media all and (min-width: ${breakpoints[breakpoint]}) {
      grid-column: ${start && `${start} /`} ${columns ? `span ${columns}` : -1};
      display: ${display ? display : 'initial'};
    }
  `;
}
function getColumnSpan({ $columnSpan }: GridChildStyledProps) {
  if (!$columnSpan) {
    return css`
      grid-column: 1 / -1;
    `;
  }

  return $columnSpan.map(getColumnSpanMapper);
}

export const GridChildStyled = styled.div<GridChildStyledProps>`
  grid-row: ${({ $rowStart }) => $rowStart && `${$rowStart}`} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};
  ${getColumnSpan};
  place-self: ${({ $alignSelf }) => $alignSelf} ${({ $align }) => $align};
  padding: ${({ $padding }) => $padding};
`;
