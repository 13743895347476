import Link from 'next/link';
import { Accordion } from 'react-accessible-accordion';
import styled, { css } from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

export const MobileMenuStyled = styled.div`
  .SlideInHeader {
    position: relative;
  }

  .SearchWrapper {
    padding: ${spacing.small} 0 ${spacing.medium} 0;
  }
`;

export const MobileMenuSubLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.black};
  font-size: ${fontSizes.small};
  min-height: 44px;

  &:link {
    color: ${colors.black};
  }
`;

const AccordionItemStyle = css`
  display: block;
  min-height: 44px;
  font-size: 1.125rem;
  font-weight: 300;
  margin: 0;
  position: relative;
`;

const AccordionHeadingStyle = css`
  display: flex;
  height: 2.5rem;
  align-items: center;
`;

export const MobileMenuLink = styled(Link)`
  ${AccordionItemStyle};
  ${AccordionHeadingStyle};
  text-decoration: none;
  color: ${colors.black};

  &:link {
    color: ${colors.black};
  }
`;

export const AccordionStyled = styled(Accordion)`
  position: relative;

  .accordion__button {
    ${AccordionItemStyle};
    display: flex;
    inline-size: 100%;
    align-items: center;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      display: inline-flex;
      inset-inline-end: 0;
      inset-block-start: 22px;
      inline-size: 12px;
      block-size: 12px;
      background: rgb(0 0 0 / 0%) url('/assets/gfx/chevron.svg') no-repeat
        scroll center center;
      transform: translateY(-50%);
      transition: all 0.3s ease 0s;
    }

    &[aria-expanded='true'] {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .accordion__heading {
    ${AccordionHeadingStyle};
  }

  > * + * {
    margin-block-start: ${spacing.xsmall};
  }

  .accordion__panel {
    overflow: hidden;

    &[hidden] {
      display: block;
    }

    > * + * {
      margin-block-start: ${spacing.xsmall};
    }
  }
`;

export const AccordionItemContent = styled.div`
  padding: ${spacing.small} ${spacing.regular};
`;

export const Divider = styled.span`
  display: block;
  border-top: thin solid ${colors.border};
  padding-top: ${spacing.regular};
  margin-top: ${spacing.regular};
`;
