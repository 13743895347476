import { useRef } from 'react';

import { useClickAway, useKey } from 'react-use';

import { useCart, useGlobal } from '@hultafors/hultafors/hooks';

import { Badge } from '../Badge/Badge';
import { Icon, IconSvg } from '../Icon/Icon';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { CartIcon } from '../NavItem/NavItem.styled';

import {
  Clear,
  ClearLabel,
  CloseButton,
  ExternalLink,
  ExternalLinksContainer,
  LanguageContainer,
  SlideInExit,
  SlideInNewStyled,
} from './SlideInNew.styled';

interface SlideInNewProps {
  children?: React.ReactNode;
  isOpen: boolean;
  toggle(key?: string): void;
  clearAll?(): void;
  headerText?: string;
  headerIcon?: IconSvg;
  clearText?: string;
  isMenu?: boolean;
  isCart?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  changeLanguageText?: string;
  itemLength?: number;
  id?: string;
  className?: string;
  toggleCartMenu?: (value: string) => void;
  fromTop?: boolean;
  fromLeft?: boolean;
  small?: boolean;
  padContent?: boolean;
}

export const SlideInNew: React.FC<SlideInNewProps> = ({
  children,
  isOpen,
  isMenu,
  isCart,
  fromLeft = true,
  fromTop,
  small,
  padContent,
  headerIcon,
  clearText = '',
  headerText = '',
  partnerPortalUrl = '',
  changeLanguageText = '',
  partnerLabel = 'Partner',
  itemLength,
  clearAll,
  toggle,
  id = '',
  toggleCartMenu,
}) => {
  const { globalContent } = useGlobal();
  const { cart } = useCart();
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef(null);
  const close = () => {
    if (isOpen) {
      toggle('');
    }
  };

  const toggleCart = () => {
    toggleCartMenu?.('');
  };

  useKey('Escape', () => {
    if (isOpen) {
      close();
    }
  });
  // TODO: Figure out click away
  useClickAway(innerRef, (event) => {
    const target = event.target as HTMLButtonElement;
    if (
      !target?.dataset?.['slideinIgnore']
      || (id && target?.dataset?.['slideinIgnore'] !== id)
    ) {
      close();
    }
  });

  return (
    <SlideInNewStyled
      $fromLeft={fromLeft}
      $fromTop={fromTop}
      $small={small}
      $padContent={padContent}
    >
      <div className={`SlideInBackground ${isOpen ? 'active' : ''}`} ref={ref}>
        <aside
          className={`SlideInInside ${isOpen ? 'active' : ''}`}
          ref={innerRef}
        >
          <div className="SlideInHeader">
            <div className={isMenu ? 'HeaderText isMenu' : 'HeaderText'}>
              {isMenu
                ? (
                  <>
                    <CloseButton
                      className="HeaderIcon"
                      onClick={() => toggle('')}
                      aria-label={globalContent.closeLabel || ''}
                    >
                      <Icon svg="close" />
                    </CloseButton>
                    <LanguageContainer>
                      <LanguageSelector
                        chooseLanguageLabel={changeLanguageText}
                      />
                    </LanguageContainer>
                    <ExternalLinksContainer>
                      {partnerPortalUrl && (
                        <div>
                          <ExternalLink
                            href={partnerPortalUrl || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {partnerLabel}
                            <Icon svg="partnerLogin" width={23} height={24} />
                          </ExternalLink>
                        </div>
                      )}
                      {globalContent.showShoppinglist
                      && globalContent.shoppingListHeader && (
                        <CartIcon
                          aria-hidden="true"
                          aria-label="Open Cart"
                          onClick={toggleCart}
                        >
                          <Icon svg="cart" />
                          {cart.length > 0 && (
                            <Badge tiny round red>
                              {cart.length}
                            </Badge>
                          )}
                        </CartIcon>
                      )}
                    </ExternalLinksContainer>
                  </>
                  )
                : (
                  <>
                    {headerIcon && (
                      <Icon svg={headerIcon} className="HeaderIcon" />
                    )}
                    <span>{headerText}</span>
                  </>
                  )}
            </div>

            <SlideInExit>
              {clearAll
              && clearText
              && typeof itemLength !== 'undefined'
              && itemLength > 0 && (
                <div>
                  <Clear
                    onClick={clearAll}
                    key="ClearButton"
                    aria-label={clearText}
                  >
                    <ClearLabel>{clearText}</ClearLabel>
                    <Icon svg="trash" />
                  </Clear>
                </div>
              )}

              {!isMenu && (
                <CloseButton
                  aria-label={globalContent.closeLabel || ''}
                  onClick={() => toggle('')}
                  key="CloseButton"
                >
                  <Icon svg="close" />
                </CloseButton>
              )}
            </SlideInExit>
          </div>

          <div className={isCart ? 'SlideInBody isCart' : 'SlideInBody'}>
            {children}
          </div>
        </aside>
      </div>
    </SlideInNewStyled>
  );
};
