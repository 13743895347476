import { ButtonLinkStyled } from './ButtonLink.styled';

interface ButtonLinkProps {
  children?: React.ReactNode;
  to: string;
  className?: string;
  disabled?: boolean;
  inverted?: boolean;
  center?: boolean;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  to = '',
  disabled,
  className,
  children,
  inverted,
  center,
}) => (
  <ButtonLinkStyled
    href={to || ''}
    className={className}
    $center={center}
    $inverted={inverted}
    $disabled={disabled}
  >
    {children}
  </ButtonLinkStyled>
);
